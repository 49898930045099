import { GameMovementRow } from '@features/shared/api/typings';
import { GameResultsBadge } from '@features/tournament/ui/components/GameResultBadge';
import { cx } from '@libs/classnames';
import { HorizontalScroll } from '@ui/components/HorizontalScroll';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import styles from './RoundMovement.module.css';

type Props = {
  className?: string;
  isPending?: boolean;
  rows: GameMovementRow[];
};

export function RoundMovement({ className, isPending, rows }: Props) {
  return (
    <HorizontalScroll>
      <Table
        className={cx(styles.root, className, {
          [styles.isPending]: isPending,
        })}
      >
        <TableRow header smallText>
          {/*<TableCell>Стол</TableCell>*/}
          <TableCell colSpan={/*2*/ 1}>Игрок</TableCell>
          <TableCell className={styles.resultsHeader}>Результат</TableCell>
          <TableCell colSpan={/*2*/ 1}>Игрок</TableCell>
        </TableRow>

        {rows.map((r) => (
          <RoundMovementRow
            key={r.boardNo + r.player1.userId + r.player2.userId}
            row={r}
          />
        ))}
      </Table>
    </HorizontalScroll>
  );
}

type RoundMovementRowProps = {
  row: GameMovementRow;
};

function RoundMovementRow({ row }: RoundMovementRowProps) {
  return (
    <TableRow hoverable>
      {/*<TableCell className={styles.no}>{row.boardNo}</TableCell>*/}
      {/*<TableCell className={styles.playerTitle}>{row.player1.title}</TableCell>*/}
      <TableCell className={styles.playerName}>
        <div className={styles.playerNameContainer}>
          {row.player1.lastName} {row.player1.firstName}
        </div>
      </TableCell>
      <TableCell className={styles.results}>
        <GameResultsBadge
          className={styles.result}
          result={row.player1.game.resultType}
          side={row.player1.game.side}
          score={row.player1.game.score}
          dotCentered
        />
        <GameResultsBadge
          className={styles.result}
          result={row.player2.game.resultType}
          side={row.player2.game.side}
          score={row.player2.game.score}
          dotCentered
          reversed
        />
      </TableCell>
      <TableCell className={styles.playerName}>
        <div className={styles.playerNameContainer}>
          {row.player2.lastName} {row.player2.firstName}
        </div>
      </TableCell>
      {/*<TableCell className={styles.playerTitle}>{row.player2.title}</TableCell>*/}
    </TableRow>
  );
}
