export function ArrowIcon() {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.304 5.28641C4.06517 6.50427 4.44575 7.1132 5 7.1132C5.55425 7.1132 5.93483 6.50427 6.696 5.28641L8.0875 3.06C8.93314 1.70697 9.35596 1.03046 9.0704 0.515231C8.78483 0 7.98705 0 6.3915 0H3.6085C2.01295 0 1.21517 0 0.929605 0.515231C0.644039 1.03046 1.06686 1.70697 1.9125 3.06L3.304 5.28641Z"
        fill="#0E0E11"
      />
    </svg>
  );
}
