import { TournamentSubHeader } from 'features/shared/ui/TournamentSubHeader';
import { ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UserError } from '@features/shared/api/club/clients';
import {
  ClockControlType,
  Tournament,
  UpdateTournamentRequest,
} from '@features/shared/api/typings';
import { UnsavedChangesModal } from '@features/shared/ui/UnsavedChangesModal/UnsavedChangesModal';
import { TournamentSettings } from '@features/tournament/ui/components/TournamentSettings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings';
import {
  getDefaultValuesFromTournament,
  getSubmitValuesFromForm,
} from '@features/tournament/ui/components/TournamentSettings';
import { cx } from '@libs/classnames';
import { useBlocker } from '@tanstack/react-router';
import { AnimatedNode } from '@ui/components/Animation';
import { AnimationCrossfade } from '@ui/components/AnimationCrossfade';
import { BookmarkButton } from '@ui/components/BookmarkButton';
import { BookmarkButtonBar } from '@ui/components/BookmarkButtonBar';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';
import { Loader } from '@ui/components/Loader';
import { PageTabs } from '@ui/components/PageTabs';
import { DeleteIcon } from '@ui/icons/DeleteIcon';
import { SaveIcon } from '@ui/icons/SaveIcon';
import styles from './TournamentEditPage.module.css';

const TABS = [
  { value: 'settings', label: 'Настройки' } as const,
  { value: 'participants', label: 'Участники' } as const,
];

type PageTab = (typeof TABS)['0']['value'];

export type Props = {
  tournament: Tournament;
  pageTab: PageTab;
  onPageTabChange: (page: PageTab) => void;
  onBack: () => void;
  onDelete?: () => void;
  onSave?: (tournament: UpdateTournamentRequest) => void | Promise<unknown>;
  participants?: (
    key: string,
    isTeam: boolean,
    clockControl: ClockControlType,
  ) => ReactNode;
  onUploadLogo: (file: FormData) => Promise<void>;
  onUploadRules: (file: FormData) => Promise<void>;
  disabled: boolean;
  enableUnsavedWarning?: boolean;
  _useNavigationBlocker?: typeof useBlocker; // HACK: для сторей
};

export function TournamentEditPage({
  tournament,
  pageTab,
  onPageTabChange,
  participants,
  onBack,
  onDelete,
  onSave,
  onUploadLogo,
  onUploadRules,
  disabled,
  enableUnsavedWarning,
  _useNavigationBlocker = useBlocker,
}: Props) {
  const isMatch = tournament.system === 'OneOnOne';
  const methods = useForm<TournamentEditFormValues>({
    disabled,
    defaultValues: getDefaultValuesFromTournament(tournament),
  });
  const { error, reset } = useErrorNotification();
  let errStr = error instanceof UserError && error?.message;
  try {
    errStr = errStr && JSON.parse(errStr).message;
  } catch (_e: unknown) {
    console.error('Failed to parse error in tournament edit', error);
  }

  const onSubmit = (tournament: TournamentEditFormValues) => {
    reset();
    return onSave && onSave(getSubmitValuesFromForm(tournament));
  };
  const onError = () => {
    //console.log(error);
  };

  const { isSubmitting, dirtyFields, isSubmitSuccessful } = methods.formState;

  // Предупреждение о несохранённых данных
  // ВАЖНО: formState.isDirty ненадёжен и даёт false positives
  const isDirty = Object.keys(dirtyFields).length > 0;
  const unsavedBlock = _useNavigationBlocker({
    condition:
      enableUnsavedWarning && isDirty && !isSubmitSuccessful && !disabled,
  });

  //

  const handleSubmit = methods.handleSubmit(onSubmit, onError);

  const isTeam = (methods.getValues('mode') || tournament.mode) === 'Team';
  const clockControl = methods.getValues('clockControl');
  const title = methods.watch('title') ?? tournament.title;

  useEffect(() => {
    methods.setValue('logoUrl', tournament.logoUrl);
    methods.setValue('rulesUrl', tournament.rulesUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tournament.logoUrl, tournament.rulesUrl]);

  return (
    <>
      <TournamentSubHeader title={title || 'Без названия'} onBack={onBack} />

      {!tournament && <Loader className={styles.loader} centered />}

      {tournament && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <div className={styles.root}>
              <PageTabs
                className={styles.tabs}
                tabs={TABS}
                onChange={onPageTabChange}
                value={pageTab}
              />
              <div className={styles.error}>{errStr}</div>
              <AnimationCrossfade>
                {pageTab !== 'participants' ? (
                  <TournamentSettings
                    className={cx({
                      [styles.submitting]: isSubmitting,
                    })}
                    key="settings"
                    onUploadRules={onUploadRules}
                    onUploadLogo={onUploadLogo}
                  />
                ) : (
                  participants?.('participants', isTeam, clockControl)
                )}
              </AnimationCrossfade>
            </div>

            <BookmarkButtonBar>
              <BookmarkButton
                icon={<SaveIcon />}
                label="Сохранить"
                onClick={handleSubmit}
                disabled={disabled || isSubmitting}
              />
              <BookmarkButton
                icon={<DeleteIcon />}
                label="Удалить"
                clickedStateBottom={
                  <div className={styles.clickable} onClick={onDelete}>
                    Да, удалить
                  </div>
                }
                clickedStateTop={
                  <div>Удалить {isMatch ? 'матч' : 'турнир'}</div>
                }
                disabled={disabled || isSubmitting}
              />
            </BookmarkButtonBar>
          </form>

          {/* Предупреждение о несохранённых изменениях */}
          <AnimatedNode>
            {unsavedBlock.status === 'blocked' && (
              <UnsavedChangesModal
                onProceed={unsavedBlock.proceed}
                onStay={unsavedBlock.reset}
              />
            )}
          </AnimatedNode>
        </FormProvider>
      )}
    </>
  );
}

export function TournamentEditPageSkeleton() {
  return (
    <>
      <TournamentSubHeader title="" />
      <Loader className={styles.loader} centered />
    </>
  );
}
