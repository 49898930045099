import { useFormContext, useWatch } from 'react-hook-form';
import { OneOnOneSettings } from '@features/tournament/ui/components/TournamentSettings/OneOnOneSettings';
import { AdditionalScoreInput } from '@features/tournament/ui/components/TournamentSettings/inputs/AdditonalScoreInput';
import { ArmageddonInput } from '@features/tournament/ui/components/TournamentSettings/inputs/ArmageddonInput';
import { ContactsInput } from '@features/tournament/ui/components/TournamentSettings/inputs/ContactsInput';
import { EntranceInput } from '@features/tournament/ui/components/TournamentSettings/inputs/EntranceInput';
import { GameSystemInput } from '@features/tournament/ui/components/TournamentSettings/inputs/GameSystemInput';
import { LogoFileInput } from '@features/tournament/ui/components/TournamentSettings/inputs/LogoFileInput';
import { ModeInput } from '@features/tournament/ui/components/TournamentSettings/inputs/ModeInput';
import { RcfRankInput } from '@features/tournament/ui/components/TournamentSettings/inputs/RcfRankInput';
import { RulesFileInput } from '@features/tournament/ui/components/TournamentSettings/inputs/RulesFileInput';
import { StartTimeInput } from '@features/tournament/ui/components/TournamentSettings/inputs/StartTimeInput';
import { TitleInput } from '@features/tournament/ui/components/TournamentSettings/inputs/TitleInput';
import { TournamentClockControlInput } from '@features/tournament/ui/components/TournamentSettings/inputs/TournamentClockControlInput';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { cx } from '@libs/classnames';
import { Form } from '@ui/components/Form';
import styles from './TournamentSettings.module.css';
import { TimeBetweenRoundsInput } from './inputs/TimeBetweenRoundsInput';

type Props = {
  className?: string;
  onUploadLogo: (file: FormData) => Promise<void>;
  onUploadRules: (file: FormData) => Promise<void>;
};

export function TournamentSettings({
  className,
  onUploadLogo,
  onUploadRules,
}: Props) {
  const { control } = useFormContext<TournamentEditFormValues>();
  const system = useWatch({
    name: 'system',
    control,
  });
  const isMatch = system === 'OneOnOne';
  const isTournament = !isMatch;

  return (
    <Form className={cx(className, styles.root)}>
      <TitleInput />
      <StartTimeInput />
      {isTournament && <EntranceInput />}
      <RcfRankInput />
      <ModeInput />
      {isTournament && <GameSystemInput />}
      <TimeBetweenRoundsInput />
      {isTournament && <TournamentClockControlInput />}
      {isMatch && <OneOnOneSettings />}
      {isTournament && <AdditionalScoreInput />}
      <ArmageddonInput />
      <RulesFileInput onUpload={onUploadRules} />
      <LogoFileInput onUpload={onUploadLogo} />
      <ContactsInput />
    </Form>
  );
}
