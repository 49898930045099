import { useCallback, useState } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { useClubEventsQuery } from '@features/club/api/hooks';
import {
  ClubEventList,
  ClubEventListProps,
} from '@features/club/ui/ClubEventList';
import { useNavigate } from '@tanstack/react-router';

export type Props = {
  className?: string;
  clubId: string;
};

export function ClubEventListWidget({ className, clubId }: Props) {
  const [tab, setTab] = useState<ClubEventListProps['tab']>('upcoming');
  const navigate = useNavigate();
  const onTournament = useCallback(
    (tournamentId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId',
        params: { clubId, tournamentId },
      });
    },
    [navigate, clubId],
  );

  const { isManager } = useClubRoles(clubId);

  const { data, isPending } = useClubEventsQuery(clubId, tab, isManager);

  return (
    <ClubEventList
      className={className}
      tab={tab}
      loading={isPending}
      events={data}
      onTab={setTab}
      onTournament={onTournament}
    />
  );
}
