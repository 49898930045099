export type PluralWordForms = {
  zero?: string;
  one: string;
  few?: string;
  many: string;
};

export function pluralize(wordForms: PluralWordForms, count?: number) {
  let pattern = wordForms.many;
  const cntStr = count?.toString() || '0';

  if (!count) {
    pattern = wordForms.zero !== undefined ? wordForms.zero : wordForms.many;
  } else if (count > 10 && count <= 20) {
    pattern = wordForms.many;
  } else if (count % 10 === 1) {
    pattern = wordForms.one;
  } else if (count % 10 >= 2 && count % 10 <= 4) {
    pattern = wordForms.few || wordForms.many;
  }

  return pattern.replaceAll('{number}', cntStr);
}

// нет машин, 1 машина, 2 машины, 10 машин
// нет бананов, 1 банан, 2 банана, 100 бананов
// нет людей, 1 человек, 42 человека
