import { useEffect } from 'react';
import { applyClubColor, removeClubColor } from '../utils/clubColors';

export function useClubColors(themeColor?: string | null) {
  useEffect(() => {
    if (!themeColor) {
      removeClubColor();
      return;
    }

    if (themeColor) applyClubColor(themeColor);
  }, [themeColor]);
}
