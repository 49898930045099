export function RemoveIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6716 0.328446C13.5675 0.224323 13.444 0.141722 13.3081 0.0853653C13.1721 0.0290081 13.0264 0 12.8792 0C12.7321 0 12.5863 0.0290081 12.4504 0.0853653C12.3144 0.141722 12.1909 0.224323 12.0869 0.328446L6.99987 5.4155L1.91282 0.328446C1.70268 0.118311 1.41768 0.000258454 1.1205 0.000258456C0.823327 0.000258458 0.538323 0.118311 0.328188 0.328446C0.118053 0.538581 2.21413e-09 0.823585 0 1.12076C-2.21413e-09 1.41794 0.118053 1.70294 0.328188 1.91308L5.41524 7.00013L0.328188 12.0872C0.118053 12.2973 0 12.5823 0 12.8795C0 13.1767 0.118053 13.4617 0.328188 13.6718C0.538323 13.8819 0.823327 14 1.1205 14C1.41768 14 1.70268 13.8819 1.91282 13.6718L6.99987 8.58476L12.0869 13.6718C12.2971 13.8819 12.5821 14 12.8792 14C13.1764 14 13.4614 13.8819 13.6716 13.6718C13.8817 13.4617 13.9997 13.1767 13.9997 12.8795C13.9997 12.5823 13.8817 12.2973 13.6716 12.0872L8.5845 7.00013L13.6716 1.91308C13.7757 1.80907 13.8583 1.68556 13.9146 1.54961C13.971 1.41366 14 1.26793 14 1.12076C14 0.973591 13.971 0.827865 13.9146 0.691914C13.8583 0.555962 13.7757 0.432453 13.6716 0.328446Z"
        fill="white"
      />
    </svg>
  );
}
