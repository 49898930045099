import { ThemeIconButton } from 'features/shared/ui/ThemeIconButton';
import { ReactNode, useCallback, useState } from 'react';
import {
  ClockControlType,
  ParticipantDto,
  TournamentTeam,
} from '@features/shared/api/typings';
import { MoveParticipantButton } from '@features/tournament/ui/components/MoveParticipantButton/MoveParticipantButton';
import { TeamAddForm } from '@features/tournament/ui/components/TeamAddForm';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import { Popover } from '@ui/components/Popover';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import { Tabs } from '@ui/components/Tabs';
import { ThemeButton } from '@ui/components/ThemeButton';
import { RemoveIcon } from '@ui/icons/RemoveIcon';
import styles from './TournamentParticipantsEdit.module.css';

type Tab = 'Invited' | 'Participating' | 'Exited' | 'Banned';

const filterTabs: { value: Tab; label: string }[] = [
  { value: 'Participating', label: 'Подтвердившие' },
  { value: 'Invited', label: 'Приглашенные' },
  { value: 'Exited', label: 'Удаленные' },
  { value: 'Banned', label: 'Забаненные' },
];

export type Props = {
  className?: string;
  isPending: boolean;
  participants?: ParticipantDto[];
  tab: Tab;
  onTabChange: (newTab: Tab) => void;
  isTeam?: boolean;
  teams?: TournamentTeam[];
  clockControl: ClockControlType;
  maxPerTeam: number;
  onCreateTeam: (teamName: string) => void;
  onRemoveTeam: (teamId: string) => void;
  onRemoveParticipant: (userId: string) => void;
  onMoveParticipant: (teamId: string) => void;
  modal: ReactNode;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  disabled?: boolean;
};

export function TournamentParticipantsEdit({
  className,
  isPending,
  participants,
  tab,
  onTabChange,
  isTeam,
  teams,
  clockControl,
  maxPerTeam,
  onCreateTeam,
  onRemoveTeam,
  onRemoveParticipant,
  onMoveParticipant,
  modal,
  modalVisible,
  setModalVisible,
  disabled,
}: Props) {
  const enrichedTeams = teams?.map((t) => ({
    ...t,
    isFull:
      (participants &&
        participants.filter((p) => p.teamId === t.teamId).length >=
          maxPerTeam) ||
      false,
  }));

  return (
    <div className={cx(className, styles.root)}>
      <Tabs
        className={styles.tabs}
        tabs={filterTabs}
        onChange={onTabChange}
        value={tab}
        disabled={disabled}
      />

      {isPending && <Loader className={styles.loader} centered />}

      {!isPending && (
        <>
          <Table className={styles.table}>
            {!!participants?.length && <ParticipantsTableHead />}

            {isTeam && tab === 'Participating' ? (
              <TeamTable
                teams={enrichedTeams || []}
                participants={participants || []}
                onRemoveTeam={onRemoveTeam}
                onRemoveParticipant={onRemoveParticipant}
                clockControl={clockControl}
                onMove={onMoveParticipant}
              />
            ) : (
              <ParticipantsTable
                participants={participants || []}
                onRemove={onRemoveParticipant}
                clockControl={clockControl}
                disabled={disabled}
              />
            )}
          </Table>
          <ParticipantsTableFooter
            tab={tab}
            onTeamCreate={
              (isTeam && tab === 'Participating' && onCreateTeam) || undefined
            }
            totalTeamsCount={teams?.length || 0}
            modal={modal}
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
}

function ParticipantsTableHead() {
  return (
    <TableRow header>
      <TableCell colSpan={5}>Имя</TableCell>
      <TableCell right>Рейтинг</TableCell>
      <TableCell right className={styles.buttonColumn}></TableCell>
    </TableRow>
  );
}

type ParticipantsTableFooterProps = {
  tab: Tab;
  onTeamCreate?: (newTeamName: string) => void;
  modal: ReactNode;
  modalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
  totalTeamsCount: number;
  disabled?: boolean;
};
function ParticipantsTableFooter({
  tab,
  onTeamCreate,
  totalTeamsCount,
  modal,
  modalVisible,
  setModalVisible,
  disabled,
}: ParticipantsTableFooterProps) {
  const [teamFormVisible, setTeamFormVisible] = useState(false);
  const showParticipantsModal = useCallback(
    () => setModalVisible(true),
    [setModalVisible],
  );
  const hideParticipantsModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleCreateTeam = useCallback(
    async (newTeamName: string) => {
      await onTeamCreate?.(newTeamName);
      setTeamFormVisible(false);
    },
    [onTeamCreate, setTeamFormVisible],
  );

  return (
    <>
      <div className={styles.addButtons}>
        {teamFormVisible && (
          <TeamAddForm
            totalTeamsCount={totalTeamsCount}
            onSubmit={handleCreateTeam}
            onCancel={() => setTeamFormVisible(false)}
            disabled={disabled}
          />
        )}
        {!teamFormVisible && (
          <>
            {tab === 'Participating' && onTeamCreate && (
              <ThemeButton
                className={styles.button}
                onClick={() => setTeamFormVisible(true)}
                disabled={disabled}
              >
                Добавить команду
              </ThemeButton>
            )}
            {tab === 'Participating' && (
              <ThemeButton
                className={styles.button}
                onClick={showParticipantsModal}
                disabled={disabled}
              >
                Добавить участника
              </ThemeButton>
            )}
            {tab === 'Invited' && (
              <ThemeButton
                className={styles.button}
                onClick={showParticipantsModal}
                disabled={disabled}
              >
                Пригласить участника
              </ThemeButton>
            )}
          </>
        )}
      </div>

      <div
        className={cx(styles.popoverWrapper, {
          [styles.active]: modalVisible,
        })}
      >
        <Popover
          className={styles.participantsPopover}
          active={modalVisible}
          onHide={hideParticipantsModal}
        >
          {modalVisible && modal}
        </Popover>
      </div>
    </>
  );
}

type ParticipantsTableProps = {
  participants: ParticipantDto[];
  onRemove: (id: string) => void;
  clockControl?: ClockControlType;
  disabled?: boolean;
};

function ParticipantsTable({
  participants,
  onRemove,
  clockControl,
  disabled,
}: ParticipantsTableProps) {
  return (
    <>
      {participants.map((participant, i) => (
        <ParticipantRow
          key={participant.userId}
          participant={participant}
          num={++i}
          onRemove={onRemove}
          clockControl={clockControl}
          disabled={disabled}
        />
      ))}
    </>
  );
}

type ParticipantRowProps = {
  participant: ParticipantDto;
  num: number;
  onRemove: (id: string) => void;
  clockControl?: ClockControlType;
  teams?: (TournamentTeam & { isFull: boolean })[];
  currentTeamId?: string;
  onMove?: (userId: string, teamId?: string) => void;
  disabled?: boolean;
};

function ParticipantRow({
  participant,
  num,
  onRemove,
  clockControl,
  teams,
  currentTeamId,
  onMove,
  disabled,
}: ParticipantRowProps) {
  const handleRemove = useCallback(() => {
    onRemove(participant.userId);
  }, [participant, onRemove]);

  let rank;
  if (clockControl === 'Blitz') rank = participant.elo.blitz.rating.toString();
  if (clockControl === 'Rapid') rank = participant.elo.rapid.rating.toString();
  if (clockControl === 'Classic')
    rank = participant.elo.classic.rating.toString();

  return (
    <TableRow hoverable>
      {onMove && (
        <TableCell className={styles.burgerButtonCell}>
          <MoveParticipantButton
            teams={teams}
            currentTeamId={currentTeamId}
            onMove={(teamId?: string) => onMove?.(participant.userId, teamId)}
          />
        </TableCell>
      )}
      <TableCell className={styles.numbers}>{num}</TableCell>
      <TableCell>
        {participant.lastName} {participant.firstName}
      </TableCell>
      <TableCell>{participant.title}</TableCell>
      <TableCell>{participant.rcfId}</TableCell>
      <TableCell slim right>
        {rank}
      </TableCell>
      <TableCell slim right>
        <ThemeIconButton onClick={handleRemove} disabled={disabled}>
          <RemoveIcon />
        </ThemeIconButton>
      </TableCell>
    </TableRow>
  );
}

type TeamTableProps = {
  teams: (TournamentTeam & { isFull: boolean })[];
  participants: ParticipantDto[];
  onRemoveTeam: (teamId: string) => void;
  onRemoveParticipant: (userId: string) => void;
  clockControl?: ClockControlType;
  onMove?: (
    userId: string,
    newTeamId?: string,
    numberInTeam?: number,
    oldTeamId?: string,
  ) => void;
  disabled?: boolean;
};

function TeamTable({
  teams,
  participants,
  onRemoveTeam,
  onRemoveParticipant,
  clockControl,
  onMove,
  disabled,
}: TeamTableProps) {
  const participantsByTeams: { [k: string]: ParticipantDto[] } = {};
  participants.forEach((p) => {
    const key = p.teamId || 'free';
    if (!participantsByTeams[key]) participantsByTeams[key] = [];
    participantsByTeams[key]!.push(p);
  });

  return (
    <>
      {participantsByTeams['free'] &&
        participantsByTeams['free'].length > 0 &&
        participantsByTeams['free'].map((p, i) => (
          <ParticipantRow
            key={p.userId}
            teams={teams}
            num={++i}
            participant={p}
            disabled={disabled}
            onRemove={onRemoveParticipant}
            clockControl={clockControl}
            onMove={(userId: string, teamId?: string) => {
              const numberInTeam = teamId
                ? (participantsByTeams[teamId]?.length || 0) + 1
                : undefined;
              onMove?.(userId, teamId, numberInTeam);
            }}
          />
        ))}

      {teams.length > 0 &&
        teams.map((team, i) => (
          <TeamRow
            key={team.teamId}
            num={++i}
            onRemoveTeam={onRemoveTeam}
            onRemoveParticipant={onRemoveParticipant}
            teamId={team.teamId}
            name={team.name}
            teams={teams}
            participants={participantsByTeams[team.teamId] || []}
            clockControl={clockControl}
            onMove={(userId: string, teamId?: string) =>
              onMove?.(
                userId,
                teamId,
                teamId ? participantsByTeams[teamId]?.length || 1 : undefined,
                team.teamId,
              )
            }
            disabled={disabled}
          />
        ))}
    </>
  );
}

type TeamRowProps = {
  num: number;
  name: string;
  teams: (TournamentTeam & { isFull: boolean })[];
  teamId: string;
  onRemoveTeam: (teamId: string) => void;
  onRemoveParticipant: (userId: string) => void;
  participants: ParticipantDto[];
  clockControl?: ClockControlType;
  onMove: (userId: string, teamId?: string) => void;
  disabled?: boolean;
};

function TeamRow({
  num,
  name,
  teamId,
  teams,
  onRemoveTeam,
  onRemoveParticipant,
  disabled,
  onMove,
  clockControl,
  participants,
}: TeamRowProps) {
  const handleRemove = useCallback(() => {
    onRemoveTeam(teamId);
  }, [teamId, onRemoveTeam]);

  return (
    <>
      <TableRow hoverable className={styles.teamRow}>
        <TableCell className={styles.numbers}>{num}</TableCell>
        <TableCell colSpan={4}>{name}</TableCell>
        <TableCell right></TableCell>
        <TableCell right>
          <ThemeIconButton onClick={handleRemove} disabled={disabled}>
            <RemoveIcon />
          </ThemeIconButton>
        </TableCell>
      </TableRow>
      {participants.map((p) => (
        <ParticipantRow
          key={p.userId}
          teams={teams}
          currentTeamId={teamId}
          onMove={onMove}
          participant={p}
          num={p.numberInTeam!}
          onRemove={onRemoveParticipant}
          disabled={disabled}
          clockControl={clockControl}
        />
      ))}
    </>
  );
}
