import { useCallback, useState } from 'react';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import { useTournamentGames } from '@features/tournament/hooks/useTournamentGames';
import { useNavigate } from '@tanstack/react-router';
import { TournamentGames } from './TournamentGames';

/*
    ____,-------------------------------,____
    \   |            Виджет             |   /
    /___|-------------------------------|___\
*/

type Props = { clubId: string; tournamentId: string };

export function TournamentGamesWidget({ clubId, tournamentId }: Props) {
  const navigate = useNavigate();

  const { gamesHidden, onGamesToggle } = useGamesToggle();

  const tournamentQuery = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });

  const rounds = tournamentQuery.data?.rounds
    .filter((r) => r.status === 'InProgress' || r.status === 'Finished')
    .sort((r1, r2) => r1.roundNumber - r2.roundNumber);
  const [round, setRound] = useState<number | null>(null);
  const selectedRound = round
    ? rounds?.find((r) => r.roundNumber === round)
    : rounds?.at(-1);

  const { games, cachedGames } = useTournamentGames(
    tournamentId,
    selectedRound?.roundNumber || NaN,
    {
      enabled: !gamesHidden && !!selectedRound,
    },
  );

  const onGameClick = useCallback(
    (gameId: string) => {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/game/$gameId',
        params: { clubId, tournamentId, gameId },
      });
    },
    [navigate, clubId, tournamentId],
  );

  if (!selectedRound) return null;

  return (
    <TournamentGames
      key={selectedRound?.roundId}
      games={games ?? cachedGames}
      loading={!games}
      showToggle
      gamesHidden={gamesHidden}
      roundNumber={selectedRound?.roundNumber}
      roundsCount={rounds?.length}
      onGameClick={onGameClick}
      onGamesToggle={onGamesToggle}
      onRoundClick={setRound}
    />
  );
}

/*
    ____,-------------------------------,____
    \   |            Запчасти           |   /
    /___|-------------------------------|___\
*/

const LOCAL_STORAGE_KEY = 'hide_tournament_games';

function useGamesToggle() {
  const [gamesHidden, setGamesHidden] = useState(() => {
    return Boolean(localStorage.getItem(LOCAL_STORAGE_KEY));
  });
  const onGamesToggle = useCallback(
    (newValue: boolean) => {
      setGamesHidden(newValue);
      localStorage.setItem(LOCAL_STORAGE_KEY, newValue ? 'true' : '');
    },
    [setGamesHidden],
  );

  return { gamesHidden, onGamesToggle };
}
