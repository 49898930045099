export function CreateIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88861 3.37061C12.1776 1.17298 16.0444 0 20 0C25.3043 0 30.3914 2.10714 34.1422 5.85787C37.8929 9.60859 40 14.6957 40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9992 39.9914 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65493 36.9392 5.85787 34.1421C3.06082 31.3451 1.15601 27.7814 0.384303 23.9018C-0.387401 20.0222 0.00866564 16.0009 1.52242 12.3463C3.03617 8.69181 5.59962 5.56824 8.88861 3.37061ZM11.6665 32.472C14.1332 34.1203 17.0333 35 20 35C23.9783 35 27.7936 33.4197 30.6066 30.6066C33.4197 27.7936 35 23.9783 35 20C35 17.0333 34.1203 14.1332 32.4721 11.6664C30.8238 9.19971 28.4812 7.27712 25.7403 6.14181C22.9994 5.00649 19.9834 4.70944 17.0737 5.28822C14.1639 5.867 11.4912 7.29561 9.39341 9.3934C7.29562 11.4912 5.86701 14.1639 5.28823 17.0736C4.70945 19.9834 5.0065 22.9994 6.14182 25.7403C7.27713 28.4811 9.19972 30.8238 11.6665 32.472ZM22.5 17.5H26.875C27.5381 17.5 28.174 17.7634 28.6428 18.2322C29.1116 18.7011 29.375 19.337 29.375 20C29.375 20.663 29.1116 21.2989 28.6428 21.7678C28.174 22.2366 27.5381 22.5 26.875 22.5H22.5V26.875C22.5 27.538 22.2366 28.1739 21.7678 28.6428C21.299 29.1116 20.6631 29.375 20 29.375C19.337 29.375 18.7011 29.1116 18.2323 28.6428C17.7634 28.1739 17.5 27.538 17.5 26.875V22.5H13.125C12.462 22.5 11.8261 22.2366 11.3573 21.7678C10.8884 21.2989 10.625 20.663 10.625 20C10.625 19.337 10.8884 18.7011 11.3573 18.2322C11.8261 17.7634 12.462 17.5 13.125 17.5H17.5V13.125C17.5 12.462 17.7634 11.8261 18.2323 11.3572C18.7011 10.8884 19.337 10.625 20 10.625C20.6631 10.625 21.299 10.8884 21.7678 11.3572C22.2366 11.8261 22.5 12.462 22.5 13.125V17.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
