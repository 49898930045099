import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { AdditionalGamesCountInput } from '@features/tournament/ui/components/TournamentSettings/inputs/AdditionalGamesCountInput';
import { GamesCountInput } from '@features/tournament/ui/components/TournamentSettings/inputs/GamesCountInput';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function OneOnOneSettings() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const [system, clockControl, oneOnOneSettings] = useWatch({
    name: ['system', 'clockControl', 'oneOnOneSettings'],
    control,
  });

  if (system !== 'OneOnOne') return null;

  return (
    <>
      <GamesCountInput />

      <ClockControlInput
        label="Контроль времени"
        perPlayer
        value={{
          clockControl,
          clockControlSettingsPlayer1:
            oneOnOneSettings.clockControlSettingsPlayer1,
          clockControlSettingsPlayer2:
            oneOnOneSettings.clockControlSettingsPlayer2,
        }}
        onChange={({
          clockControl,
          clockControlSettingsPlayer1,
          clockControlSettingsPlayer2,
        }) => {
          setValue('clockControl', clockControl);
          setValue(
            'oneOnOneSettings.clockControlSettingsPlayer1',
            clockControlSettingsPlayer1,
          );
          setValue(
            'oneOnOneSettings.clockControlSettingsPlayer2',
            clockControlSettingsPlayer2,
          );
        }}
        disabled={disabled}
      />

      <AdditionalGamesCountInput />

      <ClockControlInput
        label="Время"
        labelIndented
        perPlayer
        value={{
          clockControl: oneOnOneSettings.tieSettings.clockControl,
          clockControlSettingsPlayer1:
            oneOnOneSettings.tieSettings.clockControlSettingsPlayer1,
          clockControlSettingsPlayer2:
            oneOnOneSettings.tieSettings.clockControlSettingsPlayer2,
        }}
        onChange={({
          clockControl,
          clockControlSettingsPlayer1,
          clockControlSettingsPlayer2,
        }) => {
          setValue('oneOnOneSettings.tieSettings.clockControl', clockControl);
          setValue(
            'oneOnOneSettings.tieSettings.clockControlSettingsPlayer1',
            clockControlSettingsPlayer1,
          );
          setValue(
            'oneOnOneSettings.tieSettings.clockControlSettingsPlayer2',
            clockControlSettingsPlayer2,
          );
        }}
        disabled={
          oneOnOneSettings.tieSettings.additionalGamesCount < 1 || disabled
        }
      />
    </>
  );
}
