import { Chess, Move } from 'chess.js';
import { PromotionPiece, SquareName } from '@libs/chess/types';

export type Action = 'move' | 'premove';
export type AllowedAction = 'move' | 'premove' | 'none';

/*
    ____,-------------------------------,____
    \   |         State Machine         |   /
    /___|-------------------------------|___\
*/

export type SelectionFSM =
  | SelectionIdle
  | SelectionTargeting
  | SelectionPromoting
  | SelectionSelected;

export class SelectionIdle {
  name = 'idle' as const;

  action: undefined;
  from: undefined;
  to: undefined;
  moves: undefined;
  promotion: undefined;
  toIdle: undefined;
  toSelected: undefined;
  toPromoting: undefined;

  toTargeting(action: Action, from: SquareName, moves: Move[]) {
    return new SelectionTargeting(action, from, moves);
  }

  transition({
    allowedAction,
    from,
    chess,
  }: {
    allowedAction: AllowedAction;
    from: SquareName;
    chess: Chess;
  }): SelectionIdle | SelectionTargeting {
    if (allowedAction === 'none') return this;

    const piece = chess.get(from);
    if (!piece) return this;

    const sameColor = piece.color === chess.turn();

    if (
      (allowedAction === 'move' && !sameColor) ||
      (allowedAction === 'premove' && sameColor)
    ) {
      return this;
    }

    const moves =
      allowedAction === 'move'
        ? getMoves(chess, from)
        : getPremoves(chess, from);

    return this.toTargeting(allowedAction, from, moves);
  }
}

export class SelectionTargeting {
  name = 'targeting' as const;

  to: undefined;
  promotion: undefined;
  toTargeting: undefined;

  private selfClickedOrDropped = false;

  constructor(
    public action: Action,
    public from: SquareName,
    public moves: Move[],
  ) {}

  toIdle() {
    return new SelectionIdle();
  }

  toSelected(to: SquareName) {
    return new SelectionSelected(this.action, this.from, to);
  }

  toPromoting(to: SquareName, promotion: Move) {
    return new SelectionPromoting(this.action, this.from, to, promotion);
  }

  transition({
    to,
    canReset,
    canReselect,
    chess,
    moveCallback,
    autoPromotion,
  }: {
    to: SquareName;
    canReset: boolean;
    canReselect: boolean;
    chess: Chess;
    moveCallback: (move: Move) => void;
    autoPromotion?: PromotionPiece;
  }):
    | SelectionTargeting
    | SelectionIdle
    | SelectionSelected
    | SelectionPromoting {
    if (this.from === to) {
      this.selfClickedOrDropped = true;
      return this;
    }

    const fromColor = chess.get(this.from)?.color;
    const pieceColor = chess.get(to)?.color;
    const move = this.moves?.find((m) => m.to === to);

    if (move) {
      if (move.promotion && !autoPromotion) {
        return this.toPromoting(to, move);
      } else {
        moveCallback({ ...move, promotion: move.promotion && autoPromotion });
        return this.toSelected(to);
      }
    } else if (canReselect && pieceColor && pieceColor === fromColor) {
      return this.toIdle().transition({
        from: to,
        allowedAction: this.action,
        chess,
      });
    } else if (canReset) {
      return this.toIdle();
    }

    return this;
  }

  transitionCleanup() {
    return this.selfClickedOrDropped ? this.toIdle() : this;
  }
}

export class SelectionPromoting {
  name = 'promoting' as const;

  moves: undefined;
  toPromoting: undefined;

  constructor(
    public action: Action,
    public from: SquareName,
    public to: SquareName,
    public promotion: Move,
  ) {}

  toIdle() {
    return new SelectionIdle();
  }

  toTargeting(from: SquareName, moves: Move[]) {
    return new SelectionTargeting(this.action, from, moves);
  }

  toSelected() {
    return new SelectionSelected(this.action, this.from, this.to);
  }
}

export class SelectionSelected {
  name = 'selected' as const;

  moves: undefined;
  promotion: undefined;
  toSelected: undefined;
  toPromoting: undefined;
  toTargeting: undefined;

  constructor(
    public action: Action,
    public from: SquareName,
    public to: SquareName,
  ) {}

  toIdle() {
    return new SelectionIdle();
  }
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function getMoves(chess: Chess, from: SquareName): Move[] {
  return chess.moves({ verbose: true, square: from });
}

function getPremoves(chess: Chess, from: SquareName): Move[] {
  const futureFens = chess.moves({ verbose: true }).map((m) => m.after);
  const moves: Record<string, Move> = {};

  futureFens.forEach((fen) => {
    const futureChess = new Chess(fen);
    futureChess
      .moves({ verbose: true, square: from })
      .forEach((m) => (moves[m.to] = m));
  });

  return Object.values(moves);
}
