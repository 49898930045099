export function BurgerIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5H12V1.5H0V0.5ZM0 5.5H12V6.5H0V5.5ZM12 10.5H0V11.5H12V10.5Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}
