import { cx } from '@libs/classnames';
import { ArrowSlimIcon } from '@ui/icons/ArrowSlimIcon';
import styles from './BackButton.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
};
export function BackButton({ className, onClick, disabled }: Props) {
  return (
    <span
      className={cx(className, styles.backButton, {
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      <ArrowSlimIcon />
    </span>
  );
}
