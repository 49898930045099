import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';
import { Tabs } from '@ui/components/Tabs';

const armageddonEnabledTabs = [
  { value: true, label: 'Да', disabled: true },
  { value: false, label: 'Нет' },
];

export function ArmageddonInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  const armageddonEnabled = useWatch({ name: 'armageddonEnabled' });
  return (
    <>
      <FormRow>
        <FormLabel>Армагеддон</FormLabel>
        <FormInput>
          <Controller
            name="armageddonEnabled"
            control={control}
            render={({ field: { value, onChange, disabled } }) => (
              <Tabs
                tabs={armageddonEnabledTabs}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </FormInput>
      </FormRow>

      {armageddonEnabled && (
        <FormRow>
          <FormLabel>Правила</FormLabel>
          <FormInput>
            <Controller
              name="armageddonWhiteLimitMinutes"
              control={control}
              render={({ field: { value, onChange, disabled } }) => (
                <Input
                  invisible
                  type="number"
                  rightLabel="мин белым"
                  value={value?.toString() || '0'}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name="armageddonBlackLimitMinutes"
              control={control}
              render={({ field: { value, onChange, disabled } }) => (
                <Input
                  invisible
                  type="number"
                  rightLabel="мин черным"
                  value={value?.toString() || '0'}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
          </FormInput>
        </FormRow>
      )}
    </>
  );
}
