import { useCallback, useEffect, useRef } from 'react';

export function useClickOutside<T extends HTMLElement>(
  callback?: null | (() => void),
) {
  const ref = useRef<T>(null);

  const handleClick = useCallback(
    (e: PointerEvent) => {
      if (ref.current && !ref.current?.contains(e.target as Node)) {
        callback?.();
      }
    },
    [callback],
  );

  useEffect(() => {
    if (!callback) return undefined;

    document.addEventListener('pointerdown', handleClick, { capture: true });

    return () => {
      document.removeEventListener('pointerdown', handleClick, {
        capture: true,
      });
    };
  }, [handleClick, callback]);

  return ref;
}
