import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Form.module.css';

export type Props = {
  className?: string;
  children: ReactNode;
};

export function Form({ className, children }: Props) {
  return <div className={cx(className, styles.form)}>{children}</div>;
}

type FormRowProps = {
  className?: string;
  children: ReactNode;
};
export function FormRow({ className, children }: FormRowProps) {
  return <div className={cx(className, styles.formRow)}>{children}</div>;
}

type FormLabelProps = {
  className?: string;
  indented?: boolean;
  hint?: ReactNode;
  children?: ReactNode;
};
export function FormLabel({
  className,
  indented,
  hint,
  children,
}: FormLabelProps) {
  return (
    <div
      className={cx(className, styles.formLabel, {
        [styles.indented]: indented,
      })}
    >
      {children}
      <br />
      {hint && <span className={styles.hint}>{hint}</span>}
    </div>
  );
}

type FormInputProps = {
  className?: string;
  children: ReactNode;
};
export function FormInput({ className, children }: FormInputProps) {
  return <div className={cx(className, styles.formInput)}>{children}</div>;
}
