import { cx } from '@libs/classnames';
import { ReactNode } from '@tanstack/react-router';
import styles from './GoldenModal.module.css';

type Props = {
  className?: string;
  children: ReactNode;
};

export function GoldenModal({ className, children }: Props) {
  return <section className={cx(styles.modal, className)}>{children}</section>;
}
