import { useCallback, useState } from 'react';
import { cx } from '@libs/classnames';
import { Card } from '@ui/components/Card';
import { Popover } from '@ui/components/Popover';
import { BurgerIcon } from '@ui/icons/BurgerIcon';
import styles from './MoveParticipantButton.module.css';

export type Props = {
  className?: string;
  teams?: {
    teamId: string;
    name: string;
    tournamentId: string;
    clubId: string;
    isFull: boolean;
  }[];
  currentTeamId?: string;
  onMove: (teamId?: string) => void;
};

export function MoveParticipantButton({
  className,
  teams,
  currentTeamId,
  onMove,
}: Props) {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const showPopover = useCallback(() => {
    setPopoverVisible(true);
  }, [setPopoverVisible]);
  const hidePopover = useCallback(() => {
    setPopoverVisible(false);
  }, [setPopoverVisible]);

  return (
    <>
      <div className={cx(styles.burgerButton, className)} onClick={showPopover}>
        <BurgerIcon />
      </div>

      <Popover
        className={styles.movePopover}
        wrapperClassName={styles.movePopoverWrapper}
        onHide={hidePopover}
        active={popoverVisible}
      >
        <Card className={styles.content}>
          <div className={styles.title}>Переместить в команду</div>

          <div className={styles.teams}>
            <TeamRow
              key="no-team"
              id=""
              name="Без команды"
              onClick={onMove}
              disabled={!currentTeamId}
            />

            {teams &&
              teams.length > 0 &&
              teams.map((team) => (
                <TeamRow
                  key={team.teamId}
                  id={team.teamId}
                  name={team.name}
                  onClick={onMove}
                  disabled={team.teamId === currentTeamId || team.isFull}
                />
              ))}

            {!teams || (!teams.length && 'Нет команд')}
          </div>

          <hr className={styles.hr} />

          <div className={styles.cancel} onClick={hidePopover}>
            Отмена
          </div>
        </Card>
      </Popover>
    </>
  );
}

type TeamRowProps = {
  id: string;
  name: string;
  onClick: (teamId: string) => void;
  disabled?: boolean;
};
function TeamRow({ id, name, onClick, disabled }: TeamRowProps) {
  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <div
      className={cx(styles.teamRow, { [styles.disabled]: disabled })}
      onClick={handleClick}
    >
      {name}
    </div>
  );
}
