import { cx } from '@libs/classnames';
import styles from './UserSettingsMenu.module.css';

export type Props = {
  className?: string;
  autoQueen?: boolean;
  darkTheme?: boolean;
  onAutoQueenToggle?: (enabled: boolean) => void;
  onDarkThemeToggle?: (enabled: boolean) => void;
};

export function UserSettingsMenu({
  className,
  autoQueen,
  darkTheme,
  onAutoQueenToggle,
  onDarkThemeToggle,
}: Props) {
  return (
    <ul className={cx(styles.settingsMenu, className)}>
      <li onClick={() => onAutoQueenToggle?.(!autoQueen)}>
        {autoQueen ? 'Выключить автоферзя' : 'Включить автоферзя'}
      </li>
      <li onClick={() => onDarkThemeToggle?.(!darkTheme)}>
        {darkTheme ? 'Выключить темную тему' : 'Включить темную тему'}
      </li>
    </ul>
  );
}
