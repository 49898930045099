/*
    ____,-------------------------------,____
    \   |           Base64URL           |   /
    /___|-------------------------------|___\

    Используется в JWT
    https://base64.guru/standards/base64url

    Работаем с ArrayBuffer чтобы избежать проблем с unicode
*/

export function bufferTobase64Url(buf: ArrayBuffer): string {
  return btoa(
    Array.from(new Uint8Array(buf), (b) => String.fromCharCode(b)).join(''),
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}

export function base64UrlToBuffer(val: string): ArrayBuffer {
  const m = val.length % 4;
  return Uint8Array.from(
    atob(
      val
        .replace(/-/g, '+')
        .replace(/_/g, '/')
        .padEnd(val.length + (m === 0 ? 0 : 4 - m), '='),
    ),
    (c) => c.charCodeAt(0),
  ).buffer;
}

export function base64UrlToUnicode(val: string): string {
  const buffer = base64UrlToBuffer(val);
  return new TextDecoder().decode(buffer);
}
