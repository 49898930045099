import { useCallback, useState } from 'react';
import { useUserSocket } from '@features/shared/api';
import { NoPairModalProps } from '../ui/components/NoPairModal';

export function useNoPairModal(
  tournamentId: string,
): NoPairModalProps | undefined {
  const [noPair, setNoPair] = useState<{ round: number } | null>(null);
  const onClose = useCallback(() => setNoPair(null), []);

  useUserSocket((e) => {
    switch (e.eventType) {
      case 'NoPair':
        if (e.tournamentId === tournamentId) {
          setNoPair({ round: e.roundNumber });
        }
    }
  });

  return noPair ? { roundNumber: noPair.round, onClose } : undefined;
}
