import { PromotionPiece } from '@libs/chess/types';
import { cx } from '@libs/classnames';
import { ChessPieces } from '../ChessPieces/types';
import styles from './PromotionSelector.module.css';

export type Props = {
  piecesStyle: ChessPieces;
  className?: string;
  pieceClassName?: string;
  reverse?: boolean;
  onSelect?: (promoteTo: PromotionPiece) => void;
};

export function PromotionSelector({
  piecesStyle,
  className,
  pieceClassName,
  reverse,
  onSelect,
}: Props) {
  const Queen = piecesStyle['Q'];
  const Rook = piecesStyle['R'];
  const Knight = piecesStyle['N'];
  const Bishop = piecesStyle['B'];
  return (
    <ul
      className={cx(styles.promotion, className, { [styles.reverse]: reverse })}
    >
      <li className={styles.queen} onClick={() => onSelect?.('q')}>
        <Queen className={pieceClassName} />
      </li>
      <li className={styles.rook} onClick={() => onSelect?.('r')}>
        <Rook className={pieceClassName} />
      </li>
      <li className={styles.knight} onClick={() => onSelect?.('n')}>
        <Knight className={pieceClassName} />
      </li>
      <li className={styles.bishop} onClick={() => onSelect?.('b')}>
        <Bishop className={pieceClassName} />
      </li>
    </ul>
  );
}
