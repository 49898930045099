import { useContext } from 'react';
import { AuthContext } from '../providers/AuthProvider';

export function useAuth() {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error(
      'AuthContext not initialized. Please, wrap your components in <AuthProvider />',
    );
  }

  return ctx;
}
