import { format as fmt } from 'date-fns';
import { ru } from 'date-fns/locale';

export type Props = {
  className?: string;
  date: Date | string | number;
  format: string;
};
export function DateView({ className, date, format }: Props) {
  const result = fmt(date || new Date(), format, { locale: ru });
  return <span className={className}>{result}</span>;
}
