import { InfoItem } from 'features/shared/ui/InfoItem';
import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { DateView } from '@ui/components/DateView';
import { ThemeLink } from '@ui/components/ThemeLink';
import styles from './ClubInfo.module.css';

export type Props = {
  club?: {
    membersCount?: number;
    finishedTournamentsCount?: number;
    createdTimestampMs: Date | number | string;
    administrator?: {
      fullName: string;
      phoneNumber: string;
      email: string;
    };
    telegramChannel?: string;
  };
  clubParticipationButton?: ReactNode;
  className?: string;
};

export function ClubInfo({ className, club, clubParticipationButton }: Props) {
  const adminHref = club?.administrator?.email
    ? 'mailto:' + club?.administrator?.email
    : undefined;

  const tgHref = club?.telegramChannel
    ? 'https://t.me/' + club.telegramChannel
    : undefined;

  return (
    <div className={cx(className, styles.clubInfo)}>
      <InfoItem label="Участники" value={club?.membersCount} />
      <InfoItem label="Турниры" value={club?.finishedTournamentsCount} />
      <InfoItem
        label="Создан"
        value={
          club && (
            <DateView date={club.createdTimestampMs} format="dd MMMM yyyy" />
          )
        }
      />
      <InfoItem
        label="Администратор"
        value={
          <ThemeLink href={adminHref}>
            {club?.administrator?.fullName}
          </ThemeLink>
        }
      />
      <InfoItem
        label="Канал"
        value={<ThemeLink href={tgHref}>{club?.telegramChannel}</ThemeLink>}
      />
      <div>{clubParticipationButton}</div>
    </div>
  );
}
