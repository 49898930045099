import { LoginPage } from '@features/auth/pages/LoginPage';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/login')({
  component: () => <Page />,
  validateSearch: (search) => {
    return {
      back: search?.back,
      'chessopen-token': search?.['chessopen-token'],
    } as { back?: string; 'chessopen-token'?: string };
  },
});

function Page() {
  const search = Route.useSearch();
  return (
    <LoginPage
      redirect={search.back}
      chessopenToken={search['chessopen-token']}
    />
  );
}
