import { useCallback } from 'react';
import { cx } from '@libs/classnames';
import styles from './NumberInput.module.css';

export type Props = {
  className?: string;
  onChange: (value: number) => void;
  value: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
};
export function NumberInput({
  className,
  onChange,
  value,
  min,
  max,
  step,
  disabled,
}: Props) {
  const minusDisabled = min !== undefined && value <= min;
  const plusDisabled = max !== undefined && value >= max;

  const handleMinus = useCallback(() => {
    if (minusDisabled) return;
    const oldValue = isNaN(value) ? 0 : Number(value);
    let newValue = oldValue - (step || 1);
    if (min !== undefined) {
      newValue = Math.max(newValue, min);
    }
    onChange(newValue);
  }, [value, onChange, min, step, minusDisabled]);

  const handlePlus = useCallback(() => {
    if (plusDisabled) return;
    const oldValue = isNaN(value) ? 0 : Number(value);
    let newValue = oldValue + (step || 1);
    if (max !== undefined) {
      newValue = Math.min(newValue, max);
    }
    onChange(newValue);
  }, [value, onChange, max, step, plusDisabled]);
  return (
    <div
      className={cx(styles.numberInput, className, {
        [styles.disabled]: disabled,
      })}
    >
      <div
        className={cx(styles.button, { [styles.disabled]: minusDisabled })}
        onClick={handleMinus}
      >
        −
      </div>
      <div className={styles.value}>{value}</div>
      <div
        className={cx(styles.button, { [styles.disabled]: plusDisabled })}
        onClick={handlePlus}
      >
        +
      </div>
    </div>
  );
}
