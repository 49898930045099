import { ChessBoardProps } from 'exports/ChessBoard';
import { useCallback, useMemo, useState } from 'react';
import { SquareName } from '@libs/chess/types';
import { useImmediateEffect } from '@libs/hooks/useImmediateEffect';
import { components } from '../api';

const TEXTS = {
  draw: 'Ничья',
  whiteWin: 'Победа белых',
  blackWin: 'Победа черных',
  whiteOffer: 'Белые предлагают ничью',
  blackOffer: 'Черные предлагают ничью',
};

export function useScoresheet(
  game?: components['schemas']['GameDto'],
  props?: ChessBoardProps,
) {
  const [selected, setSelected] = useState<components['schemas']['MoveDto']>();
  const moves = useMemo(() => game?.moves ?? [], [game]);

  const onNavigate = useCallback((next: components['schemas']['MoveDto']) => {
    setSelected(next);
  }, []);

  // сбросить выбор если случился новый ход в игре
  useImmediateEffect(() => {
    if (selected) {
      setSelected(undefined);
    }
  }, [game?.moves.length, game?.result === 'InProgress']);

  const isLastMove =
    selected && selected === game?.moves[game?.moves.length - 1];
  const propsPatch: Partial<ChessBoardProps> | undefined = selected
    ? isLastMove
      ? {
          // выключить анимацию при переключении истории,
          // но включить как только пользователь начнёт перетаскивать фигуру
          noAnimation: props ? props.selectedFrom === selected.from : true,
        }
      : {
          position: selected.fenAfter,
          selectedFrom: selected.from as SquareName,
          selectedTo: selected.to as SquareName,
          noAnimation: true,
          interactive: 'none',
          onSquarePointerDown: undefined,
          winner: undefined,
        }
    : undefined;

  const playersTimeMs = selected
    ? {
        white: selected.clockAfter?.whiteLeftMs,
        black: selected.clockAfter?.blackLeftMs,
      }
    : undefined;

  return {
    props: {
      moves,
      onNavigate,
      selected,
      result:
        mapServerResult(game?.result) ??
        mapClientResult(props?.winner) ??
        mapServerDrawOffer(game?.activeDrawRequest) ??
        undefined,
    },
    propsPatch,
    playersTimeMs,
    moves,
  };
}

function mapServerResult(
  result?: components['schemas']['GameResult'],
): string | null {
  switch (result) {
    case 'InProgress':
    case 'NotStarted':
    case 'Unknown':
    case undefined:
      return null;
    case 'BlackWin':
      return TEXTS.blackWin;
    case 'WhiteWin':
      return TEXTS.whiteWin;
    case 'Draw':
      return TEXTS.draw;
  }
}

function mapClientResult(result: ChessBoardProps['winner']): string | null {
  switch (result) {
    case undefined:
      return null;
    case 'black':
      return TEXTS.blackWin;
    case 'white':
      return TEXTS.whiteWin;
    case 'draw':
      return TEXTS.draw;
  }
}

function mapServerDrawOffer(
  request?: components['schemas']['ActiveDrawRequestDto'],
): string | null {
  if (!request) return null;

  return request.triggeredBySide === 'White'
    ? TEXTS.whiteOffer
    : TEXTS.blackOffer;
}
