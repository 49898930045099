import { useCallback, useRef } from 'react';
import { cx } from '@libs/classnames';
import { Input } from '@ui/components/Input';
import { ArrowIcon } from '@ui/icons/ArrowIcon/ArrowIcon';
import styles from './TimeInput.module.css';

export type Props = {
  className?: string;
  invisible?: boolean;
  value?: string;
  onChange: (newDate: string) => void;
  disabled?: boolean;
};
export function TimeInput({
  className,
  value,
  onChange,
  invisible,
  disabled,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleWrapperClick = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.showPicker();
  }, [inputRef]);

  return (
    <Input
      type="time"
      invisible={invisible}
      inputRef={inputRef}
      rightLabel={
        <div className={styles.pickerButton}>
          <ArrowIcon />
        </div>
      }
      className={cx(className, styles.input)}
      value={value}
      onChange={onChange}
      onClick={handleWrapperClick}
      disabled={disabled}
    />
  );
}
