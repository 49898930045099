import { CSSProperties } from 'react';
import { cx } from '@libs/classnames';
import styles from './ColorSquare.module.css';

export type Props = {
  className?: string;
  style?: CSSProperties;
  color: string;
};

export function ColorSquare({ className, style, color }: Props) {
  return (
    <span
      style={{ background: color, ...style }}
      className={cx(className, styles.colorSquare)}
    />
  );
}
