import { useCallback, useState } from 'react';
import { ARRAY_EMPTY } from '@libs/constants';

/*
    ____,-------------------------------,____
    \   |              Хук              |   /
    /___|-------------------------------|___\
*/

export function useConfimationButton(
  callback?: () => void,
  deps?: React.DependencyList,
) {
  const [armed, setArmed] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOpen = useCallback(() => setArmed(true), ARRAY_EMPTY);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = useCallback(() => setArmed(false), ARRAY_EMPTY);
  const handleConfirm = useCallback(() => {
    callback?.();
    setArmed(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps ?? ARRAY_EMPTY);

  return {
    armed,
    onClick: handleOpen,
    onAbort: handleClose,
    onConfirm: handleConfirm,
  };
}
