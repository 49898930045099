import { useCallback } from 'react';
import { useTournamentCardQuery } from '@features/tournament/api/hooks';
import { GameConflictModalProps } from '../ui/GameConflictModal';
import { useGameRedirectsQueue } from './useAutoGameRedirect';

export function useGameConflictModal(
  currentGameId?: string,
): GameConflictModalProps | null {
  const { queue, open, remove } = useGameRedirectsQueue();
  const nextGame = queue ? queue[0] : null;
  //
  const onIgnore = useCallback(() => {
    if (!nextGame) return;
    remove(nextGame);
  }, [nextGame, remove]);
  //
  const onOpen = useCallback(() => {
    if (!nextGame) return;
    open(nextGame, currentGameId);
  }, [currentGameId, nextGame, open]);

  // Достаём данные для отображения
  const tournament = useTournamentCardQuery(
    nextGame?.clubId ?? '',
    nextGame?.tournamentId ?? '',
    {
      enabled: Boolean(nextGame),
    },
  );

  if (nextGame && tournament.data?.tournamentId === nextGame.tournamentId) {
    return {
      startTimeMs: nextGame.startTimeMs,
      tournament: tournament.data.title,
      onIgnore,
      onOpen,
    };
  }

  return null;
}
