export function DeleteIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5201 0.733217C30.2879 0.500776 30.0122 0.316379 29.7087 0.190568C29.4052 0.0647572 29.0799 0 28.7514 0C28.4228 0 28.0975 0.0647572 27.794 0.190568C27.4905 0.316379 27.2148 0.500776 26.9826 0.733217L15.6264 12.0895L4.27014 0.733217C3.80104 0.264115 3.1648 0.000576968 2.50139 0.000576973C1.83798 0.000576978 1.20174 0.264115 0.73264 0.733217C0.263538 1.20232 4.94279e-09 1.83856 0 2.50197C-4.94279e-09 3.16538 0.263538 3.80161 0.73264 4.27071L12.0889 15.627L0.73264 26.9832C0.263538 27.4523 0 28.0885 0 28.752C0 29.4154 0.263538 30.0516 0.73264 30.5207C1.20174 30.9898 1.83798 31.2533 2.50139 31.2533C3.1648 31.2533 3.80104 30.9898 4.27014 30.5207L15.6264 19.1645L26.9826 30.5207C27.4517 30.9898 28.088 31.2533 28.7514 31.2533C29.4148 31.2533 30.051 30.9898 30.5201 30.5207C30.9892 30.0516 31.2528 29.4154 31.2528 28.752C31.2528 28.0885 30.9892 27.4523 30.5201 26.9832L19.1639 15.627L30.5201 4.27071C30.7526 4.03853 30.937 3.76281 31.0628 3.45932C31.1886 3.15582 31.2533 2.8305 31.2533 2.50197C31.2533 2.17343 31.1886 1.84811 31.0628 1.54462C30.937 1.24112 30.7526 0.965399 30.5201 0.733217Z"
        fill="currentColor"
      />
    </svg>
  );
}
