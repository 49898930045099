import { useContext } from 'react';
import { ErrorsNotificationContext } from './ErrorsNotificationContext';

export function useErrorNotification() {
  const ctx = useContext(ErrorsNotificationContext);

  if (!ctx) {
    throw new Error(
      'ErrorsNotificationContext not initialized. Please, wrap your components in <ErrorsNotificationProvider />',
    );
  }

  return ctx;
}
