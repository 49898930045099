import { CSSProperties } from 'react';

type Props = {
  className?: string;
  style?: CSSProperties;
  ignoreClubColors?: boolean;
  result: 'win' | 'defeat' | 'draw';
};

export function GameScoreCirce({
  className,
  ignoreClubColors,
  style,
  result,
}: Props) {
  switch (result) {
    case 'defeat':
      return (
        <svg
          className={className}
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 16"
        >
          <rect width="16" height="16" fill="#868687" rx="8" />
          <path
            fill="#fff"
            d="M9.565 3.614c.598 0 1.066.212 1.404.637.338.425.507 1.1.507 2.028 0 .641-.082 1.291-.247 1.95a9.017 9.017 0 0 1-.702 1.872 7.835 7.835 0 0 1-1.04 1.586c-.39.46-.814.823-1.274 1.092-.46.269-.936.403-1.43.403-.572 0-1.027-.217-1.365-.65-.33-.433-.494-1.11-.494-2.028 0-.641.083-1.287.247-1.937a9.115 9.115 0 0 1 .69-1.872c.303-.59.65-1.114 1.04-1.573.39-.468.81-.836 1.26-1.105.46-.269.928-.403 1.404-.403Zm-.039.234c-.364 0-.715.156-1.053.468-.33.303-.641.715-.936 1.235a10.275 10.275 0 0 0-.754 1.729 14.595 14.595 0 0 0-.494 1.95c-.121.667-.182 1.3-.182 1.898 0 .607.07 1.062.208 1.365.148.303.334.455.56.455.363 0 .71-.152 1.04-.455.337-.312.65-.724.935-1.235.295-.52.546-1.096.754-1.729a13.88 13.88 0 0 0 .507-1.963c.122-.667.182-1.3.182-1.898 0-.607-.073-1.062-.22-1.365-.14-.303-.321-.455-.547-.455Z"
          />
        </svg>
      );
    case 'win':
      return (
        <svg
          className={className}
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 16"
        >
          <rect
            width="16"
            height="16"
            fill={ignoreClubColors ? '#ECB256' : 'var(--club-background-color)'}
            rx="8"
          />
          <path
            fill="#fff"
            d="M10.4 3.614 7.867 13H6.709l2.197-8.177a8.026 8.026 0 0 1-.82.403c-.294.121-.667.251-1.117.39l.039-.273a8.351 8.351 0 0 0 1.378-.533c.38-.2.71-.399.988-.598.277-.208.533-.407.767-.598h.26Z"
          />
        </svg>
      );
    case 'draw':
      return (
        <svg
          className={className}
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 16 16"
        >
          <rect width="16" height="16" fill="#868687" rx="8" />
          <path
            fill="#fff"
            d="m13.405 2.288.195.169L2.771 13.715l-.195-.169 10.83-11.258Zm-5.239.923L6.853 7.319h-.767L7.14 4.056c-.217.13-.45.256-.702.377a6.05 6.05 0 0 1-.936.325l.065-.247c.416-.13.763-.264 1.04-.403.286-.147.533-.299.741-.455.208-.156.399-.303.572-.442h.247Zm1.885 5.668c.303 0 .55.056.741.169.19.113.286.303.286.572 0 .277-.095.542-.286.793a4.34 4.34 0 0 1-.715.728c-.286.225-.576.438-.87.637-.296.19-.543.368-.742.533.147 0 .32.022.52.065.2.035.399.074.598.117.208.035.381.052.52.052a.737.737 0 0 0 .43-.117.398.398 0 0 0 .181-.338.546.546 0 0 0-.052-.247l.221-.091c.043.06.074.126.091.195.026.07.04.143.04.221a.8.8 0 0 1-.3.624c-.2.173-.49.26-.87.26-.27 0-.512-.035-.729-.104a3.305 3.305 0 0 0-.819-.13 1.764 1.764 0 0 0-.546.026 3.698 3.698 0 0 0-.468.156l.052-.234c.156-.104.355-.234.598-.39.251-.156.511-.325.78-.507.277-.19.537-.394.78-.611.243-.217.438-.438.585-.663.156-.234.234-.472.234-.715 0-.2-.06-.342-.182-.429-.121-.087-.295-.13-.52-.13-.225 0-.46.052-.702.156-.234.095-.464.269-.689.52l-.182-.143c.2-.251.472-.477.82-.676.346-.2.744-.299 1.195-.299Z"
          />
        </svg>
      );
  }
}
