import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { DateTimeInput } from '@ui/components/DateTimeInput';
import { Dropdown } from '@ui/components/Dropdown';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import styles from './TournamentEditFormInputs.module.css';

const promoDropdownOptions = [
  { value: false, label: 'Без промо в клубе' },
  { value: true, label: 'С промо в клубе' },
];

export function StartTimeInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Дата и время</FormLabel>

      <FormInput>
        <Controller
          name="startTime"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <DateTimeInput
              className={styles.dateTimeInput}
              invisible
              onChange={onChange}
              value={value}
              disabled={disabled}
              min={format(new Date(), 'yyyy-MM-dd')}
            />
          )}
        />
        <Controller
          name="promoted"
          control={control}
          render={({ field: { value, onChange, disabled } }) => {
            const onValidChange = (value: string) => {
              onChange(value === 'true');
            };
            return (
              <Dropdown
                className={styles.promotedInput}
                options={promoDropdownOptions}
                value={value ? 'true' : 'false'}
                onChange={onValidChange}
                disabled={disabled}
              />
            );
          }}
        />
      </FormInput>
    </FormRow>
  );
}
