import { CSSProperties } from 'react';
import { cx } from '@libs/classnames';
import styles from './BoardArrow.module.css';

export type Props = {
  className?: string;
  style?: {
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    transform?: CSSProperties['transform'];
  };
  color?: 'primary' | 'secondary' | 'tertiary';
};

// TODO: Тень отличается от фигмы (менее выраженная), но так даже лучше. Если
// пондобится из фигмы, необходимо добавить в <defs> фильтр из svg и применить
// его в CSS (см. как сделано для стрелок в stockfish). Минус — ColorMatrix
// должен быть разным для разных цветов стрелок, что мешает использовать css темы

export function BoardArrow({ color = 'tertiary', className, style }: Props) {
  return (
    // Используется техника sliding window, поэтому у стрелки есть ограничение
    // на максимальную ширину
    <svg
      className={cx(styles.arrow, className, {
        [styles.arrow1]: color === 'primary',
        [styles.arrow2]: color === 'secondary',
        [styles.arrow3]: color === 'tertiary',
      })}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <use href="#tail-5f1fa459ca24" x="0" y="0" width="50%" height="100%" />
      <use
        href="#arrow-5f1fa459ca24"
        width="50%"
        height="100%"
        style={{ translate: '50% 0' }}
      />
      <defs>
        <symbol
          id="tail-5f1fa459ca24"
          viewBox="0 0 1000 62"
          preserveAspectRatio="xMinYMid slice"
        >
          <path
            d="M 0 30.814 c 0 -1.415 0 -2.122 0.44 -2.56 c 0.439 -0.44 1.146 -0.44 2.56 -0.44 h 980 v 6 H 3 c -1.414 0 -2.121 0 -2.56 -0.44 C 0 32.935 0 32.228 0 30.814 Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          id="arrow-5f1fa459ca24"
          viewBox="0 0 1000 62"
          preserveAspectRatio="xMaxYMid slice"
        >
          <use href="#tail-5f1fa459ca24" width="100%" height="100%" />
          <path
            d="M 1000 30.814 c 0 -0.415 -0.333 -0.748 -1 -1.415 L 988.997 19.396 c -0.9 -0.9 -1.349 -1.349 -1.903 -1.392 a 1.495 1.495 0 0 0 -0.23 0 c -0.553 0.043 -1.003 0.493 -1.902 1.392 c -0.983 0.983 -1.475 1.475 -1.494 2.067 a 1.42 1.42 0 0 0 0.012 0.244 c 0.077 0.588 0.614 1.03 1.688 1.912 l 5.108 4.195 H 983 v 6 h 7.276 l -5.108 4.195 c -1.074 0.882 -1.611 1.323 -1.688 1.911 a 1.426 1.426 0 0 0 -0.012 0.244 c 0.019 0.593 0.511 1.084 1.494 2.067 c 0.899 0.9 1.349 1.35 1.902 1.392 c 0.077 0.006 0.154 0.006 0.23 0 c 0.554 -0.043 1.003 -0.492 1.903 -1.392 L 999 32.228 c 0.667 -0.667 1 -1 1 -1.414 Z"
            fill="currentColor"
          />
        </symbol>
      </defs>
    </svg>
  );
}
