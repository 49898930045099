import { useEffect, useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import {
  useAcceptClubMemberMutation,
  useClubCardQuery,
  useClubMembersQuery,
  useDeleteClubMemberMutation,
  useRejectClubMemberMutation,
} from '@features/club/api/hooks';
import { ClubInfoForm } from '@features/club/ui/ClubInfoForm';
import { ClubParticipantsForm } from '@features/club/ui/ClubParticipantsForm';
import { ClubParticipantFormStatus } from '@features/club/ui/ClubParticipantsForm/ClubParticipantsForm';
import { TournamentSubHeader } from '@features/shared/ui/TournamentSubHeader';
import { AnimationCrossfade } from '@ui/components/AnimationCrossfade';
import { PageTabs } from '@ui/components/PageTabs';

type PageTab = 'participants' | 'club' | 'admin';

const tabs: { value: PageTab; label: string; disabled?: boolean }[] = [
  { value: 'participants', label: 'Участники' },
  { value: 'club', label: 'Данные клуба' },
  { value: 'admin', label: 'Администратор', disabled: true },
];

export type Props = {
  clubId: string;
  tab: PageTab;
  onTabChange: (newTab: PageTab) => void;
};

export function ClubSettingsPage({ clubId, tab, onTabChange }: Props) {
  const { data: club } = useClubCardQuery(clubId);
  const { mutate: onDelete, isSuccess: isDeleteSuccess } =
    useDeleteClubMemberMutation(clubId);
  const { mutate: onAccept, isSuccess: isAcceptSuccess } =
    useAcceptClubMemberMutation(clubId);
  const { mutate: onReject, isSuccess: isRejectSuccess } =
    useRejectClubMemberMutation(clubId);
  const [status, setStatus] = useState<ClubParticipantFormStatus>('Active');
  const { user } = useAuth();
  const currentUserId = user?.userId;

  const {
    data: members,
    isPending,
    refetch,
  } = useClubMembersQuery(clubId, {
    sortBy: 'Alphabet',
    status,
    isManager: true,
  });

  useEffect(() => {
    if (isDeleteSuccess || isAcceptSuccess || isRejectSuccess) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSuccess, isAcceptSuccess, isRejectSuccess]);

  return (
    <>
      {club && (
        <>
          <TournamentSubHeader title="Настройки" />

          <PageTabs tabs={tabs} value={tab} onChange={onTabChange} />

          <AnimationCrossfade>
            {(tab === 'participants' && (
              <ClubParticipantsForm
                key="participants"
                participants={members || []}
                onDelete={onDelete}
                onAccept={onAccept}
                onReject={onReject}
                isLoading={isPending}
                status={status}
                setStatus={setStatus}
                currentUserId={currentUserId}
              />
            )) ||
              (tab === 'club' && <ClubInfoForm key="clubInfo" club={club} />)}
          </AnimationCrossfade>
        </>
      )}
    </>
  );
}
