import {
  ClockControlSettings,
  ClockControlType,
  OneOnOneSettings,
  TournamentSystem,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { pluralize, PluralWordForms } from '@libs/i18n';
import styles from './TournamentSystemView.module.css';

const systems: { [k in TournamentSystem]: string } = {
  RoundRobin: 'Круговая\u00A0система',
  Swiss: 'Швейцарская\u00A0система',
  Knockout: 'Нокаут',
  OneOnOne: 'Матч',
};

const timeControls: { [k in ClockControlType]: string } = {
  Blitz: 'Блиц',
  Rapid: 'Рапид',
  Classic: 'Классические\u00A0шахматы',
};

const gamesPlural: PluralWordForms = {
  one: '{number} партия',
  few: '{number} партии',
  many: '{number} партий',
};

export type Props = {
  className?: string;
  tournament?: {
    system: TournamentSystem;
    clockControl?: ClockControlType;
    clockControlSettings?: ClockControlSettings;
    oneOnOneSettings?: OneOnOneSettings;
  };
};

export function TournamentSystemView({ className, tournament }: Props) {
  if (!tournament) return null;

  const system = systems[tournament.system];

  const isMatch = tournament.system === 'OneOnOne';

  let time;
  if (tournament.clockControl) {
    time = ' · ' + timeControls[tournament.clockControl];
    if (
      !isMatch &&
      tournament.clockControlSettings?.initialSeconds !== undefined &&
      tournament.clockControlSettings?.addSecondsPerMove !== undefined
    ) {
      time += `,\u00A0${Math.floor(tournament.clockControlSettings.initialSeconds / 60)}\u00A0+\u00A0${tournament.clockControlSettings.addSecondsPerMove}`;
    }

    if (
      isMatch &&
      tournament.oneOnOneSettings?.clockControlSettingsPlayer1 &&
      tournament.oneOnOneSettings?.clockControlSettingsPlayer2
    ) {
      time += `,\u00A0${Math.floor(tournament.oneOnOneSettings.clockControlSettingsPlayer1.initialSeconds / 60)}\u00A0+\u00A0${tournament.oneOnOneSettings.clockControlSettingsPlayer1.addSecondsPerMove}`;
      time += `,\u00A0${Math.floor(tournament.oneOnOneSettings.clockControlSettingsPlayer2.initialSeconds / 60)}\u00A0+\u00A0${tournament.oneOnOneSettings.clockControlSettingsPlayer2.addSecondsPerMove}`;
    }
  }

  let gamesCount;
  if (isMatch && tournament.oneOnOneSettings) {
    gamesCount =
      ' · ' + pluralize(gamesPlural, tournament.oneOnOneSettings.gamesCount);
  }

  return (
    <span className={cx(className, styles.root)}>
      {system}
      {gamesCount}
      {time}
    </span>
  );
}
