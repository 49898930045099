import { CSSProperties, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './ThemeButton.module.css';

export type Props = {
  style?: CSSProperties;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
  compact?: boolean;
  inverted?: boolean;
};

export function ThemeButton({
  className,
  onClick,
  disabled,
  style,
  children,
  compact,
  inverted,
}: Props) {
  return (
    <div
      onClick={onClick}
      style={style}
      className={cx(className, styles.button, {
        [styles.compact]: compact,
        [styles.inverted]: inverted,
        [styles.disabled]: disabled,
      })}
    >
      {children}
    </div>
  );
}
