import { cx } from '@libs/classnames';
import { HorizontalScroll } from '@ui/components/HorizontalScroll';
import { Loader } from '@ui/components/Loader';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Tabs } from '@ui/components/Tabs';
import { ClubEventCardProps } from '../ClubEventCard';
import { ClubEventCard } from '../ClubEventCard/ClubEventCard';
import styles from './ClubEventList.module.css';

const TABS = [
  { value: 'upcoming', label: 'Ближайшие' } as const,
  { value: 'finished', label: 'Прошедшие' } as const,
];

type TabValue = (typeof TABS)['0']['value'];

export type Props = {
  className?: string;
  tab: TabValue;
  loading?: boolean;
  events?: ClubEventCardProps['tournament'][];
  onTab?: (t: TabValue) => void;
  onTournament?: (tournamentId: string) => void;
};

export function ClubEventList({
  className,
  tab,
  loading,
  events,
  onTab,
  onTournament,
}: Props) {
  const isMobile = useMediaQuery() !== 'desktop';

  return (
    <div className={cx(className, styles.events)}>
      <div className={styles.title}>
        <PageSubTitle>{isMobile ? 'Турниры' : 'События'}</PageSubTitle>
        <Tabs
          tabs={TABS}
          value={tab}
          onChange={(newTab) => onTab?.(newTab as TabValue)}
        />
      </div>

      {(loading && <Loader className={styles.loader} centered />) || (
        <HorizontalScroll className={styles.list}>
          {events && events.length > 0 && (
            <>
              {events.map((evt) => (
                <ClubEventCard
                  key={evt.tournamentId}
                  tournament={evt}
                  onClick={onTournament}
                />
              ))}
            </>
          )}
          {(!events || !events.length) && (
            <div className={styles.placeholder}>Нет турниров</div>
          )}
        </HorizontalScroll>
      )}
    </div>
  );
}
