import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { cx } from '@libs/classnames';
import { DateInput } from '@ui/components/DateInput';
import { TimeInput } from '@ui/components/TimeInput';
import styles from './DateTimeInput.module.css';

export type Props = {
  className?: string;
  invisible?: boolean;
  value: Date | null;
  onChange: (newDate: Date | null) => void;
  disabled?: boolean;
  min?: string;
  max?: string;
};
export function DateTimeInput({
  className,
  invisible,
  value,
  onChange,
  disabled,
  min,
  max,
}: Props) {
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState('00:00');

  const onDateChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        const [hours = 0, minutes = 0] = time.split(':');
        newDate.setHours(Number(hours), Number(minutes), 0);
      }
      setDate(newDate);
      onChange(newDate);
    },
    [onChange, time, setDate],
  );
  const onTimeChange = useCallback(
    (newTime: string) => {
      setTime(newTime);
      if (value) {
        const newDate = new Date(value);
        const [hours = 0, minutes = 0] = newTime.split(':');
        newDate.setHours(Number(hours), Number(minutes), 0);
        onChange(newDate);
      }
    },
    [onChange, value, setTime],
  );

  useEffect(() => {
    if (!value) {
      if (date || time) {
        setDate(null);
        setTime('');
      }
      return;
    }

    if (value !== date) {
      setDate(value);
      const externalTime = format(value, 'HH:mm');
      if (externalTime !== time) {
        setTime(externalTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={cx(className, styles.root)}>
      <DateInput
        invisible={invisible}
        className={styles.dateInput}
        value={date}
        onChange={onDateChange}
        disabled={disabled}
        min={min}
        max={max}
      />
      <TimeInput
        invisible={invisible}
        className={styles.timeInput}
        value={time}
        onChange={onTimeChange}
        disabled={disabled || !value}
      />
    </div>
  );
}
