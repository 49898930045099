import { GET, POST } from '@features/shared/api/club/clients';
import { MemberDto } from '@features/shared/api/typings';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

export function useClubCardQuery(
  clubId: string,
  { enabled = true, staleTime }: { enabled?: boolean; staleTime?: number } = {},
) {
  return useQuery({
    enabled,
    staleTime,
    queryKey: ['/api/v1/user/clubs/{clubId}', clubId],
    queryFn: async () =>
      (
        await GET('/api/v1/user/clubs/{clubId}', {
          params: {
            path: {
              clubId,
            },
          },
        })
      ).data!,
  });
}

export function useClubMembersQuery(
  clubId: string,
  options: {
    sortBy: 'Alphabet' | 'RatingBlitz' | 'RatingRapid' | 'RatingClassic';
    status?: 'JoinRequest' | 'Active' | 'Deleted' | 'Invited';
    isManager?: boolean;
    query?: string;
  },
) {
  const { sortBy, status, isManager } = options;

  const url = isManager
    ? '/api/v1/manager/clubs/{clubId}/members'
    : '/api/v1/user/clubs/{clubId}/members';

  const result = useInfiniteQuery({
    queryKey: [url, clubId, sortBy, status, isManager],
    getNextPageParam: (lastPage: { nextCursor?: number | null }) =>
      lastPage.nextCursor,
    initialPageParam: 0,
    queryFn: async ({ pageParam }) =>
      (
        await GET(url, {
          params: {
            path: {
              clubId,
            },
            query: {
              sortBy,
              status,
              cursor: pageParam ? Number(pageParam) : 0,
              limit: 1000,
            },
          },
        })
      ).data!,
  });

  return {
    ...result,
    data: result?.data?.pages?.reduce(
      // TODO fix, after BE adds participationStatus
      (res: MemberDto[], p) => res.concat(p.result as unknown as MemberDto[]),
      [],
    ),
  };
}

export function useClubEventsQuery(
  clubId: string,
  type: 'upcoming' | 'finished',
  isManager?: boolean,
) {
  const url = isManager
    ? type === 'upcoming'
      ? '/api/v1/manager/clubs/{clubId}/tournaments/upcoming'
      : '/api/v1/manager/clubs/{clubId}/tournaments/finished'
    : type === 'upcoming'
      ? '/api/v1/user/clubs/{clubId}/tournaments/upcoming'
      : '/api/v1/user/clubs/{clubId}/tournaments/finished';

  return useQuery({
    queryKey: [url, clubId, isManager, type],
    queryFn: async () =>
      (
        await GET(url, {
          params: {
            path: {
              clubId,
            },
          },
        })
      ).data!,
  });
}

export function useJoinClubMutation(clubId: string) {
  return useMutation({
    mutationFn: async () => {
      return (
        await POST('/api/v1/user/clubs/{clubId}/membership/actions/join', {
          params: { path: { clubId } },
        })
      ).data!;
    },
  });
}

export function useLeaveClubMutation(clubId: string) {
  return useMutation({
    mutationFn: async () => {
      return (
        await POST('/api/v1/user/clubs/{clubId}/membership/actions/leave', {
          params: { path: { clubId } },
        })
      ).data!;
    },
  });
}

export function useAcceptClubMemberMutation(clubId: string) {
  return useMutation({
    mutationFn: async (userId: string) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/members/{userId}/actions/accept',
          {
            params: { path: { clubId, userId } },
          },
        )
      ).data!;
    },
  });
}

export function useRejectClubMemberMutation(clubId: string) {
  return useMutation({
    mutationFn: async (userId: string) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/members/{userId}/actions/reject',
          {
            params: { path: { clubId, userId } },
          },
        )
      ).data!;
    },
  });
}

export function useInviteClubMemberMutation(clubId: string) {
  return useMutation({
    mutationFn: async (userId: string) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/members/{userId}/actions/invite',
          {
            params: { path: { clubId, userId } },
          },
        )
      ).data!;
    },
  });
}

export function useDeleteClubMemberMutation(clubId: string) {
  return useMutation({
    mutationFn: async (userId: string) => {
      return (
        await POST(
          '/api/v1/manager/clubs/{clubId}/members/{userId}/actions/delete',
          {
            params: {
              path: { clubId, userId },
            },
          },
        )
      ).data!;
    },
  });
}
