import { useEffect, useRef } from 'react';

export function useIsRendered() {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
  }, []);

  return mounted;
}
