import {
  BoardPositions,
  FileName,
  PieceCode,
  RankName,
  SquareName,
} from './types';

export type FenObject = {
  fen: string;
  board: BoardPositions;
  color: 'white' | 'black';
  enpassant: SquareName;
  castling: string;
  halfMoves: number;
  fullMoves: number;
};

export function parseFen(fen?: string): FenObject | null {
  if (!fen) return null;

  // 1. board
  // 2. color
  // 3. castling
  // 4. en passant
  // 5. half moves counter
  // 6. full moves counter
  //                                                  2.     4.  6.
  //                                                  ↓      ↓   ↓
  // nbqkbnr/pp1ppppp/8/2p5/4P3/5N2/PPPP1PPP/RNBQKB1R b KQkq - 1 2
  // ↑______________________________________________↑   ↑__↑   ↑
  //                          1.                         3.    5.

  const [board, color, castling, enpassant, halfmoves, fullmoves] =
    fen.split(' ');

  if (!board || !color || !castling || !enpassant || !halfmoves || !fullmoves)
    return null;

  const rows = board.split('/');

  return {
    fen,
    board: parsePositions(rows),
    color: color === 'w' ? 'white' : 'black',
    enpassant: enpassant as SquareName,
    castling,
    halfMoves: parseInt(halfmoves, 10),
    fullMoves: parseInt(fullmoves, 10),
  };
}

function parsePositions(rows: string[]): BoardPositions {
  const positions: BoardPositions = {};

  // ['nbqkbnr', 'pp1ppppp', '8', '2p5', '4P3', '5N2', 'PPPP1PPP', 'RNBQKB1R']
  // - ряды доски, от верхнего (8) к нижнему (1)
  // - маленькие буквы - чёрные фигуры
  // - большие буквы — белые фигуры
  // - цифры — пропуски (пустые клетки)

  rows.forEach((row, i) => {
    const rank = (8 - i).toString() as RankName;

    let col = 0;

    [...row].forEach((char) => {
      if ('12345678'.includes(char)) {
        const skipCols = parseInt(char, 10);
        col = col + skipCols;
      } else {
        const file = 'abcdefgh'[col] as FileName;
        const square: SquareName = `${file}${rank}`;
        positions[square] = (
          char.toLowerCase() === char ? `b${char.toUpperCase()}` : `w${char}`
        ) as PieceCode;
        col = col + 1;
      }
    });
  });

  return positions;
}
