import { useRef, useState, useEffect } from 'react';

export function useCountdownUntill(dateMs: number, pauseUpdates = false) {
  const remainingMs = Math.max(0, dateMs - Date.now());
  const seconds = Math.ceil(remainingMs / 1000);

  const state = { dateMs, seconds };
  const refState = useRef(state);
  refState.current = state;

  const [, update] = useState([]);

  useEffect(() => {
    const calc: FrameRequestCallback = () => {
      if (pauseUpdates) return;

      const { seconds } = refState.current;

      const nextRemaining = Math.max(0, dateMs - Date.now());
      const nextSec = Math.ceil(nextRemaining / 1000);

      if (nextSec) {
        raf = requestAnimationFrame(calc);
      }

      if (nextSec !== seconds) {
        update([]);
      }
    };

    let raf = requestAnimationFrame(calc);

    return () => cancelAnimationFrame(raf);
  }, [pauseUpdates, dateMs]);

  return { seconds };
}
