import { addHours } from 'date-fns/addHours';
import {
  ClubGroup,
  GameMovementRow,
  MemberDto,
  MemberTournamentParticipantDto,
  ParticipantDto,
  PositionAdditionalScore,
  PositionData,
  PositionGame,
  PositionParticipant,
  PositionRound,
  Tournament,
} from '@features/shared/api/typings';

export const participantGroups: ClubGroup[] = [
  {
    id: 1,
    title: 'Юность Москвы',
    participantIds: [1, 2, 3],
  },
  {
    id: 2,
    title: 'Старость Омской Области',
    participantIds: [4, 5, 6],
  },
  {
    id: 3,
    title: 'Воронежский Кризис Среднего Возраста',
    participantIds: [7, 8, 9],
  },
];

export const members: MemberDto[] = [
  {
    userId: '1',
    firstName: 'Константин',
    lastName: 'Константинопольский',
    title: 'IG',
    email: 'kostya@test.ru',
    rcfId: '314159265',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 0,
    gamesCount: 0,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 2412,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1532,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 1111,
      },
    },
  },
  {
    userId: '2',
    firstName: 'Иван',
    lastName: 'Петров',
    title: 'GM',
    rcfId: '123',
    email: 'ivan@test.ru',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 12,
    gamesCount: 9999,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 1996,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1840,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 2000,
      },
    },
  },
  {
    userId: '3',
    firstName: 'Магнус',
    lastName: 'Степанович Курский',
    title: 'FM',
    email: 'magnus@test.ru',
    rcfId: '92653589',
    status: 'Active',
    joinTimestampMs: 123123,
    tournamentsCount: 9999,
    gamesCount: 9999,
    elo: {
      blitz: {
        clockControl: 'Blitz',
        gamesCount: 15,
        rating: 2412,
        lastChange: 123,
      },
      rapid: {
        clockControl: 'Rapid',
        gamesCount: 15,
        lastChange: 123,
        rating: 1622,
      },
      classic: {
        clockControl: 'Classic',
        gamesCount: 15,
        lastChange: 123,
        rating: 3000,
      },
    },
  },
];

export const gameParticipants: ParticipantDto[] = members
  .filter((p) => p.userId !== '2')
  .map((p) => ({
    ...p,
    tournamentsCount: 23,
    games: 3213,
    status: 'Participating',
    teamId: null,
    numberInTeam: null,
    registrationTimestampMs: null,
  }));

export const memberTournamentParticipants: MemberTournamentParticipantDto[] =
  members.map((p) => ({
    ...p,
    participationStatus: 'Invited',
  }));

export const gameEvents: Tournament[] = [
  {
    clubId: '200',
    tournamentId: '1',
    title: 'Отборочный турнир отдела закупок',
    status: 'InProgress',
    system: 'Swiss',
    swissSettings: {
      roundsCount: 2,
    },
    logoUrl:
      'https://upload.wikimedia.org/wikipedia/ru/2/2d/Gazprom-Logo-rus.svg',
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettings: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    armageddonEnabled: false,
    rulesUrl: '',
    contacts: [
      {
        name: 'Судья',
        label: 'Константин Константинопольский',
        uri: 'http://tg.me/kost',
      },
    ],
    startTimestampMs: addHours(Date.now(), -1).getTime(),
    rounds: [
      {
        roundId: '1',
        roundNumber: 1,
        cycleNumber: 1,
        status: 'Finished',
        startTimestampMs: new Date().getTime(),
      },
      {
        roundId: '2',
        roundNumber: 2,
        cycleNumber: 1,
        status: 'Drafted',
        startTimestampMs: new Date().getTime() + 120 * 1000,
      },
      {
        roundId: '3',
        roundNumber: 3,
        cycleNumber: 1,
        status: 'New',
        startTimestampMs: new Date().getTime() + 120 * 1000,
      },
    ],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '2',
    title: 'Отборочный турнир отдела закупок',
    status: 'Paused',
    system: 'Swiss',
    swissSettings: {
      roundsCount: 2,
    },
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettings: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    armageddonEnabled: false,
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -1).getTime(),
    pausedTillTimestampMs: addHours(Date.now(), 1).getTime(),
    rounds: [
      {
        roundId: '1',
        roundNumber: 1,
        cycleNumber: 1,
        status: 'InProgress',
        startTimestampMs: new Date().getTime(),
      },
    ],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '3',
    title: 'Отборочный турнир отдела закупок. Второй тур.',
    status: 'Finished',
    system: 'Knockout',
    knockoutSettings: {
      gamesPerRound: 2,
      gamesPerFinalRound: 2,
      additionalGamesPerDraw: 2,
      additionalGamesPerFinalDraw: 2,
    },
    entrance: 'Invite',
    rcfRankCalculated: true,
    promoted: false,
    mode: 'PrivateTeam',
    clockControl: 'Rapid',
    clockControlSettings: {
      initialSeconds: 5,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: ['Berger', 'PartBerger'],
    armageddonEnabled: false,
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -10).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '4',
    title: 'Отборочный турнир отдела закупок. Третий тур.',
    status: 'Finished',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 2,
    },
    entrance: 'Rank',
    rcfRankCalculated: true,
    promoted: true,
    mode: 'Team',
    clockControl: 'Classic',
    clockControlSettings: {
      initialSeconds: 60,
      addSecondsPerMove: 60,
    },
    additionalScoreOrder: [],
    armageddonEnabled: true,
    armageddonBlackLimitMinutes: 4,
    armageddonWhiteLimitMinutes: 4,
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), -100).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '5',
    title: 'Новый турнир',
    status: 'Draft',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 4,
    },
    entrance: 'Invite',
    rcfRankCalculated: false,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettings: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    armageddonEnabled: false,
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    startTimestampMs: addHours(Date.now(), 2).getTime(),
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
  {
    clubId: '200',
    tournamentId: '6',
    title: 'Новый турнир',
    status: 'Draft',
    system: 'RoundRobin',
    roundRobinSettings: {
      roundsCount: 4,
    },
    entrance: 'Invite',
    rcfRankCalculated: false,
    promoted: false,
    mode: 'Private',
    clockControl: 'Blitz',
    clockControlSettings: {
      initialSeconds: 2,
      addSecondsPerMove: 3,
    },
    additionalScoreOrder: [],
    rulesUrl: '',
    contacts: [],
    armageddonEnabled: false,
    rounds: [],
    participantsCount: 99,
    delayBetweenRoundsSeconds: 180,
  },
];

export const clubs = [
  {
    clubId: '1',
    title: 'Шахматный клуб ОАО «Газпром»',
    description:
      'Armageddon Championship Series: Women’s Week — это&nbsp;захватывающее ежегодное событие для любителей шахмат посвященное выдающимся женщинам в мире шахмат. Мероприятие проходит в живописной Испании и приглашает самых ярких и талантливых шахматисток со всего мира на&nbsp;трехдневные состязания',
    logo: 'https://upload.wikimedia.org/wikipedia/ru/2/2d/Gazprom-Logo-rus.svg',
    participantsCount: 3141,
    tournaments: '5 · 92',
    createdAt: '2023-03-02',
    admin: 'Константин Константинопольский',
    tg: '@gazchess',
    events: gameEvents,
    participants: members,
    memberStatus: 'Active' as const,
    createdTimestampMs: addHours(Date.now(), -10).getTime(),
  },
];

export const positionAdditionalScores: PositionAdditionalScore[] = [
  {
    scoreType: 'Berger',
    score: 4,
  },
  {
    scoreType: 'PartBerger',
    score: 6.5,
  },
];
export const positionGames: PositionGame[] = [
  {
    // score: ,
    resultType: 'Skipped',
  },
  {
    score: 1,
    resultType: 'Win',
    side: 'Black',
  },
  {
    score: 0,
    resultType: 'Loss',
    side: 'Black',
  },
  {
    // score: 'С',
    resultType: 'InProgress',
    side: 'Both',
  },
  {
    score: 0.5,
    resultType: 'Draw',
    side: 'White',
  },
  {
    //score: '',
    resultType: 'UnPlayed',
  },
  {
    score: 1,
    resultType: 'Loss',
    side: 'White',
  },
];

export const positionParticipantsPeople: PositionParticipant[] = [
  {
    no: 1,
    title: 'Константин Константинопольский',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 20,
    place: '2',
  },
  {
    no: 2,
    title: 'Александр Александрийский',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 24.5,
    place: '1',
  },
  {
    no: 3,
    title: 'Петр Петрович',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 10,
    place: '4',
  },
  {
    no: 4,
    title: 'Сергей Сергеин',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 0,
    place: '7',
  },
  {
    no: 5,
    title: 'Пятачок',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 1,
    place: '6',
  },
  {
    no: 6,
    title: 'Винни-пух',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 5,
    place: '5',
  },
  {
    no: 7,
    title: 'Леонид Аркадиевич',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 15,
    place: '3',
  },
];

export const positionRounds: PositionRound[] = [
  {
    num: 1,
    members: positionParticipantsPeople,
    gamesCount: 7,
    additionalScoreTypes: ['Berger', 'PartBerger'],
  },
  {
    num: 2,
    members: positionParticipantsPeople,
    gamesCount: 7,
    additionalScoreTypes: ['Berger', 'PartBerger'],
  },
];

export const positionParticipantsGroups: PositionParticipant[] = [
  {
    no: 1,
    title: 'Юность Москвы',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 20,
    place: '2',
    subRows: positionParticipantsPeople,
  },
  {
    no: 2,
    title: 'Дряхлость Омской Области',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 24.5,
    place: '1',
    subRows: positionParticipantsPeople,
  },
  {
    no: 3,
    title: 'Воронежский Кризис Среднего Возраста',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 10,
    place: '4',
    subRows: positionParticipantsPeople,
  },
  {
    no: 4,
    title: 'Младенчество Калининград',
    games: positionGames,
    additionalScores: positionAdditionalScores,
    totalScore: 12,
    place: '3',
    subRows: positionParticipantsPeople,
  },
];

export const groupedPositionRounds: PositionRound[] = [
  {
    num: 1,
    members: positionParticipantsGroups,
    gamesCount: 7,
    additionalScoreTypes: ['PartBerger', 'Black'],
  },
];

export const positionData: PositionData = {
  rounds: positionRounds,
};

export const positionData2: PositionData = {
  rounds: [...positionRounds, positionRounds[0]!, positionRounds[1]!],
};

export const positionData3: PositionData = {
  rounds: groupedPositionRounds,
};

export const roundMovementRows: GameMovementRow[] = [
  {
    boardNo: 1,
    player1: {
      firstName: 'Константин',
      lastName: 'Константинопольский',
      title: 'GM',
      userId: '1',
      game: {
        gameId: '1',
        score: 1,
        side: 'White',
        resultType: 'Win',
      },
    },
    player2: {
      firstName: 'Александр',
      lastName: 'Александрийский',
      title: 'IG',
      userId: '2',
      game: {
        gameId: '1',
        score: 0,
        side: 'Black',
        resultType: 'Loss',
      },
    },
  },
  {
    boardNo: 2,
    player1: {
      firstName: 'Винни',
      lastName: 'Пу',
      title: 'Noob',
      userId: '3',
      game: {
        gameId: '2',
        score: 0.5,
        side: 'Both',
        resultType: 'Draw',
      },
    },
    player2: {
      firstName: 'Пятачок',
      lastName: 'Просто свинья',
      title: 'Хрюн',
      userId: '4',
      game: {
        gameId: '2',
        score: 0.5,
        side: 'Both',
        resultType: 'Draw',
      },
    },
  },
];
