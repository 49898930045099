import { ChangeEvent, ReactNode, useState } from 'react';
import { cx } from '@libs/classnames';
import { ThemeButton } from '@ui/components/ThemeButton';
import { ThemeLink } from '@ui/components/ThemeLink';
import styles from './FileUploadInput.module.css';

export type Props = {
  className?: string;
  description: ReactNode;
  value?: string | null;
  onChange: (file: FormData) => Promise<void>;
  disabled?: boolean;
};

export function FileUploadInput({
  className,
  description,
  value,
  onChange,
  disabled,
}: Props) {
  const [status, setStatus] = useState<
    'initial' | 'uploading' | 'success' | 'fail'
  >('initial');
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) return;
    setStatus('initial');
    handleUpload(e.target.files[0] as File);
  };

  const handleUpload = async (file: File) => {
    if (!file) return;
    setError(null);
    setStatus('uploading');

    const formData = new FormData();
    formData.append('file', file);

    try {
      await onChange(formData);
      setStatus('success');
    } catch (error: unknown) {
      console.error(error);
      setError((error as Error).message);
      setStatus('fail');
    }
  };

  let result;
  if (status === 'success') result = 'Файл загружен';
  if (status === 'fail')
    result = (
      <>
        Не удалось загрузить файл.
        <br />
        <span className={styles.error}>{error}</span>
      </>
    );
  if (status === 'uploading') result = 'Загрузка...';

  return (
    <div
      className={cx(className, styles.root, { [styles.disabled]: disabled })}
    >
      <label className={styles.label}>
        <ThemeButton onClick={() => {}}>Выберите файл</ThemeButton>
        <input
          className={styles.input}
          id="file"
          type="file"
          onChange={handleFileChange}
          disabled={disabled}
        />
      </label>

      <span className={styles.description}>
        <span>
          {value ? (
            <ThemeLink inNewTab href={value}>
              Скачать
            </ThemeLink>
          ) : (
            description
          )}
        </span>
        <span>{result}</span>
      </span>
    </div>
  );
}
