import { Card } from '@ui/components/Card';
import styles from './LoginForm.module.css';
import { PasswordForm } from './PasswordForm';

export type NameFormData = {
  firstName: string;
  lastName: string;
  rcfId?: number;
};

export type LoginFormData = {
  login: string;
  password: string;
};

export type Props = {
  loginError?: boolean;
  initialLogin?: string;
  rulesUrl: string;
  loading?: boolean;
  onSubmit: (loginData: LoginFormData) => void;
  onChessOpenClick?: () => void;
};
export function LoginForm({
  initialLogin,
  rulesUrl,
  loginError,
  loading,
  onSubmit,
  onChessOpenClick,
}: Props) {
  return (
    <Card className={styles.root}>
      <div className={styles.title}>Вход</div>

      <PasswordForm
        key="passwordForm"
        initialLogin={initialLogin}
        onSubmit={onSubmit}
        rulesUrl={rulesUrl}
        error={loginError}
        loading={loading}
        onChessOpenClick={onChessOpenClick}
      />
    </Card>
  );
}
