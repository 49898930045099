import { useFormContext, useWatch } from 'react-hook-form';
import { ClockControlInput } from '@features/tournament/ui/components/ClockControlInput/ClockControlInput';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

export function TournamentClockControlInput() {
  const {
    control,
    setValue,
    formState: { disabled },
  } = useFormContext<TournamentEditFormValues>();

  const clockControl = useWatch({ name: 'clockControl', control });
  const clockControlSettings = useWatch({
    name: 'clockControlSettings',
    control,
  });

  return (
    <ClockControlInput
      label="Контроль времени"
      perPlayer={false}
      value={{
        clockControl,
        clockControlSettings,
      }}
      onChange={(newValue) => {
        setValue('clockControl', newValue.clockControl);
        setValue('clockControlSettings', newValue.clockControlSettings);
      }}
      disabled={disabled}
    />
  );
}
