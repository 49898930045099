import React from 'react';

export type Theme = 'light' | 'dark';

export interface IThemeContext {
  theme: Theme;
  selectTheme: (t: Theme) => void;
}

export const ThemeContext = React.createContext<IThemeContext | undefined>(
  undefined,
);
