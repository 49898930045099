import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './PageSubTitle.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
};
export function PageSubTitle({ className, onClick, children }: Props) {
  return (
    <span onClick={onClick} className={cx(className, styles.subTitle)}>
      {children}
    </span>
  );
}
