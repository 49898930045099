import { cx } from '@libs/classnames';
import { ColorSquare } from '@ui/components/ColorSquare';
import { FileUploadInput } from '@ui/components/FileUploadInput';
import { Form } from '@ui/components/Form';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';
import styles from './ClubInfoForm.module.css';

export type Props = {
  className?: string;
  club: {
    title: string;
    description: string;
    telegramChannel: string;
    themeColor: string | null;
  };
};

export function ClubInfoForm({ className, club }: Props) {
  return (
    <Form className={cx(className, styles.root)}>
      <FormRow>
        <FormLabel>Название</FormLabel>
        <FormInput>
          <Input value={club.title} onChange={() => {}} disabled />
        </FormInput>
      </FormRow>
      <FormRow>
        <FormLabel>Описание</FormLabel>
        <FormInput>
          <Input
            className={styles.description}
            type="textarea"
            value={club.description}
            onChange={() => {}}
            disabled
          />
        </FormInput>
      </FormRow>
      <FormRow>
        <FormLabel>Телеграм-канал</FormLabel>
        <FormInput>
          <Input
            leftLabel="t.me/"
            value={club.telegramChannel}
            onChange={() => {}}
            disabled
          />
        </FormInput>
      </FormRow>
      <FormRow>
        <FormLabel>Логотип</FormLabel>
        <FormInput>
          <FileUploadInput
            description="SVG, PNG, до 2МБ."
            onChange={() => Promise.resolve()}
            disabled
          />
        </FormInput>
      </FormRow>
      <FormRow>
        {/* FIXME: поддержать null */}
        <FormLabel>Цвет страниц</FormLabel>
        <FormInput>
          <Input
            leftLabel={
              <>
                <ColorSquare color={club.themeColor ?? ''} />
              </>
            }
            value={club.themeColor ?? ''}
            onChange={() => {}}
            disabled
          />
        </FormInput>
      </FormRow>
    </Form>
  );
}
