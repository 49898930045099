export function ArrowSlimIcon() {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67188 14L0.671875 7L7.67188 2.38419e-07L8.875 1.1875L3.92188 6.14062H16.75V7.85938H3.92188L8.875 12.7969L7.67188 14Z"
        fill="currentColor"
      />
    </svg>
  );
}
