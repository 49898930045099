import { useEffect, useState } from 'react';
import { GameMovementRow } from '@features/shared/api/typings';
import { useTournamentResultsGamesQuery } from '@features/tournament/api/hooks';
import { RoundMovement } from '@features/tournament/ui/components/RoundMovement/RoundMovement';

type Props = {
  clubId: string;
  tournamentId: string;
  roundNumber: number;
};

export function RoundMovementWidget({
  clubId,
  tournamentId,
  roundNumber,
}: Props) {
  const [rows, setRows] = useState<GameMovementRow[]>([]);
  const { data, isPending } = useTournamentResultsGamesQuery(
    clubId,
    tournamentId,
    roundNumber,
  );

  useEffect(() => {
    if (!isPending) setRows(data?.rows || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, roundNumber]);

  return <RoundMovement isPending={isPending} rows={rows} />;
}
