import { useRef } from 'react';

// Как useEffect, но выполняется синзронно (а не после рендера)
export function useImmediateEffect(fn: () => void, deps: unknown[]) {
  const depsRef = useRef<unknown[]>();
  if (!depsRef.current || !dependencyEqual(deps, depsRef.current)) fn();
  depsRef.current = deps;
}

function dependencyEqual(arrA: unknown[], arrB: unknown[]): boolean {
  if (arrA === arrB) return true;
  if (arrA.length !== arrB.length) return false;
  for (let i = 0; i < arrA.length; i++) {
    if (arrA[i] !== arrB[i]) return false;
  }
  return true;
}
