import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './PageTitle.module.css';

export type Props = {
  className?: string;
  children: ReactNode;
};
export function PageTitle({ className, children }: Props) {
  return <span className={cx(className, styles.title)}>{children}</span>;
}
