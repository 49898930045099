import { useEffect, useRef } from 'react';
import { SocketInternalEvents } from '@libs/socket';
import {
  TournamentGamesSocketEvents,
  TournamentPublicStreamSocket,
} from './clients';
import { components } from './generated';

/*
    ____,-------------------------------,____
    \   |            Sockets            |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Tournament Round Games

const globalRoundWs: Record<string, TournamentGamesSotre> = {};

export function useTournamentGamesSocket({
  tournamentId,
  roundNumber,
  cb,
  enabled = true,
}: {
  tournamentId: string;
  roundNumber: number;
  cb: (e: TournamentGamesSocketEvents | SocketInternalEvents) => void;
  enabled?: boolean;
}) {
  const ref = useRef(cb);
  ref.current = cb;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const key = `${tournamentId}-${roundNumber}`;

    if (!globalRoundWs[key]) {
      globalRoundWs[key] = new TournamentGamesSotre(
        new TournamentPublicStreamSocket(tournamentId, roundNumber),
      );
    }

    const store = globalRoundWs[key];
    const unsubscribe = store.ws.subscribe((e) => ref.current(e));

    // отправляем снэпшот
    const snapshot = store.snapshot;
    if (snapshot) ref.current(snapshot);

    return () => {
      unsubscribe();
      if (store.subscribers === 0) {
        store.discard();
        delete globalRoundWs[key];
      }
    };
  }, [tournamentId, roundNumber, enabled]);
}

// Стор нужен, чтобы хранить и обновлять snapshot
class TournamentGamesSotre {
  #games: Record<string, components['schemas']['GameDto']> | undefined;
  ws: TournamentPublicStreamSocket;

  constructor(ws: TournamentPublicStreamSocket) {
    this.ws = ws;
    ws.subscribe((e) => {
      switch (e.eventType) {
        case 'RoundGamesState':
          this.#games = {};
          e.data.forEach((g) => {
            this.#games![g.id] = g;
          });
          break;
        case 'GameChanged':
          if (this.#games) {
            this.#games[e.gameId] = e.currentState;
          }
          break;
      }
    });
  }

  get snapshot(): components['schemas']['RoundGamesState'] | undefined {
    if (!this.#games) return;

    return {
      timestampMs: Date.now(),
      eventType: 'RoundGamesState',
      data: Object.values(this.#games),
    };
  }

  get subscribers(): number {
    return Math.max(this.ws.subscribers - 1, 0);
  }

  discard() {
    this.ws.discard();
  }
}
