import { FormEvent, useCallback, useState } from 'react';
import { Input } from '@ui/components/Input';
import { ThemeButton } from '@ui/components/ThemeButton';
import styles from './TeamAddForm.module.css';

export type Props = {
  totalTeamsCount: number;
  onSubmit: (newTeamName: string) => void;
  onCancel: () => void;
  disabled?: boolean;
};

export function TeamAddForm({
  totalTeamsCount,
  onSubmit,
  onCancel,
  disabled,
}: Props) {
  const [newTeamName, setNewTeamName] = useState('');
  const handleSubmit = useCallback(
    (e?: FormEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      onSubmit(newTeamName);
    },
    [onSubmit, newTeamName],
  );

  return (
    <form onSubmitCapture={handleSubmit}>
      <div className={styles.teamFormContainer}>
        <span className={styles.teamName}>Команда {totalTeamsCount + 1}</span>
        <div className={styles.teamForm}>
          <Input
            autoFocus
            className={styles.teamNameInput}
            onChange={setNewTeamName}
            value={newTeamName}
          />
          <ThemeButton
            className={styles.button}
            onClick={handleSubmit}
            disabled={disabled}
          >
            Добавить
          </ThemeButton>
          <ThemeButton
            className={styles.button}
            onClick={onCancel}
            disabled={disabled}
          >
            Отмена
          </ThemeButton>
        </div>
      </div>
    </form>
  );
}
