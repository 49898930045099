import { Button } from '@ui/components/Button';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';

export type Props = {
  className?: string;
  roundNumber: number;
  onClose?: () => void;
};

export function NoPairModal({ className, roundNumber, onClose }: Props) {
  return (
    <GoldenModal className={className}>
      <h1>Стартовал тур {roundNumber}</h1>
      <p>
        В этом туре вы свободны от игры, потому что в турнире нечетное
        количество участников.
      </p>
      <Button onClick={onClose}>OK</Button>
    </GoldenModal>
  );
}
