import { ReactNode } from 'react';
import styles from './InfoItem.module.css';

export type Props = {
  label?: ReactNode;
  value: ReactNode;
};
export function InfoItem({ label, value }: Props) {
  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
