import { TournamentLayout } from '@features/shared/ui/TournamentLayout/TournamentLayout';
import styles from './NotFoundPage.module.css';

export function NotFoundPage() {
  return (
    <TournamentLayout>
      <div className={styles.content}>
        <div className={styles.header}>404</div>
        <div className={styles.subTitle}>Страница не найдена</div>
      </div>
    </TournamentLayout>
  );
}
