/*
    ____,-------------------------------,____
    \   |           Converters          |   /
    /___|-------------------------------|___\
*/

/**
 * hexToRgb('#0079C2'): [0, 121, 194]
 *
 * @param hex #RRGGBB / RRGGBB
 * @returns [0-255, 0-255, 0-255]
 */
export function hexToRgb(hex: string): [number, number, number] {
  const [r, g, b] = hex.match(/[^#]{1,2}/g) as [string, string, string];
  return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
}

/**
 * rgbToHex(0, 121, 194): '#0079C2'
 *
 * @param r 0-255
 * @param g 0-255
 * @param b 0-255
 * @returns #RRGGBB
 */
export function rgbToHex(r: number, g: number, b: number): string {
  return `#${numToHex(r)}${numToHex(g)}${numToHex(b)}`;
}

/**
 * @param r 0-255
 * @param g 0-255
 * @param b 0-255
 * @returns [0-360, 0-100, 0-100]
 */
export function rgbToHsv(
  r: number,
  g: number,
  b: number,
): [number, number, number] {
  const absR = r / 255;
  const absG = g / 255;
  const absB = b / 255;
  const v = Math.max(absR, absG, absB);
  const c = v - Math.min(absR, absG, absB);

  const h =
    c &&
    (v === absR
      ? (absG - absB) / c
      : v === absG
        ? 2 + (absB - absR) / c
        : 4 + (absR - absG) / c);

  return [
    Math.round(60 * (h < 0 ? h + 6 : h)),
    Math.round((v && c / v) * 100),
    Math.round(v * 100),
  ];
}

/**
 * @param h 0-360
 * @param s 0-100
 * @param v 0-100
 * @returns [0-255, 0-255, 0-255]
 */
export function hsvToRgb(
  h: number,
  s: number,
  v: number,
): [number, number, number] {
  const absS = s / 100;
  const absV = v / 100;

  const f = (n: number, k = (n + h / 60) % 6) =>
    Math.round((absV - absV * absS * Math.max(Math.min(k, 4 - k, 1), 0)) * 255);

  return [f(5), f(3), f(1)];
}

/**
 * @param hex #RRGGBB / RRGGBB
 * @returns [0-360, 0-100, 0-100]
 */
export function hexToHsv(hex: string): [number, number, number] {
  return rgbToHsv(...hexToRgb(hex));
}

/**
 *
 * @param h 0-360
 * @param s 0-100
 * @param v 0-100
 * @returns #RRGGBB
 */
export function hsvToHex(h: number, s: number, v: number): string {
  return rgbToHex(...hsvToRgb(h, s, v));
}

/**
 * nameToHex('purple'): '#800080'
 *
 * @param name Web color name
 * @returns #RRGGBB
 */
export function nameToHex(name: string): string {
  const c = document.createElement('canvas').getContext('2d')!;
  c.fillStyle = name;
  return c.fillStyle;
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function numToHex(num: number) {
  return num.toString(16).padStart(2, '0');
}
