import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Badge.module.css';

export type Props = {
  size?: 'm' | 'l' | 'h25';
  className?: string;
  children: ReactNode;
};
export function Badge({ className, children, size = 'm' }: Props) {
  return (
    <span className={cx(className, styles.badge, styles[size])}>
      {children}
    </span>
  );
}
