import { useCallback, useRef } from 'react';
import { z } from 'zod';
import { TournamentLayoutWidget } from '@features/shared/ui/TournamentLayout/TournamentLayoutWidget';
import { TournamentCardPage } from '@features/tournament/pages/TournamentCardPage';
import { useTournamentCard } from '@features/tournament/pages/TournamentCardPage/useTournamentCard';
import { NoPairModalWidget } from '@features/tournament/ui/components/NoPairModal/NoPairModalWidget';
import { PositionWidget } from '@features/tournament/ui/components/Position';
import { RoundMovementWidget } from '@features/tournament/ui/components/RoundMovement/RoundMovementWidget';
import { TournamentGamesWidget } from '@features/tournament/ui/components/TournamentGames';
import { TournamentParticipantsWidget } from '@features/tournament/ui/components/TournamentParticipants';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

const querySchema = z.object({
  resultsTab: z.enum(['table', 'rounds']).catch('table').optional(),
});
type Query = z.infer<typeof querySchema>;

export const Route = createFileRoute(
  '/_authenticated/club/$clubId_/tournament/$tournamentId',
)({
  component: Page,
  validateSearch: querySchema,
});

function Page() {
  const { clubId, tournamentId } = Route.useParams();
  const { resultsTab = 'table' } = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const onResultsTabChange = useCallback(
    (resultsTab: Query['resultsTab']) => {
      navigate({
        search: (prev) => ({
          ...prev,
          resultsTab,
        }),
        ignoreBlocker: true,
      });
    },
    [navigate],
  );

  const refParticipants = useRef(null);
  const props = useTournamentCard({
    clubId,
    tournamentId,
    refParticipants,
  });
  return (
    <TournamentLayoutWidget clubId={clubId}>
      <TournamentCardPage
        {...props}
        resultsTab={resultsTab}
        onResultsTabChange={onResultsTabChange}
        participants={(className) => (
          <TournamentParticipantsWidget
            className={className}
            ref={refParticipants}
            clubId={clubId}
            tournamentId={tournamentId}
          />
        )}
        games={
          <TournamentGamesWidget clubId={clubId} tournamentId={tournamentId} />
        }
        noPairModal={<NoPairModalWidget tournamentId={tournamentId} />}
        position={
          <PositionWidget clubId={clubId} tournamentId={tournamentId} />
        }
        roundMovement={(roundNumber) => (
          <RoundMovementWidget
            clubId={clubId}
            tournamentId={tournamentId}
            roundNumber={roundNumber}
          />
        )}
      />
    </TournamentLayoutWidget>
  );
}
