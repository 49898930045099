import { useCallback, useState } from 'react';
import { ParticipantStatus } from '@features/shared/api/typings';
import {
  useAddToTournamentMutation,
  useClubMembersForTournamentModalQuery,
  useInviteToTournamentMutation,
  useRemoveFromTournamentMutation,
} from '@features/tournament/api/hooks';
import { ParticipantsModalProps } from '@features/tournament/ui/components/ParticipantsModal/index';

type Sort = 'Alphabet' | 'RatingBlitz' | 'RatingRapid' | 'RatingClassic';

type ParticipantsModalParams = {
  clubId: string;
  tournamentId: string;
};

export function useParticipantsModal({
  clubId,
  tournamentId,
}: ParticipantsModalParams): Omit<
  ParticipantsModalProps,
  'onDone' | 'clockControl' | 'allowAdd' | 'allowInvite'
> {
  const [sort, setSort] = useState<Sort>('Alphabet');
  const [search, setSearch] = useState('');

  const { mutateAsync: add } = useAddToTournamentMutation(clubId, tournamentId);
  const { mutateAsync: invite } = useInviteToTournamentMutation(
    clubId,
    tournamentId,
  );
  const { mutateAsync: remove } = useRemoveFromTournamentMutation(
    clubId,
    tournamentId,
  );

  const {
    data: members,
    hasNextPage,
    fetchNextPage,
  } = useClubMembersForTournamentModalQuery(clubId, tournamentId, {
    sortBy: sort,
    status: 'Active',
    query: search,
  });

  const onInvite = useCallback(
    async (p: { userId: string; participationStatus: ParticipantStatus }) => {
      const result = await invite({ userId: p.userId });
      // FIXME: патчить чужой стейт
      if (result!) p.participationStatus = 'Invited';
    },
    [invite],
  );

  const onAdd = useCallback(
    async (p: { userId: string; participationStatus: ParticipantStatus }) => {
      const result = await add({ userId: p.userId });
      // FIXME: патчить чужой стейт
      if (result!) p.participationStatus = 'Participating';
    },
    [add],
  );

  const onRemove = useCallback(
    async (p: { userId: string; participationStatus: ParticipantStatus }) => {
      await remove({ userId: p.userId });
      // FIXME: патчить чужой стейт
      // FIXME: попросить бэк возвращать юзера, как в других запросах
      p.participationStatus = 'Exited';
    },
    [remove],
  );

  return {
    sort,
    onSortChange: setSort,
    search,
    onSearchChange: setSearch,
    members,
    hasNextPage,
    fetchNextPage,
    onAdd,
    onInvite,
    onRemove,
  };
}
