import { FormEvent, useCallback, useState } from 'react';
import { Input } from '@ui/components/Input';
import { ThemeButton } from '@ui/components/ThemeButton';
import { ExternalAuthButton } from '../ExternalAuthButton/ExternalAuthButton';
import { LoginFormData } from './LoginForm';
import styles from './LoginForm.module.css';

export type Props = {
  initialLogin?: string;
  onSubmit: (loginData: LoginFormData) => void;
  onChessOpenClick?: () => void;
  error?: boolean;
  loading?: boolean;
  rulesUrl: string;
};
export function PasswordForm({
  initialLogin,
  onSubmit,
  onChessOpenClick,
  error,
  loading,
  rulesUrl,
}: Props) {
  const [loginData, setLoginData] = useState({
    login: initialLogin || '',
    password: '',
  } as LoginFormData);

  // упрощённая валидация вместо disabled кнопки (см. комментарий ниже про автозаполнение)
  const [invalid, setInvalid] = useState(false);

  const handleSubmit = useCallback(
    (evt?: FormEvent) => {
      if (evt) {
        evt.preventDefault();
        evt.stopPropagation();
      }

      if (!loginData.login || !loginData.password) {
        setInvalid(true);
        return;
      } else {
        setInvalid(false);
      }

      if (loading) return;

      onSubmit(loginData);
    },
    [loginData, loading, onSubmit],
  );

  const onLoginChange = useCallback(
    (login: string) => {
      setLoginData((state) => ({ ...state, login }));
    },
    [setLoginData],
  );
  const onPasswordChange = useCallback(
    (password: string) => {
      setLoginData((state) => ({ ...state, password }));
    },
    [setLoginData],
  );

  return (
    <form key="loginForm" onSubmit={handleSubmit}>
      <div className={styles.form}>
        <label>
          <span className={styles.label}>Логин</span>
          <Input
            name="login"
            placeholder="Эл. почта"
            autoFocus
            disabled={loading}
            error={error || (invalid && !loginData.login)}
            onChange={onLoginChange}
            value={loginData.login}
          />
        </label>
        <label>
          <span className={styles.label}>Пароль</span>
          <Input
            name="password"
            type="password"
            placeholder=""
            disabled={loading}
            error={error || (invalid && !loginData.password)}
            onChange={onPasswordChange}
            value={loginData.password}
          />
        </label>
        {/* ВАЖНО:
          Мы не можем дизейблить кнопку для пустых полей из-за нативного автозаполнения
          полей, которое не генерирует событие onChange по соображением безопасности до
          того как пользователь не проивзаимодействует со страницей
        */}
        <ThemeButton disabled={loading /* sic! */} onClick={handleSubmit}>
          Войти
        </ThemeButton>
        <ExternalAuthButton
          disabled={loading}
          provider="chessopen"
          onClick={onChessOpenClick}
        />
        <a
          href={rulesUrl}
          target={rulesUrl !== '#' ? '_blank' : undefined}
          rel="noreferrer"
          className={styles.agreement}
        >
          Пользовательское соглашение
        </a>
        <input type="submit" hidden />
      </div>
    </form>
  );
}
