import { createRoot } from 'react-dom/client';
import { initTracking } from 'tracking';
import { NotFoundPage } from '@features/auth/pages/NotFoundPage';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import './index.css';
import { routeTree } from './routeTree.gen';

// Трэкинг
if (__RUNTIME.isProduction) {
  initTracking();
}

export const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// Приложение
const doc = document.getElementById('root');
if (doc) {
  const root = createRoot(doc);
  root.render(
    <RouterProvider
      router={router}
      notFoundMode="root"
      defaultNotFoundComponent={NotFoundPage}
    />,
  );
}
