import { useLayoutEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(
  cb: (entry: ResizeObserverEntry) => void,
) {
  const ref = useRef<T>(null);
  const refCb = useRef(cb);
  refCb.current = cb;

  useLayoutEffect(() => {
    if (!ref.current) return;

    const el = ref.current;

    const observer = new ResizeObserver((entries) => {
      refCb.current(entries[0]!);
    });
    observer.observe(el);

    return () => {
      observer.unobserve(el);
      observer.disconnect();
    };
  }, []);

  return ref;
}
