import { ReactNode, useCallback } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { useClubCardQuery } from '@features/club/api/hooks';
import { useCreateTournamentMutation } from '@features/tournament/api/hooks';
import { UserWidget } from '@features/user/ui/User/UserWidget';
import { Link, useNavigate } from '@tanstack/react-router';
import { TournamentLayout } from './TournamentLayout';

/*
    ____,-------------------------------,____
    \   |            Виджет             |   /
    /___|-------------------------------|___\
*/

type Props = {
  children: ReactNode;
  clubId: string;
  hideCreateTournament?: boolean;
};

export function TournamentLayoutWidget({
  children,
  clubId,
  hideCreateTournament,
}: Props) {
  const { onTournamentCreate } = useTournamentCreate(clubId);
  const onHome = useOnHome(clubId);
  const { isManager } = useClubRoles(clubId);
  const clubQuery = useClubCardQuery(clubId, { staleTime: Infinity });

  return (
    <TournamentLayout
      title={
        <Link to="/club/$clubId" params={{ clubId }}>
          {clubQuery.data?.title}
        </Link>
      }
      isManager={isManager && !hideCreateTournament}
      onTournamentCreate={onTournamentCreate}
      themeColor={clubQuery.data?.themeColor}
      user={<UserWidget />}
      onHome={onHome}
    >
      {children}
    </TournamentLayout>
  );
}

/*
    ____,-------------------------------,____
    \   |            Запчасти           |   /
    /___|-------------------------------|___\
*/

function useOnHome(clubId: string) {
  const navigate = useNavigate();

  return useCallback(() => {
    navigate({
      to: '/club/$clubId',
      params: { clubId },
    });
  }, [navigate, clubId]);
}

function useTournamentCreate(clubId: string) {
  const navigate = useNavigate();
  const { mutateAsync: createTournament } = useCreateTournamentMutation();
  const onTournamentCreate = useCallback(async () => {
    if (!clubId) return;
    const result = await createTournament({ clubId });
    if (result?.tournamentId) {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/edit',
        params: { clubId, tournamentId: result.tournamentId },
      });
    }
  }, [navigate, clubId, createTournament]);

  return { onTournamentCreate };
}
