import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { TournamentSystem } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';
import { Tabs } from '@ui/components/Tabs';

const gameSystemTabs: {
  value: TournamentSystem;
  label: string;
  disabled?: boolean;
}[] = [
  { value: 'RoundRobin', label: 'Круговая' },
  { value: 'Swiss', label: 'Швейцарская', disabled: true },
  {
    value: 'Knockout',
    label: 'Нокаут (олимпийская)',
    disabled: true,
  },
];

const roundsCountTabs = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3', disabled: true },
  { value: 4, label: '4', disabled: true },
];

export function GameSystemInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  const system = useWatch({
    name: 'system',
    control,
  });

  return (
    <>
      <FormRow>
        <FormLabel>Система проведения</FormLabel>
        <FormInput>
          <Controller
            name="system"
            control={control}
            render={({ field: { value, onChange, disabled } }) => (
              <Tabs
                tabs={gameSystemTabs}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </FormInput>
      </FormRow>

      {system === 'RoundRobin' && (
        <FormRow>
          <FormLabel>Количество кругов</FormLabel>
          <FormInput>
            <Controller
              name="roundRobinSettings.roundsCount"
              control={control}
              render={({ field: { value, onChange, disabled } }) => (
                <Tabs
                  tabs={roundsCountTabs}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
          </FormInput>
        </FormRow>
      )}

      {system === 'Swiss' && (
        <FormRow>
          <FormLabel>Количество туров</FormLabel>
          <FormInput>
            <Controller
              name="swissSettings.roundsCount"
              control={control}
              render={({ field: { value, onChange, disabled } }) => (
                <Input
                  invisible
                  type="number"
                  value={value.toString()}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
          </FormInput>
        </FormRow>
      )}

      {/** TODO SUPPORT KNOCKOUT SYSTEM! **/}
    </>
  );
}
