import { ReactNode } from 'react';
import { GameParticipantsResult, GameSide } from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { RoundPhoenixIcon } from '@ui/icons/RoundPhoenixIcon';
import styles from './GameResultsBadge.module.css';

export type Props = {
  className?: string;
  result?: GameParticipantsResult;
  side?: GameSide;
  score?: number | null;
  dotCentered?: boolean;
  reversed?: boolean;
};
export function GameResultsBadge({
  className,
  result,
  side,
  score,
  reversed,
  dotCentered,
}: Props) {
  let scoreView: ReactNode = score?.toString();
  if (score === 0.5) scoreView = '½';
  if (score === 1.5) scoreView = '1½';
  if (result === 'InProgress') scoreView = 'С';
  if (result === 'Skipped')
    scoreView = (
      <div className={styles.icon}>
        <RoundPhoenixIcon />
      </div>
    );

  return (
    <div
      className={cx(
        styles.gameResultsBadge,
        result && styles[result],
        className,
        {
          [styles.reversed]: reversed,
        },
      )}
    >
      <span>{scoreView}</span>

      <span
        className={cx(styles.sideDot, side && styles[side], {
          [styles.centered]: dotCentered,
        })}
      />
    </div>
  );
}
