import { cx } from '@libs/classnames';
import { ReactNode } from '@tanstack/react-router';
import { RoundPhoenixIcon } from '@ui/icons/RoundPhoenixIcon';
import styles from './ExternalAuthButton.module.css';

type Props = {
  className?: string;
  provider: 'chessopen';
  disabled?: boolean;
  onClick?: () => void;
};

const PRESETS: Record<Props['provider'], { label: string; icon: ReactNode }> = {
  chessopen: {
    label: 'Войти через ChessOpen',
    icon: <RoundPhoenixIcon />,
  },
};

export function ExternalAuthButton({
  className,
  provider,
  disabled,
  onClick,
}: Props) {
  const { label, icon } = PRESETS[provider];
  return (
    <button
      type="button"
      className={cx(styles.authButton, className)}
      disabled={disabled}
      onClick={onClick}
    >
      <div>{label}</div>
      <div className={styles.icon}>{icon}</div>
    </button>
  );
}
