import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEntrance } from '@features/shared/api/typings';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Tabs } from '@ui/components/Tabs';

const entranceTabs: {
  value: TournamentEntrance;
  label: string;
  disabled?: boolean;
}[] = [
  { value: 'Open', label: 'Открытый', disabled: true },
  { value: 'Rank', label: 'По рейтингу', disabled: true },
  { value: 'Invite', label: 'По приглашению' },
];

export function EntranceInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Допуск</FormLabel>
      <FormInput>
        <Controller
          name="entrance"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Tabs
              tabs={entranceTabs}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
