import { useCallback } from 'react';
import { useUserSocket } from '@features/shared/api';
import { UpdateTournamentRequest } from '@features/shared/api/typings';
import {
  updateTournamentCardQuery,
  useDeleteTournamentMutation,
  useTournamentCardQuery,
  useTournamentUploadLogoMutation,
  useTournamentUploadRulesMutation,
  useUpdateTournamentMutation,
} from '@features/tournament/api/hooks';
import { useTournamentStates } from '@features/tournament/hooks/useTournamentStates';
import { TournamentEditPageProps } from '@features/tournament/pages/TournamentEditPage/index';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useErrorNotification } from '@ui/components/ErrorsNotificationProvider';

/*
    ____,-------------------------------,____
    \   |             Hook              |   /
    /___|-------------------------------|___\
*/

type PageTab = 'settings' | 'participants';

type TournamentEditParams = {
  clubId: string;
  tournamentId: string;
  pageTab: PageTab;
  onPageTabChange: (newTab: PageTab) => void;
};

export const useTournamentEdit = ({
  clubId,
  tournamentId,
  pageTab,
  onPageTabChange,
}: TournamentEditParams):
  | (TournamentEditPageProps & { refetchTournament: () => void })
  | null => {
  const { data: tournament, refetch } = useTournamentCardQuery(
    clubId,
    tournamentId,
  );

  // Отслеживание изменений турнира
  const ql = useQueryClient();
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentStartsSoon':
      case 'TournamentFinished':
        if (e.tournamentId === tournamentId) {
          updateTournamentCardQuery(ql, clubId, tournamentId, e.tournament);
        }
        break;
    }
  });

  const { onSave, onDelete, isDeleting, onUploadLogo, onUploadRules, onBack } =
    useActions(clubId, tournamentId, refetch);

  const { isEditable } = useTournamentStates(tournament ?? null);

  if (!tournament) return null;

  const disabled = !isEditable || isDeleting || !tournament;

  return {
    tournament,
    disabled,
    onBack,
    onDelete,
    onSave,
    pageTab,
    onPageTabChange,
    refetchTournament: refetch,
    onUploadRules,
    onUploadLogo,
  };
};

/*
    ____,-------------------------------,____
    \   |           Запчасти            |   /
    /___|-------------------------------|___\
*/

function useActions(
  clubId: string,
  tournamentId: string,
  refetchTournament: () => void,
) {
  const navigate = useNavigate();
  const { report } = useErrorNotification();

  // Save

  const { mutateAsync: save } = useUpdateTournamentMutation();

  const onSave = useCallback(
    async (tournament: UpdateTournamentRequest) => {
      const result = await save({
        clubId,
        tournament: { ...tournament, tournamentId },
      });

      if (result) {
        // HACK: setTimeout нужен для того, чтобы форма успела стать
        // isSubmitSuccessful и отключила предупреждение о несохранённых данных
        // при попытке покинуть страницу
        setTimeout(() => {
          navigate({
            to: `/club/$clubId/tournament/$tournamentId`,
            params: { clubId, tournamentId },
          });
        }, 100);
      } else {
        report();
      }
    },
    [save, clubId, tournamentId, navigate, report],
  );

  // Delete

  const { mutateAsync: del, isPending: isDeleting } =
    useDeleteTournamentMutation();

  const onDelete = useCallback(async () => {
    const result = await del({ clubId, tournamentId });
    if (result) {
      navigate({ to: '/club/$clubId', params: { clubId } });
    } else {
      report();
    }
  }, [del, clubId, tournamentId, report, navigate]);

  // Upload rules

  const { mutateAsync: uploadRules } = useTournamentUploadRulesMutation(
    clubId,
    tournamentId,
  );

  const onUploadRules = useCallback(
    async (file: FormData) => {
      await uploadRules(file);
      refetchTournament();
    },
    [uploadRules, refetchTournament],
  );

  // Upload logo

  const { mutateAsync: uploadLogo } = useTournamentUploadLogoMutation(
    clubId,
    tournamentId,
  );

  const onUploadLogo = useCallback(
    async (file: FormData) => {
      await uploadLogo(file);
      refetchTournament();
    },
    [uploadLogo, refetchTournament],
  );

  // Exit

  const onBack = useCallback(() => {
    navigate({
      to: '/club/$clubId/tournament/$tournamentId',
      params: { clubId, tournamentId },
    });
  }, [navigate, clubId, tournamentId]);

  // ...

  return {
    onSave,
    onDelete,
    isDeleting,
    onUploadLogo,
    onUploadRules,
    onBack,
  };
}
