import { RefObject, useEffect, useRef } from 'react';

/*
    ____,-------------------------------,____
    \   |          Состояние            |   /
    /___|-------------------------------|___\
*/

export type DragContext<T> = {
  draggable: HTMLElement;
  droppable: HTMLElement | null;
  data: T;
  initial: { x: number; y: number };
  current: { x: number; y: number };
};

export type DragAnchor = {
  x: number;
  y: number;
  initX: number;
  initY: number;
};

class DragState {
  status: 'idle' | 'ready' | 'dragging' = 'idle';
  draggable: HTMLElement | null = null;
  droppable: HTMLElement | null = null;
  initial: { x: number; y: number } | null = null;
  current: { x: number; y: number } | null = null;
  data: unknown | null = null;

  toReady(x: number, y: number) {
    if (this.status !== 'idle') return;
    this.status = 'ready';
    this.initial = { x, y };
  }

  toDragging(x: number, y: number, draggable: HTMLElement, data: unknown) {
    if (this.status !== 'ready') return;
    this.status = 'dragging';
    this.draggable = draggable;
    this.current = { x, y };
    this.data = data;
  }

  toIddle() {
    if (this.status === 'idle') return;
    this.status = 'idle';
    this.draggable = null;
    this.droppable = null;
    this.initial = null;
    this.current = null;
    this.data = null;
  }

  update(x: number, y: number) {
    if (this.status !== 'dragging') return;
    if (!this.current) {
      this.current = { x, y };
    } else {
      this.current.x = x;
      this.current.y = y;
    }
  }

  updateDroppable(droppable: HTMLElement | null) {
    if (this.status !== 'dragging') return;
    this.droppable = droppable;
  }
}

// Глобальный трекер перетаскиваний
const state: DragState = new DragState();

/*
    ____,-------------------------------,____
    \   |          Хук useDrag          |   /
    /___|-------------------------------|___\
*/

export type DragCallbacks<T> = {
  /** Иногда нужно, что dragging начинался сразу по pointerdown, не дожидаясь pointermove */
  filterPointer?: (e: PointerEvent) => 'accept' | 'reject' | 'force-drag';
  canDrag?: (e: PointerEvent) => boolean;
  onStart?: (ctx: DragContext<T>, e: PointerEvent) => T;
  onStop?: (ctx: DragContext<T>, e: PointerEvent) => void;
  customUpdate?: (initial: DragAnchor, e: PointerEvent) => void;
};

export function useDrag<T = unknown>(
  elRef: RefObject<HTMLElement>,
  dragRef: RefObject<HTMLElement>,
  callbacks: DragCallbacks<T>,
) {
  const cbRef = useRef(callbacks);
  cbRef.current = callbacks;

  useEffect(() => {
    const el = elRef.current;
    if (!el) return;

    // ------------------------------------------
    // Утилиты

    let anchor: DragAnchor | null = null;

    function move(e: PointerEvent) {
      if (!dragRef.current || !el) return;

      if (!anchor) {
        // FIXME: тут торчат уши структуры доски
        const rectBoard = el.parentElement!.getBoundingClientRect();
        const rectSquare = el.getBoundingClientRect();
        const { width, height } = rectSquare;
        const offsetX = e.clientX - (rectSquare.left + width / 2);
        const offsetY = e.clientY - (rectSquare.top + height / 2);
        anchor = {
          x: rectSquare.left - rectBoard.left + offsetX,
          y: rectSquare.top - rectBoard.top + offsetY,
          initX: e.clientX,
          initY: e.clientY,
        };
      }

      if (cbRef.current.customUpdate) {
        cbRef.current.customUpdate(anchor, e);
      } else {
        const { x, y, initX, initY } = anchor;
        const dX = e.clientX - initX;
        const dY = e.clientY - initY;

        dragRef.current.style.translate = `${Math.round(x + dX)}px ${Math.round(y + dY)}px`;
      }
    }

    function cleanUp() {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUpOrCancel);
      document.removeEventListener('pointercancel', onPointerUpOrCancel);
    }

    // ------------------------------------------
    // События

    function onPointerDown(e: PointerEvent) {
      const { filterPointer } = cbRef.current;

      const pointerStatus = filterPointer?.(e) ?? 'accept';
      if (pointerStatus === 'reject') return;

      const target = e.target as HTMLElement;
      if (target.hasPointerCapture(e.pointerId)) {
        target.releasePointerCapture(e.pointerId);
      }

      state.toReady(e.clientX, e.clientY);
      if (pointerStatus === 'force-drag') {
        start(e, pointerStatus);
      }

      document.addEventListener('pointermove', onPointerMove);
      document.addEventListener('pointerup', onPointerUpOrCancel);
      document.addEventListener('pointercancel', onPointerUpOrCancel);
    }

    function onPointerMove(e: PointerEvent) {
      if (state.status === 'ready') {
        const MOVE_THRESHOLD = 1;
        if (
          state.initial &&
          Math.abs(e.clientX - state.initial.x) <= MOVE_THRESHOLD &&
          Math.abs(e.clientY - state.initial.y) <= MOVE_THRESHOLD
        ) {
          return;
        }

        start(e);
      } else if (state.status === 'dragging') {
        state.update(e.clientX, e.clientY);
        move(e);
      }
    }

    function onPointerUpOrCancel(e: PointerEvent) {
      cleanUp();
      if (state.status === 'dragging') {
        cbRef.current?.onStop?.(state as DragContext<T>, e);
      }
      state.toIddle();
    }

    el.addEventListener('pointerdown', onPointerDown);

    // ------------------------------------------
    // Старт перетаскивания

    function start(
      e: PointerEvent,
      pointerStatus: 'accept' | 'force-drag' = 'accept',
    ) {
      if (!elRef.current) return;

      const { canDrag, onStart } = cbRef.current;

      const allow = canDrag?.(e) ?? true;
      if (allow) {
        const data = onStart?.(state as DragContext<T>, e);
        state.toDragging(e.clientX, e.clientY, elRef.current, data);
      } else {
        cleanUp();
        if (pointerStatus === 'accept') state.toIddle();
      }
    }
    // ------------------------------------------
    // Сброс

    return () => {
      cleanUp();
      el.removeEventListener('pointerdown', onPointerDown);
    };
  }, [cbRef, dragRef, elRef]);
}

/*
    ____,-------------------------------,____
    \   |          Хук useDrop          |   /
    /___|-------------------------------|___\
*/

export type DropCallbacks<T> = {
  canDrop?: (ctx: DragContext<T>) => boolean;
  onEnter?: (ctx: DragContext<T>) => void;
  onLeave?: (ctx: DragContext<T>) => void;
  onDrop?: (ctx: DragContext<T>) => void;
};

export function useDrop<T = undefined>(
  elRef: RefObject<HTMLElement>,
  callbacks: DropCallbacks<T>,
) {
  const cbRef = useRef(callbacks);
  cbRef.current = callbacks;

  useEffect(() => {
    const el = elRef.current;
    if (!el) return;

    const onPointerEnter = () => {
      if (state.status !== 'dragging') return;
      const allow = cbRef.current?.canDrop?.(state as DragContext<T>) ?? true;
      if (allow) {
        state.updateDroppable(elRef.current);
        cbRef.current?.onEnter?.(state as DragContext<T>);
        el.addEventListener('pointerleave', onPointerLeave);
        el.addEventListener('pointercancel', onPointerLeave);
        el.addEventListener('pointerup', onPointerUp);
      }
    };
    const onPointerLeave = () => {
      onCleanUp();
      if (state.status !== 'dragging') return;
      state.updateDroppable(null);
      cbRef.current?.onLeave?.(state as DragContext<T>);
    };
    const onPointerUp = () => {
      onCleanUp();
      if (state.status === 'dragging') {
        cbRef.current?.onDrop?.(state as DragContext<T>);
      }
    };
    const onCleanUp = () => {
      el.removeEventListener('pointerleave', onPointerLeave);
      el.removeEventListener('pointercancel', onPointerLeave);
      el.removeEventListener('pointerup', onPointerUp);
    };

    el.addEventListener('pointerenter', onPointerEnter);

    return () => {
      el.removeEventListener('pointerenter', onPointerEnter);
      onCleanUp();
    };
  }, [cbRef, elRef]);
}
