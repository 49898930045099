import { useCallback } from 'react';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import {
  useClubCardQuery,
  useJoinClubMutation,
} from '@features/club/api/hooks';
import { ClubCardPageProps } from '@features/club/pages/ClubCardPage/index';
import {
  useCreateMatchMutation,
  useCreateTournamentMutation,
} from '@features/tournament/api/hooks';
import { useNavigate } from '@tanstack/react-router';

type Params = {
  clubId: string;
};

export function useClubCard({ clubId }: Params): ClubCardPageProps {
  const { data: club, isPending, refetch } = useClubCardQuery(clubId);
  const { mutateAsync: createTournament } = useCreateTournamentMutation();
  const { mutateAsync: createMatch } = useCreateMatchMutation();
  const { mutateAsync: joinClub, isPending: isJoinPending } =
    useJoinClubMutation(clubId);
  const { isManager } = useClubRoles(clubId);

  const navigate = useNavigate();

  const onTournamentCreate = useCallback(async () => {
    if (!clubId) return;
    const result = await createTournament({ clubId });
    if (result?.tournamentId) {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/edit',
        params: { clubId, tournamentId: result.tournamentId },
      });
    }
  }, [navigate, clubId, createTournament]);

  const onMatchCreate = useCallback(async () => {
    if (!clubId) return;
    const result = await createMatch({ clubId });
    if (result?.tournamentId) {
      navigate({
        to: '/club/$clubId/tournament/$tournamentId/edit',
        params: { clubId, tournamentId: result.tournamentId },
      });
    }
  }, [navigate, clubId, createMatch]);

  const onSettings = () => {
    navigate({
      to: '/club/$clubId/settings',
      params: { clubId },
    });
  };

  const onClubJoin = useCallback(async () => {
    await joinClub();
    refetch();
  }, [joinClub, refetch]);

  return {
    club: club && {
      ...club,
      logoUrl: club?.logoUrl || undefined,
      themeColor: club?.themeColor || undefined,
    },
    isPending,
    onClubJoin,
    isJoinPending,
    isManager,
    onTournamentCreate,
    onMatchCreate,
    onSettings,
  };
}
