import { ChessBoardProps } from 'exports/ChessBoard';
import { useMemo } from 'react';
import { components } from '@features/game/api';
import { PlayerPanel, PlayerPanelProps } from '@features/game/ui/PlayerPanel';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';

type Props = {
  color: 'black' | 'white';
  orientation: PlayerPanelProps['orientation'];
  turn: 'black' | 'white' | null;
  mobile?: PlayerPanelProps['mobile'];
  timePressureSec?: number;
  game: components['schemas']['GameDto'];
  winner: ChessBoardProps['winner'];
  scoresheetTimeMs?: { white?: number; black?: number };
};

export type { Props as PlayerPanelWidgetProps };

export function PlayerPanelWidget({
  color,
  orientation,
  turn,
  mobile,
  timePressureSec,
  game,
  winner,
  scoresheetTimeMs,
}: Props) {
  const { timeMs, name, rating, result, timestamp } = useMemo(
    () => getPlayerData(color, game, winner),
    [color, game, winner],
  );

  const active = turn === color;
  const untillMs = active ? timestamp + timeMs : Date.now() + timeMs;
  const { seconds } = useCountdownUntill(untillMs, !active || !!winner);

  const scoresheetPlayerTime =
    game.status !== 'InProgress' &&
    scoresheetTimeMs !== undefined &&
    scoresheetTimeMs[color] !== undefined
      ? Math.ceil(scoresheetTimeMs[color] / 1000)
      : undefined;

  return (
    <PlayerPanel
      orientation={orientation}
      mobile={mobile}
      //-
      name={name}
      //-
      seconds={scoresheetPlayerTime ?? seconds}
      timePressure={timePressureSec ? seconds <= timePressureSec : false}
      //-
      networkProblem={undefined}
      //-
      rating={rating}
      ratingGain={undefined}
      //-
      result={result}
    />
  );
}

function getPlayerData(
  color: 'black' | 'white' = 'white',
  game: components['schemas']['GameDto'],
  winner: ChessBoardProps['winner'],
): {
  timestamp: number;
  timeMs: number;
  name: PlayerPanelProps['name'];
  rating: PlayerPanelProps['rating'];
  result: PlayerPanelProps['result'];
} {
  const result = winner
    ? winner === 'draw'
      ? 'draw'
      : winner === color
        ? 'win'
        : 'defeat'
    : undefined;

  const { firstName, lastName, rating } = game[color];
  const timeMs =
    color === 'black' ? game.clock.blackLeftMs : game.clock.whiteLeftMs;

  return {
    name: `${lastName} ${firstName}`,
    rating: rating ?? undefined,
    timeMs,
    result,
    timestamp: roundToSeconds(game.clock.timestampMs),
  };
}

function roundToSeconds(ms: number) {
  return Math.floor(ms / 1000) * 1000;
}
