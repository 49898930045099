import { datadogRum } from '@datadog/browser-rum';

export function initTracking() {
  if (!__RUNTIME.isProduction) return;

  datadogRum.init({
    applicationId: '3a6ee6e3-0248-4c1f-9e44-3a1de32d2bfe',
    clientToken: 'pub4a12b4e1c6da2beec8753b4475d298b3',
    service: 'play.chessopen.ru',
    env: 'prod',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    version: __RUNTIME.version,
    trackUserInteractions: false,
    trackResources: false,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

export function setUserForTracking(id: string, name: string) {
  if (!__RUNTIME.isProduction) return;

  datadogRum.setUser({ id, name });
}

export function clearUserForTracking() {
  if (!__RUNTIME.isProduction) return;

  datadogRum.clearUser();
}
