import { useCallback } from 'react';
import { useGameAccessKey } from '@features/game/api';
import { useAutoPromotion } from '@features/game/hooks/useAutoPromotion';
import { GamePage } from '@features/game/pages/GamePage/GamePage';
import { createFileRoute, useRouter } from '@tanstack/react-router';

export const Route = createFileRoute('/game/$gameId')({
  component: Page,
  validateSearch: (search) => {
    return {
      playerId: search?.playerId,
    } as { playerId: string };
  },
});

function Page() {
  const { gameId } = Route.useParams();
  const { playerId } = Route.useSearch();
  const { navigate } = useRouter();
  const handleLeave = useCallback(() => {
    navigate({ to: '/game' });
  }, [navigate]);

  const { isPending, data } = useGameAccessKey(gameId, playerId);
  const { autoPromotion } = useAutoPromotion();

  return (
    <GamePage
      key={gameId}
      gameId={gameId}
      playerId={playerId}
      accessKey={data?.accessToken}
      showSkeleton={isPending}
      onLeave={handleLeave}
      autoPromotion={autoPromotion}
    />
  );
}
