export function cx(
  ...args: Array<
    string | number | null | undefined | false | 0 | Record<string, unknown>
  >
) {
  const result = args.reduce((sink, arg) => {
    if (typeof arg === 'object') {
      for (const key in arg) {
        if (/* Object.prototype.hasOwnProperty.call(arg, key) && */ arg[key]) {
          sink.push(key);
        }
      }
    } else if (arg && typeof arg === 'string') {
      sink.push(arg);
    }
    return sink;
  }, [] as string[]);

  return result.length ? result.join(' ') : undefined;
}
