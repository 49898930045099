import { cx } from '@libs/classnames';
import styles from './NetworkProblems.module.css';

export type Props = {
  className?: string;
};

export function NetworkProblems({ className }: Props) {
  return (
    <section className={cx(styles.networkProblems, className)}>
      <h2 className={styles.header}>Потеряна связь с сервером</h2>
      <p className={styles.text}>Подключаемся снова</p>
    </section>
  );
}
