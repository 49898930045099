import { components } from '@features/shared/api/club/generated';

export type DateString = Date | string;

export type ParticipantStatus =
  | 'Invited'
  | 'Participating'
  | 'Exited'
  | 'Banned'
  | 'Deleted';

export type TournamentStatus =
  | 'Raw'
  | 'Draft'
  | 'NotStarted'
  | 'Paused'
  | 'InProgress'
  | 'Finished'
  | 'Deleted';

export type TournamentEntrance = 'Open' | 'Rank' | 'Invite';
export type TournamentMode = 'Private' | 'Team' | 'PrivateTeam';
export type ClockControlType = 'Blitz' | 'Rapid' | 'Classic';
export const ClockControlTypes: ClockControlType[] = [
  'Blitz',
  'Rapid',
  'Classic',
];
export type ClockControlSettings = {
  initialSeconds: number;
  addSecondsPerMove: number;
};
export type TournamentAdditionalScore =
  | 'Berger'
  | 'PartBerger'
  | 'Match'
  | 'Black'
  | 'ScoreSum';

export type TournamentSystem = 'RoundRobin' | 'Swiss' | 'Knockout' | 'OneOnOne';

export type GameSide = 'Black' | 'White' | 'Both' | 'Unknown';

export type GameParticipantsResult =
  | 'Win'
  | 'Loss'
  | 'Draw'
  | 'InProgress'
  | 'UnPlayed'
  | 'Skipped'
  | 'Mixed';

export type PositionAdditionalScore = {
  scoreType: TournamentAdditionalScore;
  score: number | null;
};

export type PositionGame = {
  gameId?: string | null;
  score?: number | null;
  resultType?: GameParticipantsResult;
  side?: GameSide;
};
export type PositionParticipant = {
  no: number;
  title: string;
  games: PositionGame[];
  additionalScores?: PositionAdditionalScore[];
  totalScore?: number | null;
  place?: string;
  subRows?: PositionParticipant[] | null;
};
export type PositionRound = {
  num: number;
  members: PositionParticipant[];
  gamesCount: number;
  additionalScoreTypes?: TournamentAdditionalScore[];
};
export type PositionData = {
  rounds: PositionRound[];
};

export type GameMovementRow = {
  boardNo?: number | null;
  player1: GameMovementPlayer;
  player2: GameMovementPlayer;
};
export type GameMovementPlayer = {
  userId: string;
  lastName: string;
  firstName: string;
  title?: string | null;
  game: PositionGame;
};

export type ParticipantDto = components['schemas']['ParticipantDto'];

export type MemberTournamentParticipantDto =
  components['schemas']['MemberTournamentParticipantDto'];

export type TournamentTeam = {
  teamId: string;
  name: string;
  tournamentId: string;
  clubId: string;
};

export type TournamentRound = {
  roundId: string;
  roundNumber: number;
  cycleNumber: number | null;
  status: 'New' | 'Drafted' | 'InProgress' | 'Finished';
  startTimestampMs?: number | null;
};

export type Contact = {
  name: string;
  label: string;
  uri: string;
};
export type ClubModeratorDto = {
  moderatorId: string;
  email: string;
};
export type ClubAdminDto = {
  fullName: string;
  phoneNumber: string;
  email: string;
};

export type TournamentShort = {
  tournamentId: string;
  status: TournamentStatus;
  title: string;
  startTimestampMs: number;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  clockControl: ClockControlType;
  clockControlSettings: ClockControlSettings;
  mode: TournamentMode;
  system: TournamentSystem;
  participationStatus?: ParticipantStatus;
};

export type Tournament =
  | RoundRobinSystemTournament
  | SwissSystemTournament
  | KnockoutSystemTournament;

export type RoundRobinSystemTournament = BaseTournament &
  RoundRobinSystemFields;
export type SwissSystemTournament = BaseTournament & SwissSystemFields;
export type KnockoutSystemTournament = BaseTournament & KnockoutSystemFields;
type BaseTournament = {
  clubId: string;
  tournamentId: string;
  status: TournamentStatus;
  title: string;
  logoUrl?: string | null;
  startTimestampMs?: number | null;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  contacts: Contact[];
  pausedTillTimestampMs?: number | null;
  rulesUrl?: string | null;
  rcfRankCalculated: boolean;
  mode: TournamentMode;
  clockControl: ClockControlType;
  clockControlSettings: ClockControlSettings;
  additionalScoreOrder: TournamentAdditionalScore[];
  armageddonEnabled: boolean;
  armageddonBlackLimitMinutes?: number | null;
  armageddonWhiteLimitMinutes?: number | null;
  system: TournamentSystem;
  teams?: TournamentTeam[];
  teamModeSettings?: {
    size: number;
  };
  rounds: TournamentRound[];
  participantsCount: number;
  delayBetweenRoundsSeconds: number;
  oneOnOneSettings?: OneOnOneSettings;
};

type RoundRobinSystemFields = {
  roundRobinSettings?: RoundRobinSettings;
};
export type RoundRobinSettings = {
  roundsCount: number;
};

type SwissSystemFields = {
  swissSettings?: SwissSettings;
};
export type SwissSettings = {
  roundsCount: number;
};

type KnockoutSystemFields = {
  knockoutSettings?: KnockOutSettings;
};
export type KnockOutSettings = {
  gamesPerRound: number;
  gamesPerFinalRound: number;
  additionalGamesPerDraw: number;
  additionalGamesPerFinalDraw: number;
};

export type OneOnOneSettings = components['schemas']['OneOnOneSettings'];

export type ClubDto = {
  clubId: string;
  title: string;
  description: string;
  membersCount: number;
  activeTournamentsCount: number;
  finishedTournamentsCount: number;
  administrator: ClubAdminDto;
  telegramChannel: string; // always starts with @ or always without it (last - preferable)
  logoUrl: string; // full absolute url to club logo
  themeColor: string; // hex-color from picker
  createdTimestampMs: Date; // datestring or timestamp
  moderators: ClubModeratorDto[];
};
export type ClubGroup = {
  id: number;
  title: string;
  rcfId?: string;
  participantIds: number[];
};

export type MemberDto = components['schemas']['MemberDto'];

export type MemberStatus = components['schemas']['MemberStatus'];

export type UserEloDto = {
  blitz: EloDto;
  rapid: EloDto;
  classic: EloDto;
};

export type EloDto = {
  clockControl: ClockControlType;
  gamesCount: number;
  rating: number;
  lastChange: number;
};

export type UpdateTournamentRequest = {
  title: string;
  startTimestampMs: number;
  promoted: boolean;
  entrance: TournamentEntrance;
  minEntranceElo?: number | null;
  contacts: Contact[];
  rcfRankCalculated: boolean;
  mode: TournamentMode;
  clockControl: ClockControlType;
  clockControlSettings: ClockControlSettings;
  additionalScoreOrder: TournamentAdditionalScore[];
  armageddonEnabled: boolean;
  armageddonBlackLimitMinutes: number;
  armageddonWhiteLimitMinutes: number;
  system: TournamentSystem;
  knockOutSettings?: KnockOutSettings;
  swissSettings?: SwissSettings;
  roundRobinSettings?: RoundRobinSettings;
  teamModeSettings?: {
    size: number;
  };
  oneOnOneSettings?: OneOnOneSettings;
};
