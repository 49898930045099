import { useCallback } from 'react';
import { z } from 'zod';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { ClubSettingsPage } from '@features/club/pages/ClubSettingsPage';
import { TournamentLayoutWidget } from '@features/shared/ui/TournamentLayout/TournamentLayoutWidget';
import { createFileRoute, Navigate, useNavigate } from '@tanstack/react-router';

const querySchema = z.object({
  pageTab: z
    .enum(['participants', 'club', 'admin'])
    .catch('participants')
    .optional(),
});

type Query = z.infer<typeof querySchema>;

export const Route = createFileRoute('/_authenticated/club/$clubId_/settings')({
  component: Page,
  validateSearch: querySchema,
});

function Page() {
  const { clubId } = Route.useParams();
  const { pageTab = 'participants' } = Route.useSearch();
  const { isManager } = useClubRoles(clubId);
  const navigate = useNavigate({ from: Route.fullPath });

  const handleTabChange = useCallback(
    (newTab: Query['pageTab']) => {
      navigate({
        search: (prev) => ({ ...prev, pageTab: newTab }),
      });
    },
    [navigate],
  );
  if (!isManager) {
    return <Navigate to="/" />;
  }

  return (
    <TournamentLayoutWidget hideCreateTournament clubId={clubId}>
      <ClubSettingsPage
        clubId={clubId}
        tab={pageTab}
        onTabChange={handleTabChange}
      />
    </TournamentLayoutWidget>
  );
}
