import { CSSProperties } from 'react';
import { cx } from '@libs/classnames';
import styles from './BoardStockfishArrow.module.css';

export type Props = {
  className?: string;
  style?: {
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    transform?: CSSProperties['transform'];
  };
  type?: 'primary' | 'secondary';
};

export function BoardStockfishArrow({
  className,
  style,
  type = 'primary',
}: Props) {
  return (
    <div className={cx(styles.arrowWrapper, className)} style={style}>
      <div
        className={cx(styles.arrow, {
          [styles.primary]: type === 'primary',
          [styles.secondary]: type === 'secondary',
        })}
      >
        <div className={styles.tail}></div>
        <div className={styles.head}>
          {type === 'primary' && (
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 33 42"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M28.8 17.6 6.2 3C3.4 1.2 2.1.4 1 .9 0 1.5 0 3.1 0 6.4V35.6c0 3.3 0 4.9 1 5.5 1.1.5 2.4-.3 5.2-2.1L28.8 24.4c2.3-1.6 3.5-2.3 3.5-3.4s-1.2-1.8-3.5-3.4Z"
                fill="currentColor"
              />
              <defs>{SVG_FILTER}</defs>
            </svg>
          )}
          {type === 'secondary' && (
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 29 20"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M21 6.2 5.1 1.5C2.8.8 1.6.5.8 1.1 0 1.7 0 2.9 0 5.3v9.4c-0 2.4-0 3.6.8 4.2.8.6 2 .3 4.3-.4L21 13.8c5.3-1.5 7.9-2.3 7.9-3.8S26.3 7.7 21 6.2Z"
                fill="currentColor"
              />
              <defs>{SVG_FILTER}</defs>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}

const SVG_FILTER = (
  <filter
    id="arrow-shadow-929ec67cd26d"
    x="-10%"
    y="0"
    width="120%"
    height="100%"
    colorInterpolationFilters="sRGB"
  >
    <feFlood floodOpacity="0" result="bgFix" />
    <feColorMatrix
      in="SourceAlpha"
      type="matrix"
      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
      result="hardAlpha"
    />
    <feOffset />
    <feGaussianBlur stdDeviation="8" />
    <feComposite in2="hardAlpha" operator="out" />
    <feColorMatrix
      type="matrix"
      values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
    />
    <feBlend mode="normal" in2="bgFix" result="dropShadow" />
    <feBlend mode="normal" in="SourceGraphic" in2="dropShadow" result="shape" />
  </filter>
);
