export function SaveIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2179 0.472855C30.9764 0.787197 31.6649 1.24892 32.2437 1.83127L38.1687 7.75627C38.7515 8.336 39.2135 9.02565 39.5279 9.78522C39.8422 10.5448 40.0027 11.3592 40 12.1813V32.5C40 34.4891 39.2098 36.3968 37.8033 37.8033C36.3968 39.2098 34.4891 40 32.5 40H7.5C5.51087 40 3.60322 39.2098 2.1967 37.8033C0.790176 36.3968 0 34.4891 0 32.5V7.50002C0 5.5109 0.790176 3.60324 2.1967 2.19672C3.60322 0.790199 5.51087 2.27561e-05 7.5 2.27561e-05H27.825C28.646 -0.00220055 29.4594 0.158514 30.2179 0.472855ZM34.2677 34.2678C34.7366 33.7989 35 33.163 35 32.5V12.1813C35.0001 12.0163 34.9676 11.853 34.9043 11.7007C34.841 11.5483 34.7482 11.4101 34.6312 11.2938L28.7062 5.36877C28.4729 5.13403 28.156 5.00142 27.825 5.00002H7.5C6.83696 5.00002 6.20107 5.26341 5.73223 5.73225C5.26339 6.20109 5 6.83698 5 7.50002V32.5C5 33.163 5.26339 33.7989 5.73223 34.2678C6.20107 34.7366 6.83696 35 7.5 35H32.5C33.163 35 33.7989 34.7366 34.2677 34.2678ZM12.5001 25H17.5001C18.1631 25 18.799 25.2633 19.2678 25.7322C19.7367 26.201 20.0001 26.8369 20.0001 27.5C20.0001 28.163 19.7367 28.7989 19.2678 29.2677C18.799 29.7366 18.1631 30 17.5001 30H12.5001C11.837 30 11.2012 29.7366 10.7323 29.2677C10.2635 28.7989 10.0001 28.163 10.0001 27.5C10.0001 26.8369 10.2635 26.201 10.7323 25.7322C11.2012 25.2633 11.837 25 12.5001 25Z"
        fill="currentColor"
      />
    </svg>
  );
}
