import { SignalRSocket } from '@libs/socket';
import { components } from './generated';

const BASE_URL = __RUNTIME.streamApi;

/*
    ____,-------------------------------,____
    \   |         Socket Clients        |   /
    /___|-------------------------------|___\
*/

// ------------------------------------------
// Game public stream

export const GAME_STREAM_SOCKET_URL = `${BASE_URL}/hubs/stream/game`;

// ------------------------------------------
// Tournament public stream

const TOURNAMENT_STREAM_SOCKET_URL = `${BASE_URL}/hubs/stream/tournament`;

export type TournamentGamesSocketEvents =
  | components['schemas']['RoundGamesState']
  | components['schemas']['GameChanged'];

export class TournamentPublicStreamSocket extends SignalRSocket<TournamentGamesSocketEvents> {
  constructor(
    public tournamentId: string,
    public roundNumber: number,
  ) {
    const EVENT_TYPES: Record<TournamentGamesSocketEvents['eventType'], null> =
      { RoundGamesState: null, GameChanged: null };

    super(
      `${TOURNAMENT_STREAM_SOCKET_URL}?tournamentId=${tournamentId}&roundNumber=${roundNumber}`,
      Object.keys(EVENT_TYPES),
    );
  }
}
