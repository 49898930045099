import { ReactNode } from 'react';
import styles from './Layout.module.css';

export type Props = {
  children: ReactNode;
  header?: ReactNode;
};
export function Layout({ header, children }: Props) {
  return (
    <div className={styles.layout}>
      {header}
      <div className={styles.innerContainer}>{children}</div>
    </div>
  );
}
