import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Toast.module.css';

export type Props = {
  className?: string;
  type: 'error';
  children: ReactNode;
  onClose?: () => void;
};

export function Toast({ className, type = 'error', children, onClose }: Props) {
  return (
    <article className={cx(styles.toast, className, styles[type])}>
      <button
        className={styles.close}
        onClick={onClose}
        aria-label="Закрыть"
      ></button>
      {children}
    </article>
  );
}
