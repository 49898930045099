import { useNoPairModal } from '@features/tournament/hooks/useNoPairModal';
import { NoPairModal } from './NoPairModal';

type Props = {
  className?: string;
  tournamentId: string;
};

export function NoPairModalWidget({ tournamentId }: Props) {
  const props = useNoPairModal(tournamentId);
  return props ? <NoPairModal {...props} /> : undefined;
}
