import { ReactNode } from 'react';
import InfiniteScrollExternal from 'react-infinite-scroll-component';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import styles from './InfiniteScroll.module.css';

export type Props = {
  className?: string;
  fetchMore: () => void;
  hasMore: boolean;
  itemsLength: number;
  children: ReactNode;
};

export function InfiniteScroll({
  className,
  fetchMore,
  itemsLength,
  hasMore,
  children,
}: Props) {
  return (
    <div className={cx(className, styles.root)}>
      <InfiniteScrollExternal
        next={fetchMore}
        hasMore={hasMore}
        loader={<Loader centered />}
        dataLength={itemsLength}
        height="auto"
      >
        {children}
      </InfiniteScrollExternal>
    </div>
  );
}
