import { useCallback, useState } from 'react';
import { UserSettingsMenu } from '@features/user/ui/UserSettingsMenu';
import { Avatar } from '@ui/components/Avatar';
import { Card } from '@ui/components/Card';
import { HollowButton } from '@ui/components/HollowButton';
import { Popover } from '@ui/components/Popover/Popover';
import styles from './User.module.css';

export type Props = {
  firstName?: string;
  lastName?: string;
  avatar?: string;
  settings?: {
    autoQueen?: boolean;
    darkTheme?: boolean;
    onAutoQueenToggle?: (enabled: boolean) => void;
    onDarkThemeToggle?: (enabled: boolean) => void;
  };
  onLogin?: () => void;
  onLogout?: () => void;
};
export function User({
  firstName,
  lastName,
  avatar,
  settings,
  onLogin,
  onLogout,
}: Props) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setIsPopoverVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsPopoverVisible(false);
  }, []);

  const name = formatName(lastName, firstName);

  if (!name) {
    return (
      <HollowButton onClick={onLogin} className={styles.loginButton}>
        Войти
      </HollowButton>
    );
  }

  const avatarType = avatar ? 'image' : 'text';
  const avatarContent = avatar ?? formatInitials(lastName, firstName) ?? '??';

  return (
    <div className={styles.root}>
      <Avatar
        className={styles.avatar}
        type={avatarType}
        content={avatarContent}
        onClick={handleOpen}
      />

      <Popover
        active={isPopoverVisible}
        onHide={handleClose}
        className={styles.settingsPopover}
      >
        <Card className={styles.settingsContainer}>
          <div className={styles.settingsTitle}>
            <span>{name}</span>
            <Avatar
              className={styles.settingsAvatar}
              type={avatarType}
              content={avatarContent}
              onClick={handleClose}
            />
          </div>
          <UserSettingsMenu {...settings} />
          <hr className={styles.hr} />
          <span className={styles.link} onClick={onLogout}>
            Выйти
          </span>
        </Card>
      </Popover>
    </div>
  );
}

function formatName(lastName?: string, firstName?: string) {
  return lastName || firstName
    ? [lastName, firstName].filter(Boolean).join(' ')
    : undefined;
}

function formatInitials(lastName?: string, firstName?: string) {
  return lastName || firstName
    ? [
        lastName?.charAt(0).toLocaleUpperCase(),
        firstName?.charAt(0).toLocaleUpperCase(),
      ]
        .filter(Boolean)
        .join('')
    : undefined;
}
