import { LegacyRef, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { useClickOutside } from '@libs/hooks/useClickOutside';
import { AnimatedNode } from '@ui/components/Animation';
import styles from './Popover.module.css';

export type Props = {
  className?: string;
  wrapperClassName?: string;
  active?: boolean;
  onHide?: () => void;
  children: ReactNode;
};
export function Popover({
  className,
  wrapperClassName,
  active,
  onHide,
  children,
}: Props) {
  const ref = useClickOutside((active && onHide) || undefined);
  return (
    <AnimatedNode className={wrapperClassName} animation="opacity">
      {active && (
        <div
          ref={ref as LegacyRef<HTMLDivElement>}
          className={cx(className, styles.popover)}
        >
          {children}
        </div>
      )}
    </AnimatedNode>
  );
}
