import { forwardRef, useImperativeHandle, useState } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { ClockControlType } from '@features/shared/api/typings';
import {
  useActiveTournamentParticipantsQuery,
  useTournamentCardQuery,
} from '@features/tournament/api/hooks';
import { TournamentParticipants } from './TournamentParticipants';

type Props = {
  className?: string;
  clubId: string;
  tournamentId: string;
};

type Sort =
  | 'Alphabet'
  | 'RatingBlitz'
  | 'RatingRapid'
  | 'RatingClassic'
  | 'RegistrationDate';

const SORT_RANK_MAP: { [k in ClockControlType]: Sort } = {
  Blitz: 'RatingBlitz',
  Rapid: 'RatingRapid',
  Classic: 'RatingClassic',
};

type WidgetRef = { refetch: () => void };

export const TournamentParticipantsWidget = forwardRef<WidgetRef, Props>(
  function TournamentParticipantsWidget(
    { className, clubId, tournamentId },
    ref,
  ) {
    const [tab, setTab] = useState<'Alphabet' | 'rank' | 'RegistrationDate'>(
      'RegistrationDate',
    );

    const { isManager } = useClubRoles(clubId);
    const { user } = useAuth();

    const { data: tournament } = useTournamentCardQuery(clubId, tournamentId, {
      staleTime: Infinity,
    });

    const participantsQuery = useActiveTournamentParticipantsQuery(
      clubId,
      tournamentId,
      tab === 'rank'
        ? SORT_RANK_MAP[tournament?.clockControl || 'Classic']
        : tab,
      isManager,
    );

    useImperativeHandle(ref, () => ({ refetch: participantsQuery.refetch }), [
      participantsQuery.refetch,
    ]);

    return (
      <TournamentParticipants
        className={className}
        isPending={participantsQuery.isPending}
        participants={participantsQuery.data}
        tab={tab}
        onTabChange={setTab}
        highlightedUserId={user?.userId}
        teams={tournament?.teams}
        isManager={isManager}
        clockControl={tournament?.clockControl}
      />
    );
  },
);
