import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';

export function GamesCountInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Количество партий</FormLabel>
      <FormInput>
        <Controller
          name="oneOnOneSettings.gamesCount"
          control={control}
          render={({ field: { value, onChange, disabled } }) => (
            <Input
              invisible
              type="number"
              rightLabel="партий"
              value={value.toString()}
              onChange={onChange}
              min="1"
              max="8"
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
