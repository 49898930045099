import {
  TournamentEntrance,
  TournamentMode,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import styles from './EventEntrance.module.css';

const modes: { [k in TournamentMode]: string } = {
  Team: 'Командный',
  Private: 'Личный',
  PrivateTeam: 'Лично-командный',
};

const entrances: { [k in TournamentEntrance]: string } = {
  Open: 'Открытый',
  Rank: 'По\u00A0рейтингу',
  Invite: 'По\u00A0приглашению',
};

export type Props = {
  className?: string;
  tournament?: {
    mode: TournamentMode;
    entrance: TournamentEntrance;
  };
};

export function EventEntrance({ className, tournament }: Props) {
  if (!tournament) return null;

  const mode = modes[tournament.mode];
  const entrance = entrances[tournament.entrance];

  return (
    <span className={cx(className, styles.root)}>
      {mode} · {entrance}
    </span>
  );
}
