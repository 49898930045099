export function StartIcon() {
  return (
    <svg
      width="33"
      height="38"
      viewBox="0 0 33 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.2375 12.6874L10.625 1.05613C9.57394 0.398959 8.36614 0.0351287 7.12698 0.00241852C5.88782 -0.0302917 4.6625 0.26931 3.57824 0.870116C2.49398 1.47092 1.59033 2.35103 0.961101 3.41904C0.331874 4.48705 2.13196e-05 5.70404 0 6.94363V30.5561C2.13196e-05 31.7957 0.331874 33.0127 0.961101 34.0807C1.59033 35.1487 2.49398 36.0288 3.57824 36.6296C4.6625 37.2304 5.88782 37.53 7.12698 37.4973C8.36614 37.4646 9.57394 37.1008 10.625 36.4436L29.2375 24.8124C30.2379 24.1903 31.0628 23.323 31.6337 22.2925C32.2047 21.2621 32.5029 20.1029 32.5 18.9249V18.5749C32.5029 17.3968 32.2047 16.2376 31.6337 15.2072C31.0628 14.1768 30.2379 13.3094 29.2375 12.6874ZM27.5 18.9249C27.5005 19.2539 27.417 19.5776 27.2573 19.8653C27.0976 20.153 26.867 20.3951 26.5875 20.5686L7.97499 32.2061C7.68062 32.3904 7.34227 32.4924 6.99511 32.5016C6.64796 32.5107 6.30468 32.4267 6.00099 32.2583C5.6973 32.0899 5.44427 31.8431 5.26823 31.5438C5.0922 31.2444 4.99957 30.9034 5 30.5561V6.94363C4.99957 6.59635 5.0922 6.2553 5.26823 5.95595C5.44427 5.6566 5.6973 5.40988 6.00099 5.24144C6.30468 5.07301 6.64796 4.98901 6.99511 4.99819C7.34227 5.00736 7.68062 5.10938 7.97499 5.29363L26.5875 16.9311C26.867 17.1047 27.0976 17.3468 27.2573 17.6345C27.417 17.9221 27.5005 18.2458 27.5 18.5749V18.9249Z"
        fill="currentColor"
      />
    </svg>
  );
}
