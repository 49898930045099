import { cx } from '@libs/classnames';
import { Timer } from '@ui/components/Timer';
import styles from './MatchCountdown.module.css';

export type Props = {
  className?: string;
  seconds: number;
};

export function MatchCountdown({ className, seconds }: Props) {
  return (
    <section className={cx(styles.matchCountdown, className)}>
      <h2 className={styles.header}>До начала партии</h2>
      <Timer className={styles.timer} seconds={seconds} />
    </section>
  );
}
