import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { components } from '../../api';
import { MatchCountdown } from '../../ui/MatchCountdown/MatchCountdown';

export function StartCountdownWidget({
  game,
}: {
  game: components['schemas']['GameDto'];
}) {
  const { seconds } = useCountdownUntill(game.startTimestampMs);

  if (!seconds) return null;

  return <MatchCountdown seconds={seconds} />;
}
