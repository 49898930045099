import { useUserSocket } from '@features/shared/api';
import { components } from '@features/shared/api/club/generated';
import { useTournamentGamesSocket } from '@features/shared/api/streams';
import {
  useTournamentCardQuery,
  useTournamentResultsQuery,
} from '@features/tournament/api/hooks';
import { Position } from './Position';

type Props = { clubId: string; tournamentId: string };

export function PositionWidget({ clubId, tournamentId }: Props) {
  const tournamentQUery = useTournamentCardQuery(clubId, tournamentId, {
    staleTime: Infinity,
  });
  const tournament = tournamentQUery.data;

  const positionQuery = useTournamentResultsQuery(
    clubId,
    tournamentId,
    tournament &&
      ['InProgress', 'Paused', 'Finished'].includes(tournament.status),
  );

  let position;
  const system = tournament?.system;
  const data = positionQuery.data;
  if (system === 'RoundRobin') position = data?.roundRobin?.results;
  if (system === 'OneOnOne')
    // TODO FIX, when BE fix (remove casting, typings should match)
    position = (
      data?.oneOnOne as unknown as components['schemas']['RoundRobinResults']
    )?.results;

  // Отслеживание изменений игр
  const lastRound = tournament?.rounds
    .filter((r) => r.status === 'InProgress' || r.status === 'Finished')
    .sort((r1, r2) => r1.roundNumber - r2.roundNumber)
    .at(-1);
  useTournamentGamesSocket({
    tournamentId,
    roundNumber: lastRound?.roundNumber ?? NaN,
    enabled: lastRound?.status === 'InProgress',
    cb: (e) => {
      if (e.eventType === 'GameChanged') {
        const result = e.currentState.result;
        if (
          result === 'Draw' ||
          result === 'BlackWin' ||
          result === 'WhiteWin'
        ) {
          positionQuery.refetch();
        }
      }
    },
  });

  // Отслеживание изменений турнира
  // Места становятся известны только после завершения матча, а не после
  // завершения последнего раунда
  useUserSocket((e) => {
    switch (e.eventType) {
      case 'TournamentFinished':
      case 'RoundStarted':
        if (e.tournamentId === tournamentId) {
          positionQuery.refetch();
        }
        break;
    }
  });

  if (!position || !tournament) return null;

  return (
    <Position
      rows={position}
      system={tournament.system}
      eventFinished={tournament.status === 'Finished'}
      additionalScoresOrder={tournament.additionalScoreOrder}
    />
  );
}
