import { cx } from '@libs/classnames';
import { formatSeconds } from '@libs/time';
import styles from './Timer.module.css';

export type Props = {
  className?: string;
  seconds: number;
  showHours?: boolean;
};

export function Timer({ seconds, showHours, className }: Props) {
  return (
    <div className={cx(styles.timer, className)}>
      {formatSeconds({ time: seconds, showHours })}
    </div>
  );
}
