import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './ThemeLink.module.css';

export type Props = {
  className?: string;
  href?: string;
  inNewTab?: boolean;
  onClick?: () => void;
  colorless?: boolean;
  disabled?: boolean;
  children: ReactNode;
};
export function ThemeLink({
  className,
  href,
  inNewTab,
  onClick,
  colorless,
  disabled,
  children,
}: Props) {
  const isDisabled = disabled || (!href && !onClick);

  return (
    <a
      href={href}
      target={inNewTab ? '_blank' : undefined}
      onClick={onClick}
      className={cx(className, styles.link, {
        [styles.colorless]: colorless,
        [styles.disabled]: isDisabled,
      })}
      rel="noreferrer"
    >
      {children}
    </a>
  );
}
