import { cx } from '@libs/classnames';
import { Button } from '@ui/components/Button';
import styles from './DrawOffer.module.css';

export type Props = {
  className?: string;
  onAccept?: () => void;
  onDecline?: () => void;
};

export function DrawOffer({ className, onDecline, onAccept }: Props) {
  return (
    <div className={cx(styles.root, className)}>
      <p className={styles.title}>Соперник предлагает ничью</p>
      <div className={styles.buttons}>
        <Button onClick={onDecline}>Отклонить</Button>
        <Button onClick={onAccept}>Принять</Button>
      </div>
    </div>
  );
}
