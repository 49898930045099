import { useCallback } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { useClubCardQuery } from '@features/club/api/hooks';
import { useClubColors } from '@features/club/hooks/useClubColors';
import { useGameConflictModal } from '@features/game/hooks/useGameConflictModal';
import { GamePage } from '@features/game/pages/GamePage/GamePage';
import { GameConflictModal } from '@features/game/ui/GameConflictModal';
import { useGameAuth, useTournamentGameQuery } from '@features/shared/api/club';
import { GAME_STREAM_SOCKET_URL } from '@features/shared/api/streams';
import { useNoPairModal } from '@features/tournament/hooks/useNoPairModal';
import { NoPairModal } from '@features/tournament/ui/components/NoPairModal';
import { useUserSettingsMenu } from '@features/user/hooks/useUserSettingsMenu';
import { UserWidget } from '@features/user/ui/User';
import { createFileRoute, useRouter } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/game/$gameId',
)({
  component: Page,
});

function Page() {
  const { gameId, clubId, tournamentId } = Route.useParams();
  const { navigate } = useRouter();

  const { data: club } = useClubCardQuery(clubId);

  // перекраска в клубные цвета
  useClubColors(club?.themeColor ?? undefined);

  const handleLeave = useCallback(() => {
    navigate({
      to: '/club/$clubId/tournament/$tournamentId',
      params: { clubId, tournamentId },
    });
  }, [clubId, navigate, tournamentId]);

  const game = useTournamentGameQuery(gameId);

  const { user } = useAuth();
  //
  const isMyGame =
    user &&
    [game.data?.whiteUserId, game.data?.blackUserId].includes(user.userId);
  const isActiveGame =
    game.data?.status === 'InProgress' || game.data?.status === 'NotStarted';
  const needAuth = Boolean(isMyGame && isActiveGame);
  //
  const gamesAuth = useGameAuth(gameId, { enabled: needAuth });
  //
  const settingsMenuProps = useUserSettingsMenu();
  //
  const loading = game.isPending || (needAuth && gamesAuth.isPending);
  const accessKey = gamesAuth.data?.gameAccessToken;
  const playerId = isMyGame ? user.userId : undefined;
  const customSocketUrl = needAuth ? undefined : GAME_STREAM_SOCKET_URL;

  // Модалка "Для вас не нашлось пары"
  const noPairModal = useNoPairModal(tournamentId);

  // Модалка "Начинается партия в другом турнире"
  const gameConflict = useGameConflictModal(gameId);

  // Выбор кастомной модалки
  const modal = (() => {
    if (gameConflict) return <GameConflictModal {...gameConflict} />;
    if (noPairModal) return <NoPairModal {...noPairModal} />;
  })();

  return (
    <GamePage
      key={gameId}
      avatar={user && <UserWidget />}
      gameId={gameId}
      showSkeleton={loading}
      accessKey={accessKey}
      playerId={playerId}
      customSocketUrl={customSocketUrl}
      autoPromotion={settingsMenuProps.autoQueen ? 'q' : undefined}
      onLeave={handleLeave}
      customModal={modal}
    />
  );
}
