import { useContext } from 'react';
import { Device, MediaQueryContext } from './MediaQueryContext';

export function useMediaQuery(): Device {
  const data = useContext(MediaQueryContext);

  if (data === undefined) {
    throw new Error('useMediaQuery: <MediaQueryProvider /> not found');
  }

  return data;
}
