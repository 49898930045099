import { useAuth } from '@features/auth/hooks/useAuth';
import { createFileRoute, Navigate } from '@tanstack/react-router';

export const DEFAULT_CLUB_ID = '777';

export const Route = createFileRoute('/_authenticated/club/')({
  component: () => <Page />,
});

function Page() {
  const { user } = useAuth();

  // Перекинуть в первый клуб из доступных
  if (user) {
    const clubs = Object.keys(user.clubs);
    if (clubs[0]) {
      return <Navigate to={`/club/$clubId`} params={{ clubId: clubs[0] }} />;
    }
  }

  return <Navigate to={`/club/$clubId`} params={{ clubId: DEFAULT_CLUB_ID }} />;
}
