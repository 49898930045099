import { Bishop, King, Knight, Pawn, Queen, Rook } from './Default';
import { ChessPieces } from './types';

export const DefaultChessPieces: ChessPieces = {
  K: King,
  Q: Queen,
  R: Rook,
  B: Bishop,
  N: Knight,
  P: Pawn,
};
