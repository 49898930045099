import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { AnimatedNode } from '@ui/components/Animation';
import styles from './ChessBoard.module.css';

export function ChessBoardSkeleton({ children }: { children?: ReactNode }) {
  return (
    <article className={styles.board}>
      <div className={cx(styles.playfield, styles.skeleton)}>
        <section className={styles.modal}>
          <AnimatedNode>{children}</AnimatedNode>
        </section>
      </div>
    </article>
  );
}
