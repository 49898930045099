import React, { ReactNode, Suspense } from 'react';
import { AuthProvider } from '@features/auth/providers/AuthProvider/AuthProvider';
import { useAutoGameRedirect } from '@features/game/hooks/useAutoGameRedirect';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { ErrorsNotificationProvider } from '@ui/components/ErrorsNotificationProvider';
import { MediaQueryProvider } from '@ui/components/MediaQueryProvider';
import { ThemeProvider } from '@ui/components/ThemeProvider';

/*
    ____,-------------------------------,____
    \   |            DevTools           |   /
    /___|-------------------------------|___\
*/

let routerDevtools: ReactNode = null;

if (!__RUNTIME.isProduction) {
  const STORAGE_KEY = 'router_devtools';
  const enable = !!localStorage.getItem(STORAGE_KEY);

  // eslint-disable-next-line no-console
  console.info(
    enable
      ? `Type "hideRouterDevtools()" to hide router devtools`
      : `Type "showRouterDevtools()" to show router devtools`,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).showRouterDevtools = () => {
    localStorage.setItem(STORAGE_KEY, 'on');
    window.location.reload();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).hideRouterDevtools = () => {
    localStorage.removeItem(STORAGE_KEY);
    window.location.reload();
  };
  const RouterDevTools = React.lazy(() =>
    import('@tanstack/router-devtools').then((res) => ({
      default: res.TanStackRouterDevtools,
    })),
  );

  routerDevtools = enable && (
    <Suspense>
      <RouterDevTools />
    </Suspense>
  );
}

/*
    ____,-------------------------------,____
    \   |              Роут             |   /
    /___|-------------------------------|___\
*/

const queryClient = new QueryClient();

export const Route = createRootRoute({
  component: () => (
    <>
      <MediaQueryProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <AuthProvider>
              <ErrorsNotificationProvider>
                <Outlet />
                <GlobalHooks />
              </ErrorsNotificationProvider>
            </AuthProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </MediaQueryProvider>
      {routerDevtools}
    </>
  ),
});

/*
    ____,-------------------------------,____
    \   |        Global Hooks           |   /
    /___|-------------------------------|___\
*/

function GlobalHooks() {
  // Автоматический редирект на игру, если она началась или скоро начнётся
  useAutoGameRedirect();

  return null;
}
