import { format } from 'date-fns';
import { useCallback, useRef, useState } from 'react';
import { cx } from '@libs/classnames';
import { DateView } from '@ui/components/DateView';
import { Input } from '@ui/components/Input';
import { ArrowIcon } from '@ui/icons/ArrowIcon/ArrowIcon';
import styles from './DateInput.module.css';

export type Props = {
  className?: string;
  invisible?: boolean;
  value: Date | null;
  onChange: (newDate: Date | null) => void;
  disabled?: boolean;
  min?: string;
  max?: string;
};
export function DateInput({
  className,
  value,
  onChange,
  invisible,
  disabled,
  min,
  max,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);
  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const handleInputChange = useCallback(
    (newValue?: string) => {
      onChange(newValue ? new Date(newValue) : null);
    },
    [onChange],
  );
  const handleWrapperClick = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.showPicker();
  }, [inputRef]);

  return (
    <div
      className={cx(className, styles.root, {
        [styles.focus]: isFocused,
        [styles.disabled]: disabled,
      })}
      onClick={handleWrapperClick}
    >
      {value && (
        <DateView className={styles.value} date={value} format="d MMMM yyyy" />
      )}
      <Input
        type="date"
        inputRef={inputRef}
        invisible={invisible}
        rightLabel={
          <div className={styles.pickerButton}>
            <ArrowIcon />
          </div>
        }
        className={styles.input}
        value={value ? format(value, 'yyyy-MM-dd') : ''}
        onChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={disabled}
        min={min}
        max={max}
      />
    </div>
  );
}
