import { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  ErrorsNotificationContext,
  IErrorsNotificationContext,
} from './ErrorsNotificationContext';

export function ErrorsNotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const report = useCallback((e?: Error) => {
    setTimestamp(Date.now());
    e && setError(e);
  }, []);
  const reset = useCallback(() => {
    setTimestamp(null);
    setError(null);
  }, []);

  const value = useMemo(
    (): IErrorsNotificationContext => ({
      error,
      timestamp,
      report,
      reset,
    }),
    [report, reset, timestamp, error],
  );

  return (
    <ErrorsNotificationContext.Provider value={value}>
      {children}
    </ErrorsNotificationContext.Provider>
  );
}
