import { useEffect, useRef, useState } from 'react';

export function useSticky<T extends Element | null>() {
  const ref = useRef<T>(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsSticky(
          (e && e.intersectionRatio && e.intersectionRatio < 1) || false,
        );
      },
      {
        threshold: [1],
      },
    );
    observer.observe(ref.current);
  }, []);

  return { ref, isSticky };
}
