import { CSSProperties, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Card.module.css';

export type Props = {
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
};
export function Card({
  className,
  onClick,
  style,
  active,
  disabled,
  children,
}: Props) {
  return (
    <div
      style={style}
      className={cx(className, styles.root, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
