import { ReactNode, useCallback } from 'react';
import { cx } from '@libs/classnames';
import styles from './Tabs.module.css';

type Tab<T> = {
  value: T;
  label: ReactNode;
  disabled?: boolean;
};

export type TabsProps<T> = {
  className?: string;
  tabClassName?: string;
  tabs: Tab<T>[];
  value?: T;
  onChange: (newValue: T) => void;
  disabled?: boolean;
};
export function Tabs<T extends string | boolean | number>({
  className,
  tabClassName,
  tabs,
  value,
  onChange,
  disabled,
}: TabsProps<T>) {
  const selectedTabNumber = tabs.findIndex(
    (t) => value?.toString() === t.value.toString(),
  );

  return (
    <div className={cx(className, styles.root)}>
      {tabs.map((t, i) => (
        <Tab
          key={t.value.toString()}
          className={tabClassName}
          active={value === t.value}
          label={t.label}
          value={t.value}
          onChange={onChange}
          disabled={t.disabled || disabled}
        >
          {i === 0 ? (
            <ActiveTabHighlighter selectedTabNumber={selectedTabNumber} />
          ) : null}
        </Tab>
      ))}
    </div>
  );
}

type TabProps<T> = {
  className?: string;
  label: ReactNode;
  value: T;
  active: boolean;
  onChange: (newValue: T) => void;
  disabled?: boolean;
  children?: ReactNode;
};
function Tab<T>({
  className,
  label,
  value,
  active,
  onChange,
  disabled,
  children,
}: TabProps<T>) {
  const handleClick = useCallback(() => onChange(value), [value, onChange]);
  return (
    <div
      className={cx(
        styles.tab,
        {
          [styles.active]: active,
          [styles.disabled]: disabled,
        },
        className,
      )}
      onClick={handleClick}
    >
      {children}
      <span className={styles.label}>{label}</span>
    </div>
  );
}

type ActiveTabHighlighterProps = {
  selectedTabNumber: number;
};
function ActiveTabHighlighter({
  selectedTabNumber,
}: ActiveTabHighlighterProps) {
  const style = { transform: `translateX(${selectedTabNumber * 100}%)` };
  return <div style={style} className={styles.highlighter}></div>;
}
