import { useEffect } from 'react';
import { useAuth } from '@features/auth/hooks/useAuth';
import { AuthService } from '@features/auth/services/AuthService';
import { usePrev } from '@libs/hooks/usePrev';
import {
  createFileRoute,
  Outlet,
  redirect,
  useRouter,
} from '@tanstack/react-router';

const LOGIN_URL = '/login' as const;

export const Route = createFileRoute('/_authenticated')({
  component: () => {
    return <Page />;
  },
  beforeLoad: async ({ location }) => {
    if (!AuthService.instance.isAuthenticated) {
      throw redirect({
        to: LOGIN_URL,
        search: {
          back: filterBackUrl(location.href),
        },
      });
    }
  },
});

function Page() {
  const router = useRouter();

  const { isAuthenticated } = useAuth();
  const prevIsAuthenticated = usePrev(isAuthenticated);

  // редирект на логин при логауте
  useEffect(() => {
    if (prevIsAuthenticated && !isAuthenticated) {
      const redirect = router.latestLocation.href;
      // ВАЖНО: делаем паузу, чтобы дочерние элементы имели возможность повлиять на навигацию (например, logout)
      const timeout = setTimeout(() => {
        router.navigate({
          to: LOGIN_URL,
          search:
            redirect === '/' || redirect === LOGIN_URL
              ? undefined
              : { back: redirect },
        });
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [prevIsAuthenticated, isAuthenticated, router]);

  return isAuthenticated ? <Outlet /> : null;
}

function filterBackUrl(url: string) {
  return url === '%2Fclub' ||
    url === '/club' ||
    url === '%2Flogin' ||
    url === '/login'
    ? undefined
    : url;
}
