import { createFileRoute, Navigate } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/_authenticated/club/$clubId_/tournament/',
)({
  component: Page,
});

function Page() {
  const { clubId } = Route.useParams();
  return <Navigate to="/club/$clubId" params={{ clubId }}></Navigate>;
}
