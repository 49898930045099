import React from 'react';

export interface IErrorsNotificationContext {
  error: Error | null;
  timestamp: number | null;
  report: (e?: Error) => void;
  reset: () => void;
}

export const ErrorsNotificationContext = React.createContext<
  IErrorsNotificationContext | undefined
>(undefined);
