import { ReactNode, useCallback, useState } from 'react';
import { cx } from '@libs/classnames';
import { useClickOutside } from '@libs/hooks/useClickOutside';
import styles from './BookmarkButton.module.css';

// FIXME: Separate into solid types (separate components?)
type ClickProps = {
  onClick?: () => void;
} & {
  clickedStateTop?: ReactNode;
  clickedStateBottom?: ReactNode;
};

export type Props = {
  icon: ReactNode;
  label: ReactNode;
  disabled?: boolean;
} & ClickProps;

export function BookmarkButton({
  icon,
  label,
  onClick,
  disabled,
  clickedStateTop,
  clickedStateBottom,
}: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const ref = useClickOutside<HTMLDivElement>(
    isClicked ? () => setIsClicked(false) : null,
  );

  const handleClick = useCallback(() => {
    if (onClick) return onClick();
    setIsClicked(true);
  }, [onClick, setIsClicked]);

  return (
    <div className={cx(styles.root, { [styles.disabled]: disabled })} ref={ref}>
      <button type="button" className={styles.button} onClick={handleClick}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
      </button>

      <div
        className={cx(styles.clickedState, {
          [styles.visible]: isClicked,
        })}
      >
        <div className={styles.clickedStateTop}>{clickedStateTop}</div>
        <hr className={styles.delimeter} />
        <div className={styles.clickedStateBottom}>{clickedStateBottom}</div>
      </div>
    </div>
  );
}
