import { CSSProperties, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './Table.module.css';

export type TableProps = {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
};
export function Table({ style, className, children }: TableProps) {
  return (
    <table style={style} className={cx(className, styles.table)}>
      <tbody>{children}</tbody>
    </table>
  );
}

export type TableRowProps = {
  className?: string;
  header?: boolean;
  group?: boolean;
  active?: boolean;
  hoverable?: boolean;
  smallText?: boolean;
  children: ReactNode;
};
export function TableRow({
  className,
  active,
  group,
  header,
  hoverable,
  smallText,
  children,
}: TableRowProps) {
  return (
    <tr
      className={cx(className, styles.tableRow, {
        [styles.header]: header,
        [styles.group]: group,
        [styles.active]: active,
        [styles.hoverable]: hoverable,
        [styles.smallText]: smallText,
      })}
    >
      {children}
    </tr>
  );
}

export type TableCellProps = {
  style?: CSSProperties;
  className?: string;
  colSpan?: number;
  right?: boolean;
  centered?: boolean;
  slim?: boolean;
  leftGap?: boolean;
  onClick?: () => void;
  smallText?: boolean;
  children?: ReactNode;
};
export function TableCell({
  style,
  right,
  centered,
  slim,
  leftGap,
  colSpan,
  className,
  onClick,
  smallText,
  children,
}: TableCellProps) {
  return (
    <td
      style={style}
      colSpan={colSpan}
      onClick={onClick}
      className={cx(className, styles.tableCell, {
        [styles.right]: right,
        [styles.centered]: centered,
        [styles.clickable]: !!onClick,
        [styles.slim]: slim,
        [styles.leftGap]: leftGap,
        [styles.smallText]: smallText,
      })}
    >
      {children}
    </td>
  );
}
