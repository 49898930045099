import { KeyboardEvent, ReactNode, memo, useCallback, useRef } from 'react';
import { cx } from '@libs/classnames';
import { useClickOutside } from '@libs/hooks/useClickOutside';
import { Button } from '@ui/components/Button';
import styles from './ConfirmationButton.module.css';

/*
    ____,-------------------------------,____
    \   |           Компонент           |   /
    /___|-------------------------------|___\
*/

export type Props = {
  className?: string;
  icon?: ReactNode;
  children: ReactNode;

  type?: 'submit' | 'button';
  disabled?: boolean;

  warning: string;
  confirm: string;
  armed?: boolean;

  onClick?: () => void;
  onConfirm?: () => void;
  onAbort?: () => void;
};

export const ConfirmationButton = memo(function ConfirmationButton({
  className,
  icon,
  children,
  type,
  disabled,
  armed: propsArmed,
  warning,
  confirm,
  onClick,
  onConfirm,
  onAbort,
}: Props) {
  const armed = propsArmed && !disabled;

  const ref = useClickOutside<HTMLDivElement>(armed ? onAbort : null);

  const refArmed = useRef(armed);
  const refChanged = useRef(false);
  if (armed !== refArmed.current) {
    refChanged.current = true;
    refArmed.current = armed;
  }

  const handleKeyboard = useCallback(
    (e: KeyboardEvent) => {
      if (armed && e.key === 'Escape') {
        onAbort?.();
      }
    },
    [onAbort, armed],
  );

  return (
    <div
      className={cx(className, styles.confirmBtn, {
        [styles.disabled]: disabled,
      })}
      ref={ref}
    >
      <div
        aria-hidden={!armed}
        className={cx({
          [styles.query]: true,
          [styles.armed]: armed,
        })}
      >
        <p>{warning}</p>
      </div>
      <Button
        className={cx({
          [styles.button]: true,
          [styles.armed]: armed,
          [styles.animOpen]: armed && refChanged.current,
          [styles.animClose]: !armed && refChanged.current,
        })}
        type={armed ? type : undefined}
        icon={armed ? undefined : icon}
        variant={armed ? 'condensed' : 'default'}
        disabled={disabled}
        onClick={armed ? onConfirm : onClick}
        onKeyDown={handleKeyboard}
      >
        {armed ? confirm : children}
      </Button>
    </div>
  );
});
