import {
  ReactNode,
  useRef,
  useState,
  MouseEvent,
  CSSProperties,
  memo,
  MutableRefObject,
} from 'react';
import { cx } from '@libs/classnames';
import { OBJECT_EMPTY } from '@libs/constants';
import styles from './AnimatedNode.module.css';

// FIXME: порефакторить, придумать крутые имена, сделать примеры, добавить настройки анимаций

export type Props = {
  tag?: keyof JSX.IntrinsicElements;
  // FIXME: придумать нормальные имена тут и в CSS
  animation?: 'opacity' | 'half-opacity' | 'scale' | 'none' | 'bounce-up';
  className?: string;
  persist?: boolean;
  initial?: boolean;
  children?: ReactNode;
  attributes?: object;
  style?: CSSProperties;
  nodeRef?: MutableRefObject<HTMLElement | null>;
  onClick?: (e: MouseEvent) => void;
};

export const AnimatedNode = memo(function AnimatedNode({
  tag,
  animation = 'opacity',
  className,
  persist,
  initial,
  children,
  attributes,
  style,
  nodeRef,
  onClick,
}: Props) {
  const disabled = animation === 'none';
  const prevChildren = useRef(initial ? undefined : children);
  const memoizedChildren = useRef(children);
  const animating = useRef(false);
  const [, forceUpdate] = useState(false);

  const hasChildren = children || children === 0;

  const enter = Boolean(!disabled && !prevChildren.current && hasChildren);
  const leave = Boolean(!disabled && prevChildren.current && !hasChildren);

  if (hasChildren) {
    memoizedChildren.current = children;
  }
  prevChildren.current = children;

  const HtmlTagname = tag ?? 'div';

  if (!hasChildren && !leave && !animating.current) {
    if (persist) {
      return (
        <HtmlTagname
          className={className}
          style={style}
          onClick={onClick}
          {...attributes}
        />
      );
    }
    return null;
  }

  const content = hasChildren ? children : memoizedChildren.current;
  const hidden = leave || (animating.current && !hasChildren);

  const handleAnimationStart = (e: React.AnimationEvent) => {
    if (e.animationName.includes('anim-')) {
      animating.current = true;
      e.target?.addEventListener('animationcancel', handleAnimationCancel);
    }
  };
  const handleAnimationEnd = (e: React.AnimationEvent) => {
    if (e.animationName.includes('anim-')) {
      animating.current = false;
      if (!hasChildren) {
        forceUpdate((v) => !v);
      }
    }
  };
  const handleAnimationCancel = (e: Event) => {
    handleAnimationEnd(e as unknown as React.AnimationEvent);
    e.target?.removeEventListener('animationcancel', handleAnimationCancel);
  };

  return (
    <HtmlTagname
      className={cx(className, {
        [styles.enter]: enter,
        [styles.leave]: leave,
      })}
      style={style}
      onClick={onClick}
      onAnimationStart={handleAnimationStart}
      onAnimationEnd={handleAnimationEnd}
      data-animation={animation}
      aria-hidden={hidden}
      {...({ ref: nodeRef } as object)}
      {...(hidden ? { inert: '' } : OBJECT_EMPTY)}
      {...attributes}
    >
      {content}
    </HtmlTagname>
  );
});

export type NodeInfo = {
  key: string | number;
} & Props;
export type NodesProps = {
  children?: Array<NodeInfo | null | undefined> | null | false;
};

export function AnimatedDynamicNodes({ children }: NodesProps) {
  const historyRef = useRef(children);

  const render = new Map<string | number, ReactNode>();

  if (children) {
    children.forEach((props) => {
      if (!props) return;
      render.set(props.key, <AnimatedNode {...props} key={props.key} />);
    });
  }

  if (historyRef.current) {
    historyRef.current.forEach((props) => {
      if (!props || render.has(props.key)) return;
      render.set(
        props.key,
        // eslint-disable-next-line react/no-children-prop
        <AnimatedNode {...props} key={props.key} children={null} />,
      );
    });
  }

  historyRef.current = children;

  return (
    <>
      {[...render.entries()].map(
        (e) => e[1] ?? <AnimatedNode key={e[0]}>{null}</AnimatedNode>,
      )}
    </>
  );
}
