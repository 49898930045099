import { useCallback, useEffect, useState } from 'react';
import { PromotionPiece } from '@libs/chess/types';
import { Subscription } from '@libs/subscription';

const LOCAL_STORAGE_KEY = 'autopromotion';

// FIXME: в будущем, сохранять в API, когда оно будет готово

const globalSubscription = new Subscription<PromotionPiece | undefined>();

export function useAutoPromotion() {
  const [autoPromotion, setAutoPromotion] = useState(() => {
    return localStorage.getItem(LOCAL_STORAGE_KEY) as
      | PromotionPiece
      | undefined;
  });

  // синхронизация инстансов
  useEffect(() => {
    return globalSubscription.subscribe((p) => {
      setAutoPromotion(p);
    });
  }, []);

  return {
    autoPromotion,
    setAutoPromotion: useCallback((p: PromotionPiece | null) => {
      const val = p ?? undefined;
      setAutoPromotion(val);
      globalSubscription.publish(val);

      if (p) {
        localStorage.setItem(LOCAL_STORAGE_KEY, p);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }, []),
  };
}
