import { useEffect, useRef, useState } from 'react';
import { PieceNameLower, SquareName } from '@libs/chess/types';
import { SocketInternalEvents } from '@libs/socket';
import { QueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { components, GameSocket, GET, POST, GameSocketEvents } from './clients';

/*
    ____,-------------------------------,____
    \   |             REST              |   /
    /___|-------------------------------|___\
*/

export function useGameQuery(id: string, { enabled } = { enabled: true }) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: ['/api/v1/games/{gameId}', id],
    queryFn: async () => {
      return (
        await GET('/api/v1/games/{gameId}', {
          params: { path: { gameId: id } },
        })
      ).data!;
    },
  });
}

export function updateGameQuery(
  client: QueryClient,
  id: string,
  data: components['schemas']['GameDto'],
) {
  client.setQueryData(['/api/v1/games/{gameId}', id], data);
}

export function useGameMove() {
  return useMutation({
    mutationFn: async ({
      id,
      move,
    }: {
      id: string;
      move: { from: SquareName; to: SquareName; promoteTo?: PieceNameLower };
    }) => {
      return (
        await POST('/api/v1/games/{gameId}/move', {
          params: { path: { gameId: id } },
          body: move,
        })
      ).data!;
    },
  });
}

export function useOfferDraw() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/request-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useAcceptDraw() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/accept-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useDeclineDraw() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/decline-draw', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useResign() {
  return useMutation({
    mutationFn: async (gameId: string) => {
      return (
        await POST('/api/v1/games/{gameId}/surrender', {
          params: { path: { gameId } },
        })
      ).data!;
    },
  });
}

export function useNewGame() {
  return useMutation({
    mutationFn: async () => {
      return (await POST('/api/v1/test/games')).data!;
    },
  });
}

export function useGameAccessKey(
  gameId: string,
  playerId: string,
  { enabled } = { enabled: true },
) {
  return useQuery({
    enabled,
    refetchOnWindowFocus: false,
    queryKey: ['/admin/game-access-tokens', gameId, playerId],
    queryFn: async () => {
      return (
        await POST('/api/v1/admin/game-access-tokens', {
          headers: { key: 'test' },
          body: { gameId, playerId },
        })
      ).data!;
    },
  });
}

/*
    ____,-------------------------------,____
    \   |             Socket            |   /
    /___|-------------------------------|___\
*/

export function useGameSocket({
  gameId,
  customSocketUrl,
  enabled = true,
  cb,
}: {
  gameId: string;
  customSocketUrl?: string;
  enabled?: boolean;
  cb: (e: GameSocketEvents | SocketInternalEvents) => void;
}) {
  const refCb = useRef(cb);
  refCb.current = cb;

  const createSocket = () => {
    if (!enabled) return null;

    const s = new GameSocket(gameId, customSocketUrl);
    s.subscribe((e) => {
      refCb.current(e);

      // отключить сокет, если игра закончена
      if (
        e.eventType === 'GameChanged' &&
        e.currentState.status === 'Finished'
      ) {
        s.discard();
      }
    });

    return s;
  };

  const [socket, setSocket] = useState(createSocket);
  if (enabled && (!socket || socket?.gameId !== gameId)) {
    socket?.discard();
    setSocket(createSocket());
  } else if (!enabled && socket) {
    socket.discard();
    setSocket(null);
  }

  useEffect(
    () => () => {
      if (socket) socket.discard();
    },
    [socket],
  );
}
