import { useMemo } from 'react';
import { useCountdownUntill } from '@libs/hooks/useCountdownUntill';
import { pluralize } from '@libs/i18n';
import { Button } from '@ui/components/Button';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';
import styles from './GameConflictModal.module.css';

export type Props = {
  className?: string;
  startTimeMs: number;
  tournament: string;
  _paused?: boolean; // для storybook
  onIgnore?: () => void;
  onOpen?: () => void;
};

export function GameConflictModal({
  className,
  startTimeMs,
  tournament,
  _paused = false,
  onIgnore,
  onOpen,
}: Props) {
  const { seconds } = useCountdownUntill(startTimeMs, _paused);
  const minutes = Math.round(seconds / 60);
  const minutesWords = useMemo(
    () =>
      pluralize(
        {
          many: '{number} минут',
          few: '{number} минуты',
          one: '{number} минута',
          zero: '{number} минут',
        },
        minutes,
      ),
    [minutes],
  );

  return (
    <GoldenModal className={className}>
      <h1>Начинается ваша партия в другом турнире!</h1>
      {seconds > 60 && (
        <p>
          Через {minutesWords} начнется партия в турнире <b>{tournament}</b>.
        </p>
      )}
      {seconds <= 60 && seconds > 0 && (
        <p>
          Уже через несколько секунд начнется партия в турнире{' '}
          <b>{tournament}</b>.
        </p>
      )}

      {seconds === 0 && (
        <p>
          Ваша партия в турнире <b>{tournament}</b> уже началась!
        </p>
      )}
      <p>
        Вы можете открыть ее в новом окне для параллельной игры или
        проигнорировать партию, это приведет к выбыванию из турнира.
      </p>
      <div className={styles.buttons}>
        <Button onClick={onIgnore}>Игнорировать</Button>
        <Button onClick={onOpen}>Открыть в новом окне</Button>
      </div>
    </GoldenModal>
  );
}
