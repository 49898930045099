import { useCallback, useEffect, useState } from 'react';
import { usePrev } from '@libs/hooks/usePrev';
import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useRouter } from '@tanstack/react-router';
import { AuthService } from '../../services/AuthService';
import { AuthContext, IAuthContext } from './AuthContext';

export function AuthProvider({ children }: { children: ReactNode }) {
  const auth = AuthService.instance;
  const [, setVer] = useState(0);
  const prevAuthenticated = usePrev(auth.isAuthenticated);
  const ql = useQueryClient();
  const router = useRouter();

  useEffect(() => {
    // Инвалидация кэша при разлогине. При логине кэш инвалидирует useLogin
    if (!auth.isAuthenticated && prevAuthenticated) {
      ql.invalidateQueries();
    }

    // Триггерим обновление контекста
    return auth.subscribe(() => setVer((v) => v + 1));
  }, [auth, prevAuthenticated, ql]);

  const value: IAuthContext = {
    isAuthenticated: auth.isAuthenticated,
    user: auth.user,

    logout: useCallback(() => {
      auth.deleteTokens();
      router.navigate({ to: '/' });
    }, [auth, router]),

    login: useCallback(() => {
      router.navigate({
        to: '/login',
        search: { back: router.latestLocation.href },
      });
    }, [router]),
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
