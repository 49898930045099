import { cx } from '@libs/classnames';
import styles from './Avatar.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  type: 'text' | 'image';
  content: string;
};
export function Avatar({ className, onClick, type, content }: Props) {
  return (
    <div onClick={onClick} className={cx(className, styles.root)}>
      <div className={styles.contentWrapper}>
        {type === 'image' && (
          <img className={styles.image} src={content} alt="Avatar" />
        )}

        {type === 'text' && <span className={styles.text}>{content}</span>}
      </div>
    </div>
  );
}
