import { LegacyRef, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { useSticky } from '@libs/hooks/useSticky';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { PhoenixSmallIcon } from '@ui/icons/PhoenixSmallIcon/PhoenixSmallIcon';
import styles from './TournamentHeader.module.css';

// FIXME: переименовать в DefaultHeader или SiteHeader

export type Props = {
  className?: string;
  title?: ReactNode;
  headerButtons?: ReactNode;
  user?: ReactNode;
  onHome?: () => void;
};

export function TournamentHeader({
  className,
  title,
  headerButtons,
  user,
  onHome,
}: Props) {
  const { ref, isSticky } = useSticky();
  const device = useMediaQuery();

  return (
    <div
      ref={ref as LegacyRef<HTMLDivElement>}
      className={cx(className, styles.header, { [styles.sticky]: isSticky })}
    >
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <div className={styles.clickable} onClick={onHome}>
            <PhoenixSmallIcon className={styles.logo} />
          </div>
          <div className={styles.lineClamp}>
            <div className={styles.clickable} onClick={onHome}>
              Турниры {!title ? null : <> · {title}</>}
            </div>
          </div>
        </div>
        <div className={styles.rightSide}>
          {device !== 'mobile' && headerButtons}
          {user}
        </div>
      </div>
    </div>
  );
}
