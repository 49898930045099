import { useCallback, useEffect, useState } from 'react';
import { ReactNode } from '@tanstack/react-router';
import { Theme, ThemeContext } from './ThemeContext';

const LOCAL_STORAGE_KEY = 'theme';

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState(() => {
    return (
      (localStorage.getItem(LOCAL_STORAGE_KEY) as Theme | undefined) ?? 'light'
    );
  });

  const value = {
    theme,
    selectTheme: useCallback((t: Theme) => {
      localStorage.setItem(LOCAL_STORAGE_KEY, t);
      setTheme(t);
    }, []),
  };

  useEffect(() => {
    const isDark = document.documentElement.classList.contains('theme-dark');

    if ((theme === 'dark' && isDark) || (theme === 'light' && !isDark)) return;

    // выключить анимации цветов при переключении темы
    let switching = true;
    document.documentElement.classList.add('switching-theme');

    const raf = requestAnimationFrame(() => {
      switching = false;
      document.documentElement.classList.remove('switching-theme');
    });

    if (theme === 'dark') {
      document.documentElement.classList.add('theme-dark');
    }

    if (theme === 'light') {
      document.documentElement.classList.remove('theme-dark');
    }

    return () => {
      if (switching) {
        document.documentElement.classList.remove('switching-theme');
        cancelAnimationFrame(raf);
      }
    };
  }, [theme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}
