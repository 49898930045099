import { useCallback } from 'react';
import { z } from 'zod';
import { useClubRoles } from '@features/auth/hooks/useClubRoles';
import { TournamentLayoutWidget } from '@features/shared/ui/TournamentLayout/TournamentLayoutWidget';
import {
  TournamentEditPage,
  TournamentEditPageSkeleton,
} from '@features/tournament/pages/TournamentEditPage';
import { useTournamentEdit } from '@features/tournament/pages/TournamentEditPage/useTournamentEdit';
import { TournamentParticipantsEditWidget } from '@features/tournament/ui/components/TournamentParticipantsEdit';
import { createFileRoute, notFound, useNavigate } from '@tanstack/react-router';

const querySchema = z.object({
  pageTab: z.enum(['settings', 'participants']).catch('settings').optional(),
  participantsTab: z
    .enum(['Invited', 'Participating', 'Exited', 'Banned'])
    .catch('Participating')
    .optional(),
});
type Query = z.infer<typeof querySchema>;

export const Route = createFileRoute(
  '/_authenticated/club/$clubId_/tournament/$tournamentId_/edit',
)({
  component: Page,
  validateSearch: querySchema,
});

function Page() {
  const { clubId, tournamentId } = Route.useParams();
  const { pageTab = 'settings', participantsTab = 'Participating' } =
    Route.useSearch();

  const navigate = useNavigate({ from: Route.fullPath });

  const handlePageTabChange = useCallback(
    (newTab: Query['pageTab']) => {
      const newParticipantsTab =
        newTab === 'settings' ? undefined : 'Participating';
      navigate({
        search: (prev) => ({
          ...prev,
          pageTab: newTab,
          participantsTab: newParticipantsTab,
        }),
        ignoreBlocker: true,
      });
    },
    [navigate],
  );
  const handleParticipantsTabChange = useCallback(
    (newTab: Query['participantsTab']) =>
      navigate({
        search: (prev) => ({ ...prev, participantsTab: newTab }),
        ignoreBlocker: true,
      }),
    [navigate],
  );

  const props = useTournamentEdit({
    clubId,
    tournamentId,
    pageTab,
    onPageTabChange: handlePageTabChange,
  });

  const { isManager } = useClubRoles(clubId);
  if (!isManager) {
    throw notFound();
  }

  return (
    <TournamentLayoutWidget clubId={clubId} hideCreateTournament>
      {!props && <TournamentEditPageSkeleton />}
      {props && (
        <TournamentEditPage
          {...props}
          enableUnsavedWarning
          participants={(key, isTeam, clock) => (
            <TournamentParticipantsEditWidget
              key={key}
              tournament={props.tournament}
              disabled={props.disabled}
              isTeam={isTeam}
              clockControl={clock}
              tab={participantsTab}
              onTab={handleParticipantsTabChange}
              onTournamentRefetch={props.refetchTournament}
            />
          )}
        />
      )}
    </TournamentLayoutWidget>
  );
}
