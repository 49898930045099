import { Subscription } from '@libs/subscription';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { FUNC_NOOP } from './constants';

const SOCKET_TINMEOUT_MS = 6 * 1000;
const SOCKET_PING_INTERVAL = 3 * 1000;

// lifecycle сокета
export type SocketInternalEvents =
  | { eventType: 'SocketReconnecting' }
  | { eventType: 'SocketReconnected' }
  | { eventType: 'SocketConnected' }
  | { eventType: 'SocketClosed' };

export class SignalRSocket<T> {
  connection: HubConnection;
  discarded = false;
  #subscription = new Subscription<T | SocketInternalEvents>();

  protected constructor(
    public url: string,
    eventTypes: string[],
  ) {
    this.connection = new HubConnectionBuilder()
      .withUrl(url, {
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
      })
      .configureLogging(LogLevel.Error)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (e) =>
          this.#calcRetryTimeout(e.previousRetryCount),
      })
      .build();

    this.connection.serverTimeoutInMilliseconds = SOCKET_TINMEOUT_MS;
    this.connection.keepAliveIntervalInMilliseconds = SOCKET_PING_INTERVAL;

    eventTypes.forEach((type) => {
      this.connection.on(type, (e: T) => {
        this.#subscription.publish(e);
      });
    });

    this.connection.onreconnecting(() => {
      this.#subscription.publish({ eventType: 'SocketReconnecting' });
    });

    this.connection.onreconnected(() => {
      this.#subscription.publish({ eventType: 'SocketReconnected' });
    });

    this.connection.onclose(() => {
      this.#subscription.publish({ eventType: 'SocketClosed' });
    });

    this.#start();
  }

  get subscribers() {
    return this.#subscription.size;
  }

  subscribe(cb: (e: T | SocketInternalEvents) => void): () => void {
    if (this.discarded) return FUNC_NOOP;

    this.#subscription.subscribe(cb);
    return () => this.#subscription.unsubscribe(cb);
  }

  discard() {
    if (this.discarded) return;

    this.discarded = true;
    this.connection.stop();
    this.#subscription.clear();
  }

  #start(retry = 0) {
    if (this.discarded) return;

    this.connection.start().then(
      () => {
        this.#subscription.publish(
          retry
            ? { eventType: 'SocketReconnected' }
            : { eventType: 'SocketConnected' },
        );
      },
      () => {
        if (retry === 0) {
          this.#subscription.publish({ eventType: 'SocketReconnecting' });
        }

        window.setTimeout(() => {
          this.#start(retry + 1);
        }, this.#calcRetryTimeout(retry));
      },
    );
  }

  #calcRetryTimeout(retry: number) {
    switch (retry) {
      case 0:
        return 0.5 * 1000;
      case 1:
        return 1 * 1000;
      case 2:
        return 3 * 1000;
      default:
        return 5 * 1000;
    }
  }
}
