import { hexToRgb, hsvToHex, hsvToRgb, nameToHex, rgbToHsv } from '@libs/color';

/*
  Given: color0

  color0 -> color1
    H +3
    S +24
    V +24

  color1 -> color2
    H +3
    S -30
    V +24

  color2 -> color3
    H +3
    S -30
    V +24

  Если после операции упираемся в лимит пространства HSV, то просто оставляем
  значение на максимуме.
*/

export function applyClubColor(color: string) {
  const color0 = rgbToHsv(
    ...hexToRgb(color.startsWith('#') ? color : nameToHex(color)),
  );

  const color1 = addHsv(color0, [3, 24, 24]);
  const color2 = addHsv(color1, [3, -30, 24]);
  const color3 = addHsv(color2, [3, -30, 24]);

  const { style } = document.documentElement;

  style.setProperty('--club-background-color', hsvToHex(...color0));
  style.setProperty(
    '--club-background-color-components',
    hsvToRgb(...color0).join(','),
  );

  style.setProperty('--club-primary-color', hsvToHex(...color1));
  style.setProperty(
    '--club-primary-color-components',
    hsvToRgb(...color1).join(','),
  );

  style.setProperty('--club-secondary-color', hsvToHex(...color2));
  style.setProperty(
    '--club-secondary-color-components',
    hsvToRgb(...color2).join(','),
  );

  style.setProperty('--club-tertiary-color', hsvToHex(...color3));
  style.setProperty(
    '--club-tertiary-color-components',
    hsvToRgb(...color3).join(','),
  );
}

export function removeClubColor() {
  const { style } = document.documentElement;

  style.removeProperty('--club-background-color');
  style.removeProperty('--club-background-color-components');

  style.removeProperty('--club-primary-color');
  style.removeProperty('--club-primary-color-components');

  style.removeProperty('--club-secondary-color');
  style.removeProperty('--club-secondary-color-components');

  style.removeProperty('--club-tertiary-color');
  style.removeProperty('--club-tertiary-color-components');
}

function addHsv(
  hsv: [number, number, number],
  delta: [number, number, number],
): [number, number, number] {
  return [
    Math.min(360, hsv[0] + delta[0]),
    Math.min(100, hsv[1] + delta[1]),
    Math.min(100, hsv[2] + delta[2]),
  ];
}
