import { components } from '@features/shared/api/club/generated';
import { useForceUpdate } from '@libs/hooks/useForceUpdate';
import { useTimeoutUntil } from '@libs/hooks/useTimeoutUntil';
import { TOURNAMENT_MINIMUM_TIME_BEFORE_START } from '../constants';

export function useTournamentStates(
  tournament: {
    status: components['schemas']['TournamentStatus'];
    startTimestampMs?: number | null;
  } | null,
) {
  const status = tournament?.status;
  const startTimestampMs = tournament?.startTimestampMs;

  const hasStartTime =
    startTimestampMs !== null && startTimestampMs !== undefined;

  // За X минут до начала турнир становится нередактируемым
  const lockTimestamp = hasStartTime
    ? startTimestampMs - TOURNAMENT_MINIMUM_TIME_BEFORE_START
    : null;

  useTimeoutUntil(useForceUpdate(), hasStartTime ? lockTimestamp : null);

  const isLockTime = lockTimestamp && Date.now() >= lockTimestamp;
  const isPublishable = hasStartTime && status === 'Draft' && !isLockTime;
  const isEditable =
    status === 'Draft' || (status === 'NotStarted' && !isLockTime);

  return {
    isLockTime,
    isEditable,
    isPublishable,
  };
}
