import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './HollowButton.module.css';

export type Props = {
  className?: string;
  onClick?: () => void;
  empty?: boolean;
  disabled?: boolean;
  children?: ReactNode;
};
export function HollowButton({
  className,
  onClick,
  disabled,
  empty,
  children,
}: Props) {
  return (
    <button
      onClick={onClick}
      className={cx(className, styles.button, {
        [styles.empty]: empty,
        [styles.disabled]: disabled,
      })}
      type="button"
    >
      {children}
    </button>
  );
}
