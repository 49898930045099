import { EventEntrance } from 'features/shared/ui/EventEntrance';
import { TournamentSubHeader } from 'features/shared/ui/TournamentSubHeader';
import { TournamentSystemView } from 'features/shared/ui/TournamentSystemView';
import { Fragment, ReactNode, useState } from 'react';
import { components } from '@features/shared/api/club/clients';
import { TournamentStatusView } from '@features/tournament/ui/components/GameEventStatus';
import { TournamentInfo } from '@features/tournament/ui/components/TournamentInfo';
import { isTournamentStarted } from '@features/tournament/utils/tournamentHelpers';
import { AnimatedNode } from '@ui/components/Animation';
import { BookmarkButton } from '@ui/components/BookmarkButton';
import { BookmarkButtonBar } from '@ui/components/BookmarkButtonBar';
import { HollowButton } from '@ui/components/HollowButton';
import { Loader } from '@ui/components/Loader';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Pagination } from '@ui/components/Pagination/Pagination';
import { Tabs } from '@ui/components/Tabs';
import { EditIcon } from '@ui/icons/EditIcon';
import { StartIcon } from '@ui/icons/StartIcon';
import styles from './TournamentCardPage.module.css';

const RESULTS_TABS = [
  { value: 'table' as const, label: 'Турнирная таблица' },
  { value: 'rounds' as const, label: 'Движение по турам' },
];

export type Props = {
  isPending: boolean;
  club?: {
    logoUrl?: string | null;
  };
  tournament?: {
    title: string;
    logoUrl?: string | null;
    mode: components['schemas']['TournamentMode'];
    entrance: components['schemas']['TournamentEntrance'];
    system: components['schemas']['TournamentSystem'];
    clockControl?: components['schemas']['ClockControlType'];
    clockControlSettings?: components['schemas']['ClockControlSettings'];
    startTimestampMs?: number | null;
    status: components['schemas']['TournamentStatus'];
    pausedTillTimestampMs?: number | null;
    rounds: components['schemas']['RoundDto'][];
    teams?: components['schemas']['TeamDto'][];
    participantsCount: number;
    rulesUrl?: string | null;
    contacts?: components['schemas']['Contact'][];
    additionalScoreOrder: components['schemas']['TournamentAdditionalScore'][];
    participationStatus: components['schemas']['ParticipantStatus'];
  };
  isManager: boolean;
  isEditable: boolean;
  isPublishable: boolean;
  isPublishing: boolean;
  noPairModal?: ReactNode;
  participants?: (className: string | undefined) => ReactNode;
  resultsTab?: 'table' | 'rounds';
  onResultsTabChange?: (resultsTab: 'table' | 'rounds') => void;
  position?: ReactNode;
  roundMovement?: (roundNumber: number) => ReactNode;
  games?: ReactNode;
  onEdit: () => void;
  onPublish: () => void;
  onDownloadXlsResults: () => void;
  isActionPending: boolean;
  onAccept: () => void;
  onLeave: () => void;
  // onJoin: () => void;
};

export function TournamentCardPage({
  club,
  isPending,
  tournament,
  isManager,
  noPairModal,
  isEditable,
  isPublishable,
  isPublishing,
  participants,
  resultsTab,
  onResultsTabChange = () => {},
  position,
  roundMovement,
  games,
  onEdit,
  onPublish,
  onDownloadXlsResults,
  isActionPending,
  onAccept,
  onLeave,
  // onJoin,
}: Props) {
  const activeRound =
    tournament?.rounds.find((r) => r.status === 'InProgress')?.roundNumber ||
    tournament?.rounds.at(-1)?.roundNumber ||
    1;
  const [roundNumber, setRoundNumber] = useState(activeRound);

  const isMatch = tournament?.system === 'OneOnOne';

  const participationButton = (() => {
    if (!tournament || tournament.status !== 'NotStarted' || !isEditable)
      return null;

    if (tournament.participationStatus === 'Invited') {
      return (
        <HollowButton onClick={onAccept} disabled={isActionPending}>
          Принять участие
        </HollowButton>
      );
    }

    if (tournament.participationStatus === 'Participating') {
      return (
        <HollowButton onClick={onLeave} empty disabled={isActionPending}>
          Выйти из {isMatch ? 'матча' : 'турнира'}
        </HollowButton>
      );
    }

    /*if (
        tournament.entrance === 'Open' &&
        !tournament.participationStatus
      ) {
        return  (
          <HollowButton onClick={onJoin} disabled={isActionPending}>
            Принять участие
          </HollowButton>
        );
      }
    }*/

    return null;
  })();

  const logo = tournament?.logoUrl ?? club?.logoUrl;
  const tournamentStarted =
    tournament && isTournamentStarted(tournament.status);
  const isTournamentStartTimeReached =
    tournament?.startTimestampMs &&
    new Date() >= new Date(tournament.startTimestampMs);

  const showResults =
    (tournamentStarted && isTournamentStartTimeReached) || false;

  return (
    <>
      <TournamentSubHeader
        title={tournament?.title || 'Без названия'}
        description={
          <>
            <TournamentSystemView tournament={tournament} />
            &nbsp;·&nbsp;
            <EventEntrance tournament={tournament} />
            <br />
            <TournamentStatusView
              className={styles.eventStatus}
              tournament={tournament}
            />
          </>
        }
        logo={logo ? <img src={logo} /> : undefined}
        info={
          <TournamentInfo
            tournament={tournament}
            participationButton={participationButton}
            onDownloadXlsResults={onDownloadXlsResults}
          />
        }
      />

      {isPending && <Loader className={styles.loader} centered />}

      {!isPending && (
        <AnimatedNode className={styles.nopair}>{noPairModal}</AnimatedNode>
      )}

      {!isPending && !showResults && participants?.(styles.participants)}

      {showResults && (
        <div className={styles.results}>
          <div className={styles.participantsTitle}>
            <PageSubTitle>Участники</PageSubTitle>
            <Tabs
              tabClassName={styles.participantsTab}
              tabs={RESULTS_TABS}
              onChange={onResultsTabChange}
              value={resultsTab}
            />
          </div>
          {resultsTab !== 'rounds' ? (
            <Fragment key="table">{position}</Fragment>
          ) : (
            <Fragment key="rounds">
              <div className={styles.rounds}>
                Тур
                <Pagination
                  pageTotal={tournament?.rounds.length || 1}
                  pageNum={roundNumber}
                  onPage={setRoundNumber}
                />
              </div>
              {roundMovement?.(roundNumber)}
            </Fragment>
          )}
        </div>
      )}

      {games}

      {isManager && (
        <BookmarkButtonBar>
          <BookmarkButton
            icon={<EditIcon />}
            label="Редактировать"
            onClick={onEdit}
            disabled={!isEditable || isPublishing}
          />
          {tournament?.status === 'Draft' && (
            <BookmarkButton
              icon={<StartIcon />}
              label="Опубликовать"
              onClick={onPublish}
              disabled={!isPublishable || isPublishing}
            />
          )}
        </BookmarkButtonBar>
      )}
    </>
  );
}
