import { Chess } from 'chess.js';
import { ChessBoardProps } from 'exports/ChessBoard';
import { MoveInfo, useChessGame } from 'exports/ChessBoard/hooks/useChessGame';
import { useCallback, useRef, useState } from 'react';
import { PromotionPiece, SquareName } from '@libs/chess/types';
import { useImmediateEffect } from '@libs/hooks/useImmediateEffect';
import { usePrev } from '@libs/hooks/usePrev';
import { useQueryClient } from '@tanstack/react-query';
import {
  components,
  SESSION_GAME_ACCESS_KEY,
  updateGameQuery,
  useAcceptDraw,
  useDeclineDraw,
  useGameMove,
  useGameQuery,
  useGameSocket,
  useOfferDraw,
  useResign,
} from '../api';

/*
    ____,-------------------------------,____
    \   |              Hook             |   /
    /___|-------------------------------|___\
*/

const MOVE_REQUEST_DEADLINE = 5 * 1000;

export function useGameServer({
  gameId,
  playerId: probablePlayerId,
  accessKey,
  customSocketUrl,
  autoPromotion,
  enabled,
  onError,
}: {
  gameId: string;
  playerId?: string | null;
  accessKey?: string;
  customSocketUrl?: string;
  autoPromotion?: PromotionPiece;
  enabled?: boolean;
  onError?: (error: Error) => void;
}) {
  // ------------------------------------------
  // Get Game Query

  const queryClient = useQueryClient();

  const {
    data: gameData,
    isPending: gamePending,
    error: gameError,
    refetch: refetchGame,
  } = useGameQuery(gameId, {
    // ВАЖНО: реальные данные получаются из сокета, который и управляет кэшем
    //        useGameQuery
    enabled: false,
  });

  // ------------------------------------------
  // Credentials

  const playerId = probablePlayerId ?? gameData?.white.id;
  const isPlayer = Boolean(accessKey);

  useImmediateEffect(() => {
    if (accessKey) {
      sessionStorage.setItem(SESSION_GAME_ACCESS_KEY, accessKey);
    }
  }, [accessKey]);

  // ------------------------------------------
  // Move

  const [moveProblems, setMoveProblems] = useState(false);
  const moveMutation = useGameMove();
  const handleDataRef = useRef(handleData); // HACK
  const handleMove = useCallback(
    (move: MoveInfo) => {
      const deadlineTimeout = setTimeout(() => {
        setMoveProblems(true);
      }, MOVE_REQUEST_DEADLINE);

      moveMutation.mutate(
        {
          id: gameId,
          move: {
            from: move.from,
            to: move.to,
            promoteTo: move.promotion,
          },
        },
        {
          onError(error) {
            onError?.(error);
            // откатить локальный ход на последнее состояние сервера
            if (gameData) handleDataRef.current(gameData);
            // фоном подгрузить новое состояние
            refetchGame();
          },
          onSettled() {
            clearTimeout(deadlineTimeout);
            setMoveProblems(false);
          },
        },
      );
    },
    [gameData, gameId, moveMutation, onError, refetchGame],
  );

  // ------------------------------------------
  // Premove

  const [premove, setPremove] = useState<MoveInfo | null>(null);
  const handlePremove = useCallback((m: MoveInfo | null) => {
    setPremove(m);
  }, []);

  // ------------------------------------------
  // Controller

  const side = getSide(playerId, gameData);
  const player = side === 'none' ? undefined : gameData?.[side];
  const { props, move, reset } = useChessGame({
    side: isPlayer && gameData?.result === 'InProgress' ? side : 'none',
    initialFen: gameData?.currentFen,
    allowPremove: true,
    autoPromotion,
    onMove: handleMove,
    onPremove: handlePremove,
  });

  // ------------------------------------------
  // Draw

  const [handledOffer, setHandledOffer] = useState(gameData?.activeDrawRequest);

  const offerDrawMutation = useOfferDraw();
  const handleOfferDraw = useCallback(() => {
    offerDrawMutation.mutate(gameId, { onError });
    setHandledOffer(gameData?.activeDrawRequest);
  }, [gameData?.activeDrawRequest, gameId, offerDrawMutation, onError]);

  const acceptDrawMutation = useAcceptDraw();
  const handleAcceptDraw = useCallback(() => {
    acceptDrawMutation.mutate(gameId, { onError });
    setHandledOffer(gameData?.activeDrawRequest);
  }, [acceptDrawMutation, gameId, gameData?.activeDrawRequest, onError]);

  const declineDrawMutation = useDeclineDraw();
  const handleDeclineDraw = useCallback(
    () => declineDrawMutation.mutate(gameId),
    [gameId, declineDrawMutation],
  );

  const showDrawOffer = Boolean(
    gameData &&
      gameData.activeDrawRequest &&
      gameData.activeDrawRequest !== handledOffer &&
      gameData.activeDrawRequest.triggeredBySide.toLocaleLowerCase() !== side,
  );

  // ------------------------------------------
  // Resign

  const resignMutation = useResign();
  const handleResign = useCallback(() => {
    resignMutation.mutate(gameId, { onError });
  }, [gameId, onError, resignMutation]);

  // ------------------------------------------
  // Handle New Game State

  function handleData(d: components['schemas']['GameDto']) {
    const lastMove = d.moves[d.moves.length - 1];
    if (lastMove) {
      const { from, to, fenBefore, fenAfter, promotedTo } =
        d.moves[d.moves.length - 1]!;
      move({
        from: from as SquareName,
        to: to as SquareName,
        fen: fenAfter,
        prevFen: fenBefore,
        promotion: (promotedTo as PromotionPiece) ?? undefined,
      });
      if (premove) {
        // FIXME: перенести реализацию полностью на backend
        try {
          // проверяем валидность хода
          new Chess(fenAfter).move(premove);
          handleMove(premove);
        } catch {
          // do nothing
        } finally {
          setPremove(null);
        }
      }
    } else {
      reset(d.currentFen);
    }
  }

  const prevData = usePrev(gameData);
  if (gameData && gameData !== prevData) {
    handleData(gameData);
  }

  // ------------------------------------------
  // Socket

  const [socketPromlems, setSocketProblems] = useState(false);
  useGameSocket({
    gameId,
    customSocketUrl,
    enabled,
    cb: (e) => {
      switch (e.eventType) {
        case 'GameChanged':
        case 'Surrender':
        case 'DrawRequest':
        case 'DrawAccept':
        case 'DrawDecline':
          updateGameQuery(queryClient, e.gameId, e.currentState);
          break;
        case 'SocketReconnecting':
          setSocketProblems(true);
          break;
        case 'SocketReconnected':
          setSocketProblems(false);
          break;
      }
    },
  });

  // ------------------------------------------
  // Return

  const orientation: 'black' | 'white' = side === 'black' ? 'black' : 'white';
  const position = gameData ? props.position : undefined;
  const interactive =
    isPlayer && gameData?.result === 'InProgress' ? props.interactive : 'none';
  const winner = getWinner(gameData) ?? props.winner;

  return {
    error: gameError,
    loading: gamePending,
    networkPromlems: socketPromlems || moveProblems,
    //
    propsBoard: {
      ...props,
      orientation,
      interactive,
      position,
      winner,
    },
    //
    game: gameData,
    player,
    //
    showDrawOffer,
    handleOfferDraw,
    handleAcceptDraw,
    handleDeclineDraw,
    //
    handleResign,
  };
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function getSide(
  playerId?: string,
  data?: components['schemas']['GameDto'],
): 'none' | 'black' | 'white' {
  if (playerId === data?.white.id) return 'white';
  if (playerId === data?.black.id) return 'black';
  return 'none';
}

function getWinner(
  data?: components['schemas']['GameDto'],
): ChessBoardProps['winner'] {
  if (data?.result === 'BlackWin') return 'black';
  if (data?.result === 'WhiteWin') return 'white';
  if (data?.result === 'Draw') return 'draw';
  return undefined;
}
