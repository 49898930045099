import { ChangeEvent, ReactNode, useCallback, useState } from 'react';
import { cx } from '@libs/classnames';
import { ArrowIcon } from '@ui/icons/ArrowIcon/ArrowIcon';
import styles from './Dropdown.module.css';

export type Props = {
  className?: string;
  options: {
    value: string | boolean | number;
    label: ReactNode;
    disabled?: boolean;
  }[];
  onChange: (newValue: string) => void;
  value?: string;
  disabled?: boolean;
};
export function Dropdown({
  className,
  options,
  onChange,
  value,
  disabled,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const handleOnFocus = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);
  const handleOnBlur = useCallback(() => {
    setIsFocused(false);
  }, [setIsFocused]);

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      onChange(evt.target.value);
    },
    [onChange],
  );

  return (
    <div
      className={cx(className, styles.root, {
        [styles.focus]: isFocused,
        [styles.disabled]: disabled,
      })}
    >
      <select
        value={value}
        disabled={disabled}
        className={styles.dropdown}
        onChange={handleChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      >
        {options.map((o) => (
          <option
            key={o.value.toString()}
            value={o.value.toString()}
            disabled={o.disabled}
          >
            {o.label}
          </option>
        ))}
      </select>
      <div className={styles.pickerButton}>
        <ArrowIcon />
      </div>
    </div>
  );
}
