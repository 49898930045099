import React from 'react';
import { AuthService } from '../../services/AuthService';

export interface IAuthContext {
  isAuthenticated: boolean;
  user?: AuthService['user'];

  login: () => void;
  logout: () => void;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined,
);
