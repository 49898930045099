import { ClubCardPage } from '@features/club/pages/ClubCardPage';
import { useClubCard } from '@features/club/pages/ClubCardPage/useClubCard';
import { ClubCardParticipantsWidget } from '@features/club/ui/ClubCardParticipants';
import { ClubEventListWidget } from '@features/club/ui/ClubEventList';
import { TournamentLayoutWidget } from '@features/shared/ui/TournamentLayout/TournamentLayoutWidget';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/club/$clubId')({
  component: Page,
});

function Page() {
  const { clubId } = Route.useParams();
  const props = useClubCard({ clubId });
  return (
    <TournamentLayoutWidget clubId={clubId}>
      <ClubCardPage
        {...props}
        events={(className) => (
          <ClubEventListWidget className={className} clubId={clubId} />
        )}
        members={(className) => (
          <ClubCardParticipantsWidget className={className} clubId={clubId} />
        )}
      />
    </TournamentLayoutWidget>
  );
}
