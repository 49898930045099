import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FileUploadInput } from '@ui/components/FileUploadInput';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';

export function LogoFileInput({
  onUpload,
}: {
  onUpload: (file: FormData) => Promise<void>;
}) {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel hint="не обязательно">Логотип</FormLabel>
      <FormInput>
        <Controller
          name="logoUrl"
          control={control}
          render={({ field: { value, disabled } }) => (
            <FileUploadInput
              description="PNG, до 2 МБ"
              value={value}
              onChange={onUpload}
              disabled={disabled}
            />
          )}
        />
      </FormInput>
    </FormRow>
  );
}
