import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './BookmarkButtonBar.module.css';

export type Props = {
  className?: string;
  children: ReactNode;
};

export function BookmarkButtonBar({ className, children }: Props) {
  return (
    <div className={cx(className, styles.bookmarkButtonBar)}>{children}</div>
  );
}
