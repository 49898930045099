import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './TextView.module.css';

export type Props = {
  className?: string;
  children: ReactNode;
};
export function TextView({ className, children }: Props) {
  return <span className={cx(className, styles.text)}>{children}</span>;
}
