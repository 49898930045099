import { useAuth } from '@features/auth/hooks/useAuth';
import { useUserSettingsMenu } from '../../hooks/useUserSettingsMenu';
import { User, UserProps } from '.';

/*
    ____,-------------------------------,____
    \   |            Виджет             |   /
    /___|-------------------------------|___\
*/

export function UserWidget() {
  const settings = useUserSettingsMenu();
  const userProps = useUser();
  return <User {...userProps} settings={settings} />;
}

/*
    ____,-------------------------------,____
    \   |            Запчасти           |   /
    /___|-------------------------------|___\
*/

function useUser(): UserProps {
  const { user, login, logout } = useAuth();

  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    onLogin: login,
    onLogout: logout,
  };
}
