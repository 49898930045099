import { TournamentSystemView } from 'features/shared/ui/TournamentSystemView';
import { useCallback } from 'react';
import {
  ClockControlSettings,
  ClockControlType,
  ParticipantStatus,
  TournamentEntrance,
  TournamentMode,
  TournamentStatus,
  TournamentSystem,
} from '@features/shared/api/typings';
import { EventEntrance } from '@features/shared/ui/EventEntrance';
import { Badge } from '@ui/components/Badge';
import { Card } from '@ui/components/Card';
import { DateView } from '@ui/components/DateView';
import styles from './ClubEventCard.module.css';

const PARTICIPANT_STATUS_MAP: { [status in ParticipantStatus]: string } = {
  Invited: 'Приглашен',
  Participating: 'Участвую',
  Exited: 'Вышел',
  Banned: 'Исключен',
  Deleted: 'Удален',
};

export type Props = {
  tournament: {
    tournamentId: string;
    title: string;
    status: TournamentStatus;
    promoted: boolean;
    entrance: TournamentEntrance;
    mode: TournamentMode;
    system: TournamentSystem;
    clockControl?: ClockControlType;
    clockControlSettings?: ClockControlSettings;
    startTimestampMs?: number | null;
    participationStatus?: ParticipantStatus;
  };
  onClick?: (tournamentId: string) => void;
};

export function ClubEventCard({ tournament, onClick }: Props) {
  const { participationStatus } = tournament;

  const handleClick = useCallback(() => {
    onClick?.(tournament.tournamentId);
  }, [tournament, onClick]);

  // FIXME: colored modeConfiguration
  // FIXME: colored preset badges

  return (
    <Card
      active={tournament.promoted}
      disabled={tournament.status === 'Draft'}
      onClick={handleClick}
      className={styles.event}
    >
      <div className={styles.title}>{tournament.title}</div>
      <div className={styles.system}>
        <TournamentSystemView tournament={tournament} />
      </div>
      <div className={styles.entrance}>
        <EventEntrance tournament={tournament} />
      </div>
      <div className={styles.date}>
        {tournament.startTimestampMs ? (
          <>
            <DateView format="d MMMM" date={tournament.startTimestampMs} />
            <DateView format="HH:mm" date={tournament.startTimestampMs} />
          </>
        ) : (
          <>
            <span>—</span>
            <span>—</span>
          </>
        )}
      </div>
      {participationStatus && (
        <div>
          <Badge className={styles.badge}>
            {PARTICIPANT_STATUS_MAP[participationStatus]}
          </Badge>
        </div>
      )}
      {!participationStatus && tournament.entrance === 'Open' && (
        <div>
          <Badge className={styles.badge}>Принять участие</Badge>
        </div>
      )}
    </Card>
  );
}
