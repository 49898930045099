import { cx } from '@libs/classnames';
import { Button } from '@ui/components/Button';
import { GoldenModal } from '@ui/components/GoldenModal/GoldenModal';
import styles from './UnsavedChangesModal.module.css';

type Props = {
  className?: string;
  onProceed?: () => void;
  onStay?: () => void;
};

export function UnsavedChangesModal({ className, onProceed, onStay }: Props) {
  return (
    <GoldenModal className={cx(styles.modal, className)}>
      <h1>Несохраненные изменения</h1>
      <p>Изменения будут потеряны. Вы уверены, что хотите покинуть страницу?</p>
      <div className={styles.buttons}>
        <Button onClick={onProceed}>Покинуть</Button>
        <Button onClick={onStay}>Остаться</Button>
      </div>
    </GoldenModal>
  );
}
