import { cx } from '@libs/classnames';
import styles from './Loader.module.css';

export type Props = {
  className?: string;
  centered?: boolean;
  preset?: 'desktop' | 'mobile';
  ignoreClubColors?: boolean;
};

const PRESETS = {
  mobile: { width: 20, stroke: 2 },
  desktop: { width: 40, stroke: 4 },
};

/*
    ____,-------------------------------,____
    \   |           Компонент           |   /
    /___|-------------------------------|___\
*/

export function Loader({
  preset = 'desktop',
  centered,
  className,
  ignoreClubColors,
}: Props) {
  const { width, stroke } = PRESETS[preset];
  const radius = width / 2 - stroke / 2;
  const center = width / 2;
  const arc = createSvgArc(center, center, radius, 0, 90);

  return (
    <div
      className={cx(
        styles.wrapper,
        { [styles.centered]: centered, [styles.noclub]: ignoreClubColors },
        className,
      )}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        className={styles.loader}
      >
        <circle cx={center} cy={center} r={radius} strokeWidth={stroke} />
        <path d={arc} strokeWidth={stroke} />
      </svg>
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |            Helpers            |   /
    /___|-------------------------------|___\
*/

function createSvgArc(
  x: number,
  y: number,
  radius: number,
  angleStart: number,
  angleEnd: number,
) {
  const start = toCartesian(x, y, radius, angleEnd);
  const end = toCartesian(x, y, radius, angleStart);

  const largeArcFlag = angleEnd - angleStart <= 180 ? '0' : '1';

  return ['M', ...start, 'A', radius, radius, 0, largeArcFlag, 0, ...end].join(
    ' ',
  );
}

function toCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) {
  const angleInRadians = (angleInDegrees * Math.PI) / 180.0;
  const x = centerX + radius * Math.cos(angleInRadians);
  const y = centerY + radius * Math.sin(angleInRadians);
  return [x, y];
}
