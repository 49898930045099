import {
  ClockControlSettings,
  OneOnOneSettings,
  Tournament,
  UpdateTournamentRequest,
} from '@features/shared/api/typings';
import { gameTimeControlConfig } from '@features/tournament/constants';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';

const DEFAULT_CLOCK_CONTROL: ClockControlSettings = {
  initialSeconds: gameTimeControlConfig.Rapid.defaultInitialSeconds,
  addSecondsPerMove: gameTimeControlConfig.Rapid.defaultSecondsAdded,
};
const DEFAULT_ONE_ON_ONE_SETTINGS: OneOnOneSettings = {
  gamesCount: 2,
  clockControl: 'Rapid',
  clockControlSettingsPlayer1: DEFAULT_CLOCK_CONTROL,
  clockControlSettingsPlayer2: DEFAULT_CLOCK_CONTROL,
  tieSettings: {
    additionalGamesCount: 0,
    clockControl: 'Rapid',
    clockControlSettingsPlayer1: DEFAULT_CLOCK_CONTROL,
    clockControlSettingsPlayer2: DEFAULT_CLOCK_CONTROL,
  },
};

export function getDefaultValuesFromTournament(
  tournament: Tournament,
): TournamentEditFormValues {
  const {
    armageddonBlackLimitMinutes,
    armageddonWhiteLimitMinutes,
    startTimestampMs,
    teamModeSettings,
    oneOnOneSettings,
  } = tournament;

  return {
    ...tournament,
    teamModeSettings: teamModeSettings || { size: 2 },
    armageddonBlackLimitMinutes: armageddonBlackLimitMinutes ?? 0,
    armageddonWhiteLimitMinutes: armageddonWhiteLimitMinutes ?? 0,
    startTime: startTimestampMs ? new Date(startTimestampMs) : null,
    oneOnOneSettings: oneOnOneSettings || {
      ...DEFAULT_ONE_ON_ONE_SETTINGS,
    },
  };
}

export function getSubmitValuesFromForm(
  values: TournamentEditFormValues,
): UpdateTournamentRequest {
  const result: UpdateTournamentRequest = {
    ...values,
    additionalScoreOrder: values.additionalScoreOrder.filter((v) => !!v),
    startTimestampMs: values.startTime
      ? values.startTime.getTime()
      : Date.now() + 10 * 60 * 1000,
  };
  if (result.mode !== 'Team') delete result.teamModeSettings;
  if (result.system !== 'OneOnOne') delete result.oneOnOneSettings;

  return result;
}
