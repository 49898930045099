import { ReactNode } from '@tanstack/react-router';
import { AnimatedNode } from '../Animation';
import styles from './ErrorsNotificationInHeader.module.css';
import { useErrorNotification } from './useErrorNotification';

export function ErrorsNotificationInHeader({
  message = (
    <>
      <b>Ошибка</b>. При выполнении запроса что-то пошло не так. Повторите
      попытку позже
    </>
  ),
}: {
  message?: ReactNode;
}) {
  const { timestamp, reset } = useErrorNotification();

  return (
    <AnimatedNode animation="half-opacity" className={styles.errors}>
      {timestamp && (
        <div className={styles.message} key={timestamp}>
          {message}
          <button
            className={styles.close}
            onClick={reset}
            aria-label="Закрыть"
          ></button>
        </div>
      )}
    </AnimatedNode>
  );
}
