import { useCallback } from 'react';
import { useAutoPromotion } from '@features/game/hooks/useAutoPromotion';
import { useTheme } from '@ui/components/ThemeProvider';
import { UserSettingsMenuProps } from '../ui/UserSettingsMenu';

// FIXME: переенсти в UserWidget и убрать export

export function useUserSettingsMenu(): UserSettingsMenuProps {
  const { theme, selectTheme } = useTheme();
  const { autoPromotion, setAutoPromotion } = useAutoPromotion();

  return {
    autoQueen: autoPromotion === 'q',
    onAutoQueenToggle: useCallback(
      (enabled: boolean) => setAutoPromotion(enabled ? 'q' : null),
      [setAutoPromotion],
    ),
    //
    darkTheme: theme === 'dark',
    onDarkThemeToggle: useCallback(
      (enabled: boolean) => {
        selectTheme(enabled ? 'dark' : 'light');
      },
      [selectTheme],
    ),
  };
}
