import {
  ChessBoard,
  ChessBoardProps,
  ChessBoardSkeleton,
} from 'exports/ChessBoard';
import { ReactNode } from 'react';
import { AnimatedNode } from '@ui/components/Animation';
import { Loader } from '@ui/components/Loader';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import styles from './GameLayout.module.css';

export type Props = {
  board: ChessBoardProps;
  avatar?: ReactNode;
  header?: ReactNode;
  popup?: ReactNode;
  errorToast?: ReactNode;
  player1: ReactNode;
  player2: ReactNode;
  scoresheet: ReactNode;
  actions?: ReactNode;
};

export function GameLayout(props: Props) {
  const device = useMediaQuery();
  return device === 'desktop' ? (
    <DesktopLayout {...props} />
  ) : (
    <MobileLayout {...props} />
  );
}

export function GameLayoutSkeleton() {
  const device = useMediaQuery();
  return device === 'desktop' ? (
    <DesktopLayoutSkeleton />
  ) : (
    <MobileLayoutSkeleton isTablet={device === 'tablet'} />
  );
}

/*
    ____,-------------------------------,____
    \   |             Layouts           |   /
    /___|-------------------------------|___\
*/

function DesktopLayout({
  board,
  avatar,
  header,
  popup,
  errorToast,
  player1,
  player2,
  scoresheet,
  actions,
}: Props) {
  return (
    <div className={styles.layout}>
      {avatar && <div className={styles.avatar}>{avatar}</div>}
      <div className={styles.board}>{<ChessBoard {...board} />}</div>
      <div className={styles.sidebar}>
        <div className={styles.header}>
          {header}
          <div className={styles.popup}>
            <AnimatedNode>{popup}</AnimatedNode>
          </div>
        </div>
        <div className={styles.game}>
          {player1}
          <div className={styles.scoresheet}>{scoresheet}</div>
          {player2}
        </div>
        <AnimatedNode persist className={styles.footer}>
          {actions}
        </AnimatedNode>
        <AnimatedNode animation="bounce-up" className={styles.toast}>
          {errorToast}
        </AnimatedNode>
      </div>
    </div>
  );
}

function MobileLayout({
  board,
  avatar,
  header,
  popup,
  errorToast,
  player1,
  player2,
  scoresheet,
  actions,
}: Props) {
  return (
    <div className={styles.layoutMobile}>
      {avatar && <div className={styles.avatar}>{avatar}</div>}
      {header}
      {player1}
      <div className={styles.board}>
        {<ChessBoard {...board} notation="inside" />}
      </div>
      {player2}
      <div className={styles.actionsMobileWrapper}>
        <div className={styles.actionsMobile}>{actions}</div>
        <AnimatedNode className={styles.mobilePopup}>{popup}</AnimatedNode>
        <AnimatedNode className={styles.mobileToast}>{errorToast}</AnimatedNode>
      </div>
      <div className={styles.scoresheetMobile}>{scoresheet}</div>
    </div>
  );
}

/*
    ____,-------------------------------,____
    \   |           Skeletons           |   /
    /___|-------------------------------|___\
*/

function DesktopLayoutSkeleton() {
  return (
    <div className={styles.layout}>
      <div className={styles.board}>
        {
          <ChessBoardSkeleton>
            <Loader ignoreClubColors />
          </ChessBoardSkeleton>
        }
      </div>
      <div className={styles.sidebar}>
        <div className={styles.header}>
          <div className={styles.headerSkeletons} />
        </div>
      </div>
    </div>
  );
}

function MobileLayoutSkeleton({ isTablet }: { isTablet: boolean }) {
  return (
    <div className={styles.layoutMobile}>
      <div className={styles.headerSkeletons} />
      <div style={{ height: 32 }} />
      <div className={styles.board}>
        <ChessBoardSkeleton>
          <Loader preset={isTablet ? 'desktop' : 'mobile'} ignoreClubColors />
        </ChessBoardSkeleton>
      </div>
      <div className={styles.actionsMobile} />
      <div className={styles.scoresheetMobile} />
    </div>
  );
}
