import { Controller, useFormContext } from 'react-hook-form';
import { TournamentEditFormValues } from '@features/tournament/ui/components/TournamentSettings/typings';
import { FormInput, FormLabel, FormRow } from '@ui/components/Form/Form';
import { Input } from '@ui/components/Input';

export function TimeBetweenRoundsInput() {
  const { control } = useFormContext<TournamentEditFormValues>();
  return (
    <FormRow>
      <FormLabel>Пауза между турами</FormLabel>
      <FormInput>
        <Controller
          name="delayBetweenRoundsSeconds"
          control={control}
          render={({ field: { value, onChange, disabled } }) => {
            const handleChange = (newValue: string) => {
              onChange(Number(newValue) * 60);
            };
            return (
              <Input
                invisible
                rightLabel="мин"
                value={Math.floor(value / 60).toString()}
                onChange={handleChange}
                disabled={disabled}
              />
            );
          }}
        />
      </FormInput>
    </FormRow>
  );
}
