import { ColoredBackground } from 'features/shared/ui/ColoredBackground';
import { Copyright } from 'features/shared/ui/Copyright';
import { TournamentHeader } from 'features/shared/ui/TournamentHeader';
import { ReactNode } from 'react';
import { useClubColors } from '@features/club/hooks/useClubColors';
import { ErrorsNotificationInHeader } from '@ui/components/ErrorsNotificationProvider';
import { Layout } from '@ui/components/Layout/Layout';
import { ThemeLink } from '@ui/components/ThemeLink';
import styles from './TournamentLayout.module.css';

// FIXME: переименовать в DefaultLayout или SiteLayout

export type Props = {
  title?: ReactNode;
  onHome?: () => void;
  onTournamentCreate?: () => void;
  themeColor?: string | null;
  isManager?: boolean;
  children: ReactNode;
  user?: ReactNode;
};
export function TournamentLayout({
  onHome,
  title,
  onTournamentCreate,
  isManager,
  themeColor,
  user,
  children,
}: Props) {
  // перекраска в клубные цвета
  useClubColors(themeColor);

  return (
    <Layout
      header={
        <TournamentHeader
          className={styles.header}
          title={title}
          headerButtons={
            isManager ? (
              <ThemeLink colorless onClick={onTournamentCreate}>
                Создать турнир
              </ThemeLink>
            ) : null
          }
          user={user}
          onHome={onHome}
        />
      }
    >
      <div className={styles.innerContainer}>
        <ColoredBackground className={styles.background} />

        <div className={styles.content}>
          <div className={styles.innerContent}>{children}</div>

          <div className={styles.footer}>
            <Copyright />
          </div>
        </div>
      </div>
      <ErrorsNotificationInHeader />
    </Layout>
  );
}
