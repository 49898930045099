import { cx } from '@libs/classnames';
import styles from './Toggle.module.css';

type Props = {
  className?: string;
  value?: boolean;
  onToggle?: (value: boolean) => void;
};

export function Toggle({ className, value = false, onToggle }: Props) {
  return (
    <div
      className={cx(styles.toggle, className, {
        [styles.on]: value,
        [styles.off]: !value,
      })}
      onClick={() => onToggle?.(!value)}
    >
      <div className={styles.trigger}>
        <span aria-hidden={value}>ВЫКЛ</span>
        <span aria-hidden={!value}>ВКЛ</span>
      </div>
    </div>
  );
}
