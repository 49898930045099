import { ReactNode } from 'react';
import TinyCrossfade from 'react-tiny-crossfade';
import styles from './AnimationCrossfade.module.css';

export type Props = {
  children: ReactNode;
};

/**
 * !!!Важно!!!
 * У детей должны быть ключи key, иначе компонент не будет ничего анимировать
 * TODO переделать на react-transition-group?
 * react-tiny-crossfade не очень поддерживаемый, но вроде пока работает
 */
export function AnimationCrossfade({ children }: Props) {
  return (
    <TinyCrossfade
      className={styles.container}
      classNames={{
        beforeEnter: styles.beforeEnter,
        entering: styles.entering,
        beforeLeave: styles.beforeLeave,
        leaving: styles.leaving,
      }}
      duration={300}
      disableInitialAnimation
    >
      {children as JSX.Element}
    </TinyCrossfade>
  );
}
