import { CSSProperties, ReactNode } from 'react';
import { cx } from '@libs/classnames';
import styles from './HorizontalScroll.module.css';

export type Props = {
  style?: CSSProperties;
  className?: string;
  enableForDesktop?: boolean;
  children: ReactNode;
};

export function HorizontalScroll({
  style,
  className,
  enableForDesktop,
  children,
}: Props) {
  return (
    <div
      style={style}
      className={cx(className, styles.scrollable, {
        [styles.enabledForDesktop]: enableForDesktop,
      })}
    >
      {children}
    </div>
  );
}
