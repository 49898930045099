import { useEffect } from 'react';

// see: https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#maximum_delay_value
const MAX_POSSIBLE_TIMEOUT = 2147483647;

export function useTimeoutUntil(cb: () => void, timestampMs: number | null) {
  useEffect(() => {
    if (timestampMs === null) return;

    const schedule = () =>
      setTimeout(
        () => {
          // Обрабатываем ситуацию, когда время жизни токена больше ~25 дней
          // (максимальное значение для setTimeout).
          const remaining = timestampMs - Date.now();
          if (remaining > 0) {
            timeout = schedule();
          } else {
            cb();
          }
        },
        Math.min(timestampMs - Date.now(), MAX_POSSIBLE_TIMEOUT),
      );

    let timeout = schedule();

    return () => clearTimeout(timeout);
  }, [cb, timestampMs]);
}
