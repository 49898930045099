import { ReactNode } from 'react';
import { cx } from '@libs/classnames';
import { ThemeButton } from '@ui/components/ThemeButton';
import styles from './ThemeIconButton.module.css';

export type Props = {
  className?: string;
  onClick: () => void;
  disabled?: boolean;
  children: ReactNode;
};

export function ThemeIconButton({
  className,
  onClick,
  disabled,
  children,
}: Props) {
  return (
    <ThemeButton
      className={cx(className, styles.button)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ThemeButton>
  );
}
