import { useCallback, useMemo } from 'react';
import {
  ClockControlType,
  MemberTournamentParticipantDto,
} from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import useLockBodyScroll from '@libs/hooks/useLockBodyScroll';
import { Card } from '@ui/components/Card';
import { InfiniteScroll } from '@ui/components/InfiniteScroll';
import { Input } from '@ui/components/Input';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Table, TableCell, TableRow } from '@ui/components/Table';
import { Tabs } from '@ui/components/Tabs';
import { ThemeButton } from '@ui/components/ThemeButton';
import styles from './ParticipantsModal.module.css';

type Sort = 'Alphabet' | 'RatingBlitz' | 'RatingRapid' | 'RatingClassic';

export type Props = {
  className?: string;
  onDone: () => void;
  clockControl: ClockControlType;
  sort: Sort;
  onSortChange: (sort: Sort) => void;
  search: string;
  onSearchChange: (search: string) => void;
  members?: MemberTournamentParticipantDto[];
  hasNextPage: boolean;
  fetchNextPage: () => void;
  onAdd: (p: MemberTournamentParticipantDto) => void;
  onInvite: (p: MemberTournamentParticipantDto) => void;
  onRemove: (p: MemberTournamentParticipantDto) => void;
  disabled?: boolean;
  allowInvite: boolean;
  allowAdd: boolean;
};

export function ParticipantsModal({
  className,
  onDone,
  clockControl,
  sort,
  onSortChange,
  search,
  onSearchChange,
  members,
  hasNextPage,
  fetchNextPage,
  onAdd,
  onInvite,
  onRemove,
  disabled,
  allowInvite,
  allowAdd,
}: Props) {
  useLockBodyScroll();

  const sortTabs = useMemo(() => {
    let rankSort: Sort = 'RatingClassic';
    if (clockControl === 'Rapid') rankSort = 'RatingRapid';
    if (clockControl === 'Blitz') rankSort = 'RatingBlitz';

    const tabs: { value: Sort; label: string }[] = [
      { value: rankSort, label: 'По рейтингу' },
      { value: 'Alphabet', label: 'По алфавиту' },
    ];
    return tabs;
  }, [clockControl]);

  return (
    <Card className={cx(className, styles.participantsModal)}>
      <div className={styles.header}>
        <div className={styles.title}>
          <PageSubTitle>Участники</PageSubTitle>
          <Tabs
            tabs={sortTabs}
            onChange={onSortChange}
            value={sort}
            disabled={disabled}
          />
          <Input
            onChange={onSearchChange}
            placeholder="Поиск участников"
            value={search}
            disabled={disabled}
          />
        </div>
        <ThemeButton className={styles.saveButton} onClick={onDone}>
          Готово
        </ThemeButton>
      </div>

      <InfiniteScroll
        className={styles.scrollContainer}
        fetchMore={fetchNextPage}
        hasMore={hasNextPage}
        itemsLength={members?.length || 0}
      >
        <Table className={styles.participantsTable}>
          <TableRow header>
            <TableCell className={styles.name}>Имя</TableCell>
            <TableCell>Звание</TableCell>
            <TableCell>Email</TableCell>
            <TableCell className={styles.tournaments} right>
              Турниры
            </TableCell>
            <TableCell right>Игры</TableCell>
            <TableCell right>Рейтинг</TableCell>
            <TableCell right></TableCell>
          </TableRow>

          {members?.map((p) => (
            <ParticipantRow
              key={p.userId}
              participant={p}
              isInvited={p.participationStatus === 'Invited'}
              isAdded={p.participationStatus === 'Participating'}
              onAdd={onAdd}
              onInvite={onInvite}
              onRemove={onRemove}
              clockControl={clockControl}
              disabled={disabled}
              allowInvite={allowInvite}
              allowAdd={allowAdd}
            />
          ))}
        </Table>
      </InfiniteScroll>
    </Card>
  );
}

type ParticipantRowProps = {
  participant: MemberTournamentParticipantDto;
  isInvited?: boolean;
  isAdded?: boolean;
  onAdd: (p: MemberTournamentParticipantDto) => void;
  onInvite: (p: MemberTournamentParticipantDto) => void;
  onRemove: (p: MemberTournamentParticipantDto) => void;
  clockControl: ClockControlType;
  disabled?: boolean;
  allowInvite: boolean;
  allowAdd: boolean;
};

function ParticipantRow({
  participant: p,
  isInvited,
  isAdded,
  onAdd,
  onInvite,
  onRemove,
  clockControl,
  disabled,
  allowInvite,
  allowAdd,
}: ParticipantRowProps) {
  const handleAdd = useCallback(() => {
    onAdd(p);
  }, [onAdd, p]);
  const handleInvite = useCallback(() => {
    onInvite(p);
  }, [onInvite, p]);
  const handleRemove = useCallback(() => {
    onRemove(p);
  }, [onRemove, p]);

  let rank;
  if (clockControl === 'Blitz') rank = p.elo.blitz.rating;
  if (clockControl === 'Rapid') rank = p.elo.rapid.rating;
  if (clockControl === 'Classic') rank = p.elo.classic.rating;

  return (
    <TableRow key={p.userId} hoverable>
      <TableCell>
        {p.lastName} {p.firstName}
      </TableCell>
      <TableCell>{p.title}</TableCell>
      <TableCell>{p.email}</TableCell>
      <TableCell right>{p.tournamentsCount}</TableCell>
      <TableCell right>{p.gamesCount}</TableCell>
      <TableCell right>{rank}</TableCell>
      <TableCell right>
        <div className={styles.buttons}>
          {(isInvited && !allowAdd) || isAdded ? (
            <ThemeButton
              className={styles.addButton}
              compact
              inverted
              onClick={handleRemove}
              disabled={disabled || (isAdded && !allowAdd)}
            >
              {isInvited && allowInvite && 'Отменить'}
              {isAdded && allowAdd && 'Удалить'}
              {isAdded && !allowAdd && 'Добавлен'}
            </ThemeButton>
          ) : (
            <>
              {allowAdd && (
                <ThemeButton
                  className={styles.addButton}
                  compact
                  onClick={handleAdd}
                  disabled={disabled}
                >
                  Добавить
                </ThemeButton>
              )}
              {allowInvite && !isInvited && (
                <ThemeButton
                  className={styles.addButton}
                  compact
                  onClick={handleInvite}
                  disabled={disabled}
                >
                  Пригласить
                </ThemeButton>
              )}
            </>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
