import { ChessBoardProps } from 'exports/ChessBoard';
import { Scoresheet } from 'exports/Scoresheet';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { components } from '@features/game/api';
import { useGameServer } from '@features/game/hooks/useGameServer';
import { useScoresheet } from '@features/game/hooks/useScoresheet';
import { DrawOffer } from '@features/game/ui/DrawOffer';
import {
  ButtonDraw,
  ButtonLeave,
  ButtonResign,
} from '@features/game/ui/GameButtons/GameButtons';
import { GameInfo } from '@features/game/ui/GameInfo/GameInfo';
import {
  GameLayout,
  GameLayoutSkeleton,
} from '@features/game/ui/GameLayout/GameLayout';
import { NetworkProblems } from '@features/game/ui/NetworkProblems/NetworkProblems';
import {
  PlayerPanelWidget,
  PlayerPanelWidgetProps,
} from '@features/game/widgets/PlayerPanelWidget/PlayerPanelWidget';
import { StartCountdownWidget } from '@features/game/widgets/StartCountdownWidget/StartCountdownWidget';
import { PromotionPiece } from '@libs/chess/types';
import { OBJECT_EMPTY } from '@libs/constants';
import { useMediaQuery } from '@ui/components/MediaQueryProvider';
import { Toast } from '@ui/components/Toast';

/*
    ____,-------------------------------,____
    \   |              Page             |   /
    /___|-------------------------------|___\
*/

const TIME_PRESSURE = 10;

type Props = {
  key: string /* sic! Для разных игр необходимы разные ключи */;
  gameId: string;
  playerId?: string;
  accessKey?: string;
  customSocketUrl?: string;
  avatar?: ReactNode;
  showSkeleton?: boolean;
  autoPromotion?: PromotionPiece;
  customModal?: ReactNode;
  onLeave?: () => void;
};

export function GamePage({
  gameId,
  playerId,
  accessKey,
  customSocketUrl,
  avatar,
  showSkeleton,
  autoPromotion,
  customModal,
  onLeave,
}: Props) {
  const mobile = useMediaQuery() !== 'desktop';
  const { toast, setToast, clearToast } = useToast();
  const handleError = useCallback(
    (error: Error) => {
      setToast(
        <>
          <b>Ошибка</b>. При выполнении запроса что-то пошло не так. Повторите
          попытку позже
        </>,
      );
      console.error(error);
    },
    [setToast],
  );

  const isPlayer = Boolean(accessKey);

  const {
    loading,
    networkPromlems,
    //
    propsBoard,
    //
    game,
    player,
    //
    showDrawOffer,
    //
    handleOfferDraw,
    handleAcceptDraw,
    handleDeclineDraw,
    handleResign,
  } = useGameServer({
    gameId,
    playerId,
    accessKey,
    customSocketUrl,
    autoPromotion,
    enabled: !showSkeleton,
    onError: handleError,
  });
  const inProgress = game?.result === 'InProgress';

  const title = formatTitle(game);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const gameSpecs = useMemo(() => formatSpecs(game), [game?.id]);

  const scoresheet = useScoresheet(game, propsBoard);

  // FIXME: добавить обработку ошибок
  if (showSkeleton || loading || !game) return <GameLayoutSkeleton />;

  const modal = (function () {
    if (customModal) return customModal;
    if (networkPromlems) return <NetworkProblems />;
    if (game?.result === 'NotStarted') {
      return <StartCountdownWidget game={game} />;
    }
  })();

  const propsBoardFinal: ChessBoardProps = Object.assign(
    propsBoard,
    scoresheet.propsPatch ?? OBJECT_EMPTY,
    modal ? { modal } : OBJECT_EMPTY,
  );

  const { orientation: play, winner: clientWinner } = propsBoard;
  const turn: PlayerPanelWidgetProps['turn'] = inProgress
    ? game.nextMoveBy === 'Black'
      ? 'black'
      : 'white'
    : null;
  const winner = mapWinner(game.result, clientWinner);

  const fullTurnHappened = game.moves.length >= 2;

  return (
    <GameLayout
      board={propsBoardFinal}
      avatar={avatar}
      header={<GameInfo title={title} specs={gameSpecs} />}
      errorToast={
        toast ? (
          <Toast type="error" onClose={clearToast}>
            {toast}
          </Toast>
        ) : null
      }
      popup={
        showDrawOffer && isPlayer ? (
          <DrawOffer
            onAccept={handleAcceptDraw}
            onDecline={handleDeclineDraw}
          />
        ) : undefined
      }
      player1={
        <PlayerPanelWidget
          orientation="top"
          color={play === 'white' ? 'black' : 'white'}
          turn={turn}
          winner={winner}
          game={game}
          mobile={mobile}
          timePressureSec={TIME_PRESSURE}
          scoresheetTimeMs={scoresheet.playersTimeMs}
        />
      }
      player2={
        <PlayerPanelWidget
          orientation="bottom"
          color={play === 'white' ? 'white' : 'black'}
          turn={turn}
          winner={winner}
          game={game}
          mobile={mobile}
          timePressureSec={TIME_PRESSURE}
          scoresheetTimeMs={scoresheet.playersTimeMs}
        />
      }
      scoresheet={<Scoresheet {...scoresheet.props} />}
      actions={
        inProgress && isPlayer ? (
          <>
            <ButtonDraw
              disabled={Boolean(
                game.activeDrawRequest ||
                  player?.drawRequestsLeft === 0 ||
                  !fullTurnHappened,
              )}
              onClick={handleOfferDraw}
            />
            <ButtonResign disabled={!fullTurnHappened} onClick={handleResign} />
          </>
        ) : (
          <ButtonLeave onClick={onLeave} />
        )
      }
    />
  );
}

function mapWinner(
  apiResult?: components['schemas']['GameResult'],
  clientWinner?: ChessBoardProps['winner'],
) {
  if (apiResult === 'BlackWin') return 'black';
  if (apiResult === 'WhiteWin') return 'white';
  if (apiResult === 'Draw') return 'draw';

  return clientWinner;
}

function useToast() {
  const [toast, setToast] = useState<ReactNode>(null);

  return {
    toast,
    setToast,
    clearToast() {
      setToast(null);
    },
  };
}

function formatTitle(game?: components['schemas']['GameDto']) {
  if (!game?.tournament) return 'Быстрая игра';

  const round = game.round?.roundNumber
    ? `. Тур\u00A0${game.round?.roundNumber}`
    : null;
  return `${game.tournament.name}${round ?? ''}`;
}

function formatSpecs(game?: components['schemas']['GameDto']) {
  const specs: string[] = [];
  if (
    game?.clockSettings &&
    game.clockSettings.initialSeconds &&
    game.clockSettings.addSecondsPerMove !== undefined
  ) {
    specs.push(
      `${Math.floor(game.clockSettings.initialSeconds / 60)}+${game.clockSettings.addSecondsPerMove}`,
    );
  }

  if (
    game?.tournament?.timeControl &&
    TIME_CONTROL[game.tournament.timeControl]
  ) {
    specs.push(TIME_CONTROL[game.tournament.timeControl]!);
  }

  return specs;
}

// FIXME: взять из енума API, когда таковой будет
const TIME_CONTROL: Record<string, string> = {
  Blitz: 'Блиц',
  Rapid: ' Рапид',
  Classic: 'Классические',
};
