import { CardParticipantList } from 'features/shared/ui/CardParticipantList';
import { MemberDto, ParticipantDto } from '@features/shared/api/typings';
import { cx } from '@libs/classnames';
import { Loader } from '@ui/components/Loader';
import { PageSubTitle } from '@ui/components/PageSubTitle';
import { Tabs } from '@ui/components/Tabs';
import styles from './ClubCardParticipants.module.css';

export type TabValue = 'RatingClassic' | 'Alphabet';

const TABS: { value: TabValue; label: string }[] = [
  { value: 'RatingClassic', label: 'По рейтингу' },
  { value: 'Alphabet', label: 'По алфавиту' },
];

export type Props = {
  className?: string;
  tab: TabValue;
  setTab: (newTab: TabValue) => void;
  members?: ParticipantDto[] | MemberDto[];
  isPending: boolean;
  isManager: boolean;
  highlightedUserId?: string;
};

export function ClubCardParticipants({
  className,
  tab,
  setTab,
  members,
  isPending,
  isManager,
  highlightedUserId,
}: Props) {
  return (
    <div className={cx(className, styles.participantsWidget)}>
      <div className={styles.title}>
        <PageSubTitle>Участники</PageSubTitle>
        <Tabs tabs={TABS} value={tab} onChange={setTab} />
      </div>

      {(isPending && <Loader className={styles.loader} centered />) || (
        <CardParticipantList
          className={styles.participants}
          participants={members}
          preset="club"
          isManager={isManager}
          highlightedUserId={highlightedUserId}
        />
      )}
    </div>
  );
}
