export function formatSeconds({
  time,
  showHours = false,
}: {
  time: number;
  showHours?: boolean;
}) {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((showHours ? time % 3600 : time) / 60);
  const seconds = time % 60;

  const timer = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  return showHours ? `${hours.toString().padStart(2, '0')}:${timer}` : timer;
}
