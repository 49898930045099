import { DateView } from '@ui/components/DateView';
import styles from './Copyright.module.css';

export function Copyright() {
  const now = new Date();
  return (
    <span className={styles.root}>
      © Русская Шахматная Школа, <DateView date={now} format="yyyy" />
    </span>
  );
}
