import { CSSProperties } from 'react';
import { cx } from '@libs/classnames';
import styles from './ColoredBackground.module.css';

export type Props = {
  style?: CSSProperties;
  className?: string;
};
export function ColoredBackground({ style, className }: Props) {
  return (
    <div style={style} className={cx(className, styles.root)}>
      <div className={styles.ellipse} />
    </div>
  );
}
